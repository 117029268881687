import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_offline_indicator = _resolveComponent("dtg-offline-indicator")!
  const _component_dtg_header = _resolveComponent("dtg-header")!
  const _component_dtg_spinner = _resolveComponent("dtg-spinner")!
  const _component_dtg_content = _resolveComponent("dtg-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.offline)
      ? (_openBlock(), _createBlock(_component_dtg_offline_indicator, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_dtg_header),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.b('content-wrapper'), _ctx.wrapperClass])
    }, [
      _renderSlot(_ctx.$slots, "before-content"),
      _createVNode(_component_dtg_content, { root: "" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('content-inner-wrapper', { padded: _ctx.padded && !_ctx.loading, loading: _ctx.loading }))
          }, [
            _renderSlot(_ctx.$slots, "before-inner-content"),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.b('loader'))
                }, [
                  _createVNode(_component_dtg_spinner, { view: "big" })
                ], 2))
              : (_openBlock(), _createElementBlock("div", _mergeProps({
                  key: 1,
                  class: _ctx.b('content-inner')
                }, _ctx.$attrs), [
                  _renderSlot(_ctx.$slots, "content")
                ], 16)),
            _renderSlot(_ctx.$slots, "after-inner-content")
          ], 2)
        ]),
        _: 3
      }),
      _renderSlot(_ctx.$slots, "after-content")
    ], 2)
  ], 64))
}