import { useAuth } from '@/composables/use-auth';
import { useStoreData } from '@/composables/use-store-data';

import { useStoreCollection } from '@/store';

import { logError } from '@/utils/log-error';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDtgListData() {
    const {
        actions: { fetchItemsCached, clearItemsCache },
    } = useStoreCollection('dtg');

    const { refreshTokens } = useAuth();

    const { loading, fetchData: fetchDtgs } = useStoreData({
        load: fetchDtgsInternal,
    });

    async function fetchDtgsInternal(refresh = false): Promise<void> {
        if (refresh) {
            await clearItemsCache();
        }

        await fetchItemsCached({
            onError: async (error) => {
                logError(error);

                await refreshTokens();
            },
        });
    }

    return {
        loading,

        fetchDtgs,
    };
}
