import { getDeviceType, getMobileOS } from 'dtg-shared/utils/device-type';

import { useModalAppStore } from '@/components/organisms/modal-app-store';

import { ENV } from '@/utils/env';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitInstallPrompts() {
    const modalAppStore = useModalAppStore();

    function initPWAPromptHandler(): void {
        window.addEventListener('beforeinstallprompt', (event) => {
            const os = getMobileOS();

            if (['android', 'ios'].includes(os)) {
                event.preventDefault();
            }
        });
    }

    function openAppStoreModalIfNeeded(): void {
        const deviceType = getDeviceType();
        const os = getMobileOS();

        const mobileOrTablet = ['tablet', 'mobile'].includes(deviceType);
        const iosOrAndroid = ['android', 'ios'].includes(os);

        if (ENV.VUE_APP_PLATFORM === 'web' && mobileOrTablet && iosOrAndroid) {
            modalAppStore.open({});
        }
    }

    return {
        initPWAPromptHandler,
        openAppStoreModalIfNeeded,
    };
}
