import { boolean, mixed, string } from 'yup';

import { arrayIncludes } from 'dtg-shared/utils/array';
import { defineSchemaWithConditions, makeConditionsOf } from 'dtg-shared/validation';

import { DocumentFormData, DocumentStatus, DocumentValidationSchemaData } from './types';

export function documentValidationSchema({
    madeByFields,
    dtgTypes,
    selectedDtgType,
}: DocumentValidationSchemaData): typeof schema & {
    disabledStatuses: DocumentStatus[];
} {
    const required = madeByFields === 'required' && arrayIncludes(dtgTypes, selectedDtgType);
    const disabledStatuses = required ? [DocumentStatus.notApplicable] : [];
    const statuses = Object.values(DocumentStatus).filter((status) => !disabledStatuses.includes(status));

    const when = makeConditionsOf<DocumentFormData>();

    const isDeliveredCondition = when({
        key: 'status',
        is: (value): boolean => {
            return (
                required &&
                arrayIncludes([DocumentStatus.delivered, DocumentStatus.deliveredIncorrect], value)
            );
        },
    });

    const schema = defineSchemaWithConditions<DocumentFormData>()(
        {
            status: mixed<DocumentStatus>()
                .label('Statut du document')
                .oneOf([...statuses, null], "Le statut sélectionné n'est pas valide")
                .nullable()
                .required(),
            madeBy: string().label('Réalisé par').defined(),
            madeByDate: string().label('En date du').nullable().defined(),
            comment: string().label('Commentaire').defined(),
            visibleInReport: boolean().defined(),
            filesVisibleInReport: boolean().defined(),
        },
        {
            madeBy: isDeliveredCondition.extend({
                then: string().required(),
            }),
            madeByDate: isDeliveredCondition.extend({
                then: string().nullable().required(),
            }),
        },
    );

    return { ...schema, disabledStatuses };
}
