/* eslint-disable @typescript-eslint/no-explicit-any */

export type InitlessService<T> = {
    get: () => T;
    set: (newInstance: T) => void;
    clear: () => void;
    isSet: () => boolean;
    createGetter: <U>(getter: (instance: T) => U) => () => U;
};

export type Service<T, Args extends any[]> = InitlessService<T> & {
    init: (...args: Args) => T;
    initIfNotSet: (...args: Args) => T;
};

export type AsyncService<T, Args extends any[]> = InitlessService<T> & {
    init: (...args: Args) => Promise<T>;
    initIfNotSet: (...args: Args) => Promise<T>;
};

export function createService<T, Args extends any[]>(
    name: string,
    initService: (...args: Args) => T,
): Service<T, Args> {
    const service = createInitlessService<T>(name);

    function init(...args: Args): T {
        if (service.isSet()) {
            throw new Error(`Trying to initialize service "${name}" after it has already been initialized`);
        }

        const instance = initService(...args);

        service.set(instance);

        return service.get();
    }

    function initIfNotSet(...args: Args): T {
        if (service.isSet()) {
            return service.get();
        }

        return init(...args);
    }

    return {
        init,
        initIfNotSet,
        ...service,
    };
}

export function createAsyncService<T, Args extends any[]>(
    name: string,
    initService: (...args: Args) => Promise<T>,
): AsyncService<T, Args> {
    const service = createInitlessService<T>(name);

    async function init(...args: Args): Promise<T> {
        throwIfSet();

        const instance = await initService(...args);

        throwIfSet();

        service.set(instance);

        return service.get();
    }

    async function initIfNotSet(...args: Args): Promise<T> {
        if (service.isSet()) {
            return service.get();
        }

        return init(...args);
    }

    function throwIfSet(): void {
        if (service.isSet()) {
            throw new Error(`Trying to initialize service "${name}" after it has already been initialized`);
        }
    }

    return {
        init,
        initIfNotSet,
        ...service,
    };
}

export function createInitlessService<T>(name: string): InitlessService<T> {
    let instance: T | undefined;

    function get(): T {
        if (!instance) {
            throw new Error(`Service "${name}" must be initialized before being used`);
        }

        return instance;
    }

    function set(newInstance: T): void {
        if (instance) {
            throw new Error(`Trying to override instance of service "${name}". Call clear first.`);
        }

        instance = newInstance;
    }

    function clear(): void {
        // eslint-disable-next-line no-undefined
        instance = undefined;
    }

    function isSet(): boolean {
        return Boolean(instance);
    }

    function createGetter<U>(getter: (instance: T) => U) {
        return () => getter(get());
    }

    return {
        get,
        set,
        clear,
        isSet,
        createGetter,
    };
}
