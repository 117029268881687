import { BasicItem, Id, ListItemData } from 'dtg-shared/types/data';
import { NonNullableRecord } from 'dtg-shared/types/utils';
import { createOptionsFromMap } from 'dtg-shared/utils/option';

import { DtgType } from '../dtg';

export type DocumentData = ListItemData & {
    status: DocumentStatus | null;
    comment: string;
    madeBy: string;
    madeByDate: string | null;
    visibleInReport: boolean;
    files: DocumentFile[];
    filesVisibleInReport: boolean;

    createdTimestamp: number;
    valid: boolean;

    custom: boolean;
    groupId: string | null;
    information: DocumentCustomInformation | null;
};

export type DocumentFile = Id & {
    name: string;
};

export type DocumentValidationSchemaData = DocumentValidationData & {
    selectedDtgType: DtgType | null;
};

export type DocumentCustomInformation = Omit<DocumentListItem, 'id' | 'ownId'>;

export type DocumentCustomData = DocumentFormData &
    NonNullableRecord<Pick<DocumentData, 'information' | 'groupId'>>;

export const documentFormDataKeys = [
    'status',
    'madeBy',
    'madeByDate',
    'comment',
    'visibleInReport',
    'filesVisibleInReport',
] as const;

export type DocumentFormData = Pick<DocumentData, typeof documentFormDataKeys[number]>;

export type DocumentListData = DocumentValidationData & {
    text: string;
    disabledByDtgType: DocumentDisabledByDtgType[];
};

export type DocumentViewData = DocumentData & DocumentListData;

export type DocumentListItem = BasicItem &
    DocumentValidationData & {
        ownId: string; // Required for server-side validation
        disabledByDtgType: DocumentDisabledByDtgType[];
    };

export type DocumentValidationData = {
    madeByFields: 'none' | 'optional' | 'required';
    dtgTypes: DtgType[];
};

export type DocumentDisabledByDtgType = {
    dtgType: DtgType;
    message: string;
};

export enum DocumentStatus {
    notApplicable = 'notApplicable',
    delivered = 'delivered',
    notDelivered = 'notDelivered',
    deliveredIncorrect = 'deliveredIncorrect',
}

export const DOCUMENT_STATUS_MAP: Record<DocumentStatus, string> = {
    [DocumentStatus.notApplicable]: 'Sans objet',
    [DocumentStatus.delivered]: 'Remis',
    [DocumentStatus.notDelivered]: 'Non remis',
    [DocumentStatus.deliveredIncorrect]: 'Remis non exploitable',
};

export const DOCUMENT_STATUS_OPTIONS = createOptionsFromMap(DOCUMENT_STATUS_MAP);
