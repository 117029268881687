import { inject, InjectionKey, provide } from 'vue';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createInjectedState<T>(name: string) {
    const key: InjectionKey<T> = Symbol(name);

    function provideState(state: T): void {
        provide(key, state);
    }

    function useState(): T | undefined;
    function useState(defaultState: T): T;
    function useState(defaultState?: T): T | undefined {
        return inject(key, defaultState);
    }

    function withDefaultState(defaultState: () => T): () => T {
        return () => useState(defaultState());
    }

    function useStateOrThrow(): T {
        const state = useState();

        if (!state) {
            throw new Error(`State for injectable "${name}" was not provided`);
        }

        return state;
    }

    return { provideState, useState, useStateOrThrow, withDefaultState };
}
