// See https://deviceatlas.com/blog/list-of-user-agent-strings

export function getDeviceType(): 'mobile' | 'tablet' | 'desktop' {
    const ua = navigator.userAgent;

    if (/tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(ua)) {
        return 'tablet';
    } else if (/Mobile|Android|iPhone|Opera Mobi|Opera Mini/.test(ua)) {
        return 'mobile';
    }

    return 'desktop';
}

export function getMobileOS(): 'android' | 'ios' | 'unknown' {
    const { userAgent, platform } = navigator;

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // second condition for ipad with ios >= 13
    if (/iPad|iPhone|iPod/.test(platform) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        return 'ios';
    }

    return 'unknown';
}
