import {
    Auth,
    connectAuthEmulator,
    getAuth as getAuthBase,
    indexedDBLocalPersistence,
    initializeAuth,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';

import { Db } from 'dtg-firebase/classes/Db';
import { KeysOfType } from 'dtg-shared/types/utils';
import { createAsyncService } from 'dtg-shared/utils/create-service';

import { FirebaseStorage } from '@/services/firebase-storage';
import { createKeyValueStorage } from '@/services/key-value-storage';

import { ENV, ENVType } from '@/utils/env';

import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

export type FirebaseModules = {
    auth: Auth;
    functions: firebase.functions.Functions;
    firestore: firebase.firestore.Firestore;
    storage: FirebaseStorage;
    storageBase: firebase.storage.Storage;
    db: Db;
};

const KEY_VALUE_STORAGE_DB = 'dtgMobile';
const KEY_VALUE_STORAGE_TABLE = 'firebaseStoragePendingUploads';

const firebaseService = createAsyncService('firebase', initFirebaseInternal);

const { init: initFirebase, createGetter } = firebaseService;

export { firebaseService, initFirebase };

export const getAuth = createGetter(({ auth }) => auth);
export const getFirestore = createGetter(({ firestore }) => firestore);
export const getFunctions = createGetter(({ functions }) => functions);
export const getStorage = createGetter(({ storage }) => storage);
export const getDb = createGetter(({ db }) => db);

async function initFirebaseInternal(): Promise<FirebaseModules> {
    const app = firebase.initializeApp(ENV.VUE_APP_FIREBASE_CONFIG);

    const auth =
        ENV.VUE_APP_PLATFORM === 'web'
            ? getAuthBase(app)
            : initializeAuth(app, { persistence: indexedDBLocalPersistence });
    const functions = firebase.functions();
    const firestore = firebase.firestore();

    const storageBase = firebase.storage();
    const storageRef = firebase.storage().ref('');

    const persistentStorage = await createKeyValueStorage(KEY_VALUE_STORAGE_DB, KEY_VALUE_STORAGE_TABLE);
    const storage = new FirebaseStorage(storageRef, persistentStorage);

    await storage.initialize();

    const db = new Db(firestore);

    const modules: FirebaseModules = {
        auth,
        functions,
        firestore,
        storageBase,
        storage,
        db,
    };

    if (ENV.VUE_APP_USE_FIRESTORE_EMULATOR) {
        initFirebaseEmulators(modules);
    }

    return modules;
}

function initFirebaseEmulators({ functions, firestore, storageBase, auth }: FirebaseModules): void {
    const functionsPort = getPort('VUE_APP_FUNCTIONS_EMULATOR_PORT');
    const firestorePort = getPort('VUE_APP_FIRESTORE_EMULATOR_PORT');
    const storagePort = getPort('VUE_APP_STORAGE_EMULATOR_PORT');
    const authPort = getPort('VUE_APP_AUTH_EMULATOR_PORT');
    const host = ENV.VUE_APP_FIREBASE_EMULATOR_HOST;

    if (functionsPort) {
        functions.useEmulator(host, functionsPort);
    }

    if (firestorePort) {
        firestore.useEmulator(host, firestorePort);
    }

    if (storagePort) {
        storageBase.useEmulator(host, storagePort);
    }

    if (authPort) {
        connectAuthEmulator(auth, `http://${host}:${authPort}`, { disableWarnings: true });
    }
}

function getPort(key: KeysOfType<ENVType, number>): number | null {
    const port = ENV[key];

    if (!port) {
        // eslint-disable-next-line no-console
        console.warn(
            `Firestore emulators enabled, but port ${key} is not provided. Remote service will be used.`,
        );

        return null;
    }

    return port;
}
