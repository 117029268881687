const pageNumberDataRole = 'pdf-page-number';
const pageTotalDataRole = 'pdf-page-total';
const headingDataRole = 'pdf-heading';
const headingIdDataRole = 'pdf-heading-id';
const tocSectionDataRole = 'pdf-toc-section';
const tocPageNumberDataRole = 'pdf-toc-page-number';
const embeddedPdfDataRole = 'pdf-embedded-pdf-id';
const pageBreakDataRole = 'pdf-page-break';

const sectionDataRole = 'pdf-section';
const sectionOrientationDataRole = 'pdf-section-orientation';

export const DOM_IDS = {
    headerId: 'header',
    footerId: 'footer',
    contentId: 'content',
    coverId: 'cover',
    tocId: 'tocId',
    pageStyleId: 'page-style',

    pageNumberDataRole,
    pageTotalDataRole,
    headingDataRole,
    headingIdDataRole,
    tocSectionDataRole,
    tocPageNumberDataRole,
    embeddedPdfDataRole,
    pageBreakDataRole,

    sectionOrientationDataRole,
    sectionDataRole,

    pageNumberSelector: dataRole(pageNumberDataRole),
    pageTotalSelector: dataRole(pageTotalDataRole),
    headingSelector: dataRole(headingDataRole),
    headingIdSelector: dataRole(headingIdDataRole),
    tocSectionSelector: dataRole(tocSectionDataRole),
    tocPageNumberSelector: dataRole(tocPageNumberDataRole),
    embeddedPdfSelector: dataRole(embeddedPdfDataRole),
    pageBreakSelector: dataRole(pageBreakDataRole),

    sectionSelector: dataRole(sectionDataRole),

    headingLevelAttr: 'data-level',
    tocSectionIdAttr: 'data-toc-section-id',
    embeddedPdfIdAttr: 'data-embedded-pdf-id',
} as const;

export type DomIds = typeof DOM_IDS;

function dataRole(value: string): string {
    return `[data-role="${value}"]`;
}
