
import { defineComponent } from 'vue';

import { DtgContent, DtgSpinner, getName, useBem } from 'dtg-components';

import { DtgOfflineIndicator } from '@/components/molecules/offline-indicator';
import { DtgHeader } from '@/components/organisms/header';

import { useNativePlugin } from '@/composables/use-native-plugins';
import { useNetworkStatus } from '@/composables/use-network-status';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgHeader,
        DtgSpinner,
        DtgOfflineIndicator,
        DtgContent,
    },

    inheritAttrs: false,

    props: {
        loading: {
            type: Boolean,
            default: false,
        },

        wrapperClass: {
            type: [String, Array, Object],
            default: '',
        },

        padded: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const withStatusBar = useNativePlugin('StatusBar');
        const { offline } = useNetworkStatus();

        void withStatusBar(async (plugin) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { StatusBar, Style } = plugin;

            await StatusBar.setBackgroundColor({ color: '#0E1A1F' });
            await StatusBar.setStyle({ style: Style.Dark });
        });

        return {
            b: useBem(styles),

            offline,
        };
    },
});
