import { ref } from 'vue';

import { useModalMessage } from 'dtg-components';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

import { useNativePlugin } from './use-native-plugins';

const FREE_SPACE_BREAKPOINT = 20 * 1024 * 1024; // 20 Mb
const FREE_SPACE_CHECK_INTERVAL = 1000 * 60 * 2; // 2 min

const isEnoughFreeSpace = ref(true);
let initialized = false;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDeviceDiskSpace() {
    const withDevice = useNativePlugin('Device');
    const modalMessage = useModalMessage();

    function initDeviceDiskSpace(): void {
        if (initialized || ENV.VUE_APP_PLATFORM === 'web') {
            return;
        }

        window.setInterval(checkFreeSpace, FREE_SPACE_CHECK_INTERVAL);

        initialized = true;
        void checkFreeSpace();
    }

    async function updateIsEnoughFreeSpace(): Promise<void> {
        await withDevice(async (plugin) => {
            let realDiskFree;

            try {
                ({ realDiskFree } = await plugin.Device.getInfo());
            } catch (error) {
                logError(error);

                isEnoughFreeSpace.value = true;

                return;
            }

            if (typeof realDiskFree !== 'number') {
                isEnoughFreeSpace.value = true;

                return;
            }

            isEnoughFreeSpace.value = realDiskFree > FREE_SPACE_BREAKPOINT;
        });
    }

    async function checkFreeSpace(): Promise<void> {
        if (ENV.VUE_APP_PLATFORM === 'web') {
            return;
        }

        await updateIsEnoughFreeSpace();

        if (!isEnoughFreeSpace.value) {
            await new Promise<void>((resolve) => {
                modalMessage.open({
                    title: 'Espace disponible limité',
                    message: `Vous n’avez plus beaucoup de stockage disponible
                    sur votre appareil. Vous serez donc limités
                    sur les fonctionnalités hors-ligne. Libérez
                    de l’espace en supprimant des applications, documents
                    ou photos inutilisées pour désactiver cette limitation.`,
                    confirmText: 'Oui, j’ai compris',
                    onClose: () => {
                        resolve();
                    },
                });
            });
        }
    }

    return { initDeviceDiskSpace, checkFreeSpace };
}
