import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_sort_control = _resolveComponent("dtg-sort-control")!
  const _component_dtg_select = _resolveComponent("dtg-select")!
  const _component_dtg_field = _resolveComponent("dtg-field")!
  const _component_dtg_col = _resolveComponent("dtg-col")!
  const _component_dtg_radio_group = _resolveComponent("dtg-radio-group")!
  const _component_dtg_row = _resolveComponent("dtg-row")!
  const _component_dtg_grid = _resolveComponent("dtg-grid")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b())
  }, [
    (_ctx.isWideScreen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.b('inner'))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortOptions, ({ id, value }) => {
            return (_openBlock(), _createBlock(_component_dtg_sort_control, {
              key: id,
              class: _normalizeClass(_ctx.b('sort-control', { [id]: true })),
              direction: _ctx.getFieldSortDirection(id),
              onSort: ($event: any) => (_ctx.onSortClick(id))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(value), 1)
              ]),
              _: 2
            }, 1032, ["class", "direction", "onSort"]))
          }), 128))
        ], 2))
      : (_openBlock(), _createBlock(_component_dtg_grid, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_dtg_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_dtg_col, {
                  "size-xs": "12",
                  "size-sm": "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_dtg_field, { label: "Trier par" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_dtg_select, {
                          modelValue: _ctx.sortKeyInternal,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortKeyInternal) = $event)),
                          options: _ctx.sortOptions,
                          name: "sortKey",
                          placeholder: "Sélectionnez"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_dtg_col, {
                  "size-xs": "12",
                  "size-sm": "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_dtg_field, { label: "Ordre" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_dtg_radio_group, {
                          modelValue: _ctx.sortDirectionInternal,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sortDirectionInternal) = $event)),
                          options: _ctx.sortDirections,
                          name: "sortDirection"
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ], 2))
}