import { BatiChiffrageQuote } from 'dtg-api/modules/bati-chiffrage';

import { curativeValues } from './builders';

export type ControlPointData = {
    comment: string;
    curative: CurativeValue | null;
    element: string;
    family: string;
    macroFamilyId: string | null;
    localisation: string;
    material: string[];
    notice: string[];
    state: string[];
    currency: string;
    planning: string | null;
    workNature: string[];
    costs: number | null;
    commentResolved: boolean;
    qty: number | null;
    totalCost: number | null;
    totalCostAdjusted: number | null;
    totalCostBatiChiffrage: number | null;
    costKey: typeof controlPointCostKeys[number];

    batiChiffrageQuote: { quote: BatiChiffrageQuote; updatedTimestamp: number } | null;

    createdTimestamp: number;
    valid: boolean;
    validPartially: {
        controlPoint: boolean;
        calculations: boolean;
    };

    name: string; // TODO: remove
    index: number;
};

export const controlPointCostKeys = ['totalCost', 'totalCostAdjusted', 'totalCostBatiChiffrage'] as const;

export const controlPointFormDataKeys = [
    'comment',
    'curative',
    'element',
    'family',
    'localisation',
    'material',
    'notice',
    'state',
    'currency',
    'planning',
    'workNature',
    'costs',
    'commentResolved',
    'qty',
    'totalCost',
    'costKey',
] as const;

export const controlPointCalcFormDataKeys = [
    'curative',
    'planning',
    'family',
    'localisation',
    'costs',
    'qty',
    'totalCost',
    'totalCostAdjusted',
    'totalCostBatiChiffrage',
    'costKey',
] as const;

export type ControlPointFormData = Pick<ControlPointData, typeof controlPointFormDataKeys[number]>;

export type ControlPointCalcFormData = Pick<ControlPointData, typeof controlPointCalcFormDataKeys[number]>;

export type ControlPointBaseFormData = Pick<
    ControlPointData,
    typeof controlPointCalcFormDataKeys[number] & typeof controlPointFormDataKeys[number]
>;

export type CurativeValue = typeof curativeValues[number];

export type CurativesRecord<T = string> = Record<CurativeValue, T>;

export type CurativeItem = {
    value: CurativeValue;
    title: string;
    titleShort: string;
    text: string;
    hasCalculations: boolean;
    isSevere: boolean;
};
