export const props = {
    dtgId: {
        type: String,
        required: true,
    },
    sharedActionsHidden: {
        type: Boolean,
        default: false,
    },
    canRepairValidation: {
        type: Boolean,
        default: false,
    },
    canDownloadReport: {
        type: Boolean,
        default: false,
    },
    backofficeLinkProps: {
        type: Object,
        default: () => ({}),
    },
} as const;
