import { FlattenTuple, KeyOf, KeysOf } from '../types/utils';

import {
    ValidationCondition,
    ValidationConditionArray,
    VCAnyConfig,
    VCApplied,
    VCInferConfig,
    VCKeysOfConditions,
    VCMultiConfig,
    VCMultiConfigFlattened,
} from './types';

/**
 * Factory method for defining conditions.
 *
 * See {@link defineSchemaWithConditions}.
 */
export function makeConditionsOf<T>(): <K extends KeysOf<T> | KeyOf<T>>(
    condition: VCInferConfig<T, K>,
) => ValidationCondition<typeof condition> {
    return (condition) => makeCondition<T, typeof condition>(condition);
}

/**
 * Should be used from  {@link makeConditionsOf}.
 */
export function makeCondition<T, U extends VCAnyConfig<T>>(condition: U): ValidationCondition<U> {
    return {
        apply: () => applyCondition<T>(condition),
        condition,
        check: condition.is,
        extend: (schemas) => makeCondition<T, U>({ ...condition, ...schemas }),
    };
}

export function applyCondition<T>(condition: VCAnyConfig<T>): VCApplied {
    const { is, then, otherwise } = condition;

    const key = 'key' in condition ? condition.key : [...condition.keys];

    return [
        key,
        {
            is,
            then,
            otherwise,
        },
    ];
}

/**
 * Factory method for combining multiple conditions into one.
 *
 * @example
 * const when = makeConditionsOf<ExampleType>();
 * const condition1 = when(...);
 * const condition2 = when(...);
 * const condition3 = when(...);
 * const combineConditions = combineConditionsOf<ExampleType>();
 *
 * const combinedCondition1 = combineConditions(condition1, condition2).extend({
 *     then: string().required()
 * });
 *
 * // Combined conditions can be combined further
 * const combinedCondition2 = combineConditions(combinedCondition1, condition3).extend({
 *     then: number().nullable().required(),
 * })
 */
export function combineConditionsOf<T>(): typeof combineConditions {
    function combineConditions<U extends ReadonlyArray<VCAnyConfig<T>>>(
        ...conditions: ValidationConditionArray<T, U>
    ): typeof condition {
        const condition = makeCondition<T, VCMultiConfig<T>>({
            keys: conditions.flatMap(({ condition }) => getConditionKeys(condition)) as FlattenTuple<
                VCKeysOfConditions<T, U>
            >,
            is: (...values): boolean => {
                let curValuesIndex = 0;

                for (const { condition, check } of conditions) {
                    const numKeys = getConditionKeys(condition).length;
                    const curValues = values.slice(curValuesIndex, curValuesIndex + numKeys);

                    curValuesIndex += numKeys;

                    if (!check(...curValues)) {
                        return false;
                    }
                }

                return true;
            },
        }) as unknown as ValidationCondition<VCMultiConfigFlattened<T, VCKeysOfConditions<T, U>>>;

        return condition;
    }

    return combineConditions;
}

function getConditionKeys<T>(condition: VCAnyConfig<T>): KeysOf<T> {
    return 'key' in condition ? [condition.key] : condition.keys;
}
