import firebase from 'firebase/compat/app';

import { FirebaseUploadReferenceLocal } from './FirebaseUploadReferenceLocal';
import { FirebaseUploadReferenceRemote } from './FirebaseUploadReferenceRemote';
import { FirebaseUploadTasks } from './FirebaseUploadTasks';
import {
    InferUploadType,
    UploadDataType,
    UploadReference,
    UploadReferenceLocal,
    UploadReferenceRemote,
} from './types';

export class FirebaseUploadReferences {
    private readonly storage: firebase.storage.Reference;
    private readonly uploads: FirebaseUploadTasks;

    constructor(storage: firebase.storage.Reference, uploads: FirebaseUploadTasks) {
        this.storage = storage;
        this.uploads = uploads;
    }

    getReference<T extends UploadDataType>(payload: {
        path: string;
        type: T;
    }): UploadReference<InferUploadType<T>> {
        return this.getLocalReference(payload) ?? this.getRemoteReference(payload);
    }

    private getLocalReference<T extends UploadDataType>({
        path,
        type,
    }: {
        path: string;
        type: T;
    }): UploadReferenceLocal<InferUploadType<T>> | null {
        const upload = this.uploads.getUpload({ path });

        return upload ? new FirebaseUploadReferenceLocal(type, upload) : null;
    }

    private getRemoteReference<T extends UploadDataType>({
        path,
        type,
    }: {
        path: string;
        type: T;
    }): UploadReferenceRemote<InferUploadType<T>> {
        const reference = this.storage.child(path);

        return new FirebaseUploadReferenceRemote(type, reference);
    }
}
