import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_link = _resolveComponent("dtg-link")!

  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('links'))
    }, [
      _createVNode(_component_dtg_link, {
        class: _normalizeClass(_ctx.b('link')),
        href: _ctx.privacyPolicyUrl,
        routed: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Politique de Confidentialité ")
        ]),
        _: 1
      }, 8, ["class", "href"]),
      _createVNode(_component_dtg_link, {
        class: _normalizeClass(_ctx.b('link')),
        href: _ctx.termsOfSalesUrl,
        routed: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Conditions Générales de Vente ")
        ]),
        _: 1
      }, 8, ["class", "href"]),
      _createVNode(_component_dtg_link, {
        class: _normalizeClass(_ctx.b('link')),
        href: _ctx.termsOfServiceUrl,
        routed: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Conditions Générales d’utilisation ")
        ]),
        _: 1
      }, 8, ["class", "href"])
    ], 2)
  ], 2))
}