
import { defineComponent, PropType } from 'vue';

import { DtgAction, DtgIcon, getName, useBem } from 'dtg-components';
import { SortableListDirection } from 'dtg-shared/composables/use-sortable-list';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgAction,
        DtgIcon,
    },

    props: {
        direction: {
            type: String as PropType<SortableListDirection>,
            // eslint-disable-next-line no-undefined
            default: undefined,
        },
    },

    emits: ['sort'],

    setup(props, { emit }) {
        function onSortClick(): void {
            emit('sort');
        }

        return {
            b: useBem(styles),

            onSortClick,
        };
    },
});
