import { computed, reactive } from 'vue';

import { ReportApiGenerateResponse } from 'dtg-api/classes/ReportApi';
import {
    ReportDownloadStartPollingParams,
    useReportDownload as useReportDownloadBase,
} from 'dtg-api/composables/use-report-download';
import { useModalReport } from 'dtg-components';
import { ReportData, ReportStatus } from 'dtg-firebase/modules/report';
import { ReadonlyRef } from 'dtg-shared/types/vue';
import { noop } from 'dtg-shared/utils/noop';
import { PdfPollResultFile, PdfType } from 'dtg-shared-report';

import { useNativePlugin } from '@/composables/use-native-plugins';
import { useNetworkStatus } from '@/composables/use-network-status';

import { getDb, getStorage } from '@/services/firebase';
import { reportApiService } from '@/services/report-api';

import { useStoreCollection } from '@/store';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useReportDownload({ dtgId, type }: { dtgId: ReadonlyRef<string>; type: PdfType }) {
    const db = getDb();
    const storage = getStorage();

    const modalReport = useModalReport();
    const { offline } = useNetworkStatus();
    const {
        getters: { getItemThrows },
    } = useStoreCollection('dtg');
    const withBrowser = useNativePlugin('Browser');

    let unsubscribeFromSnapshot = noop;
    let cancelGenerating = noop;

    const disabled = computed(() => offline.value);
    const dtg = computed(() => getItemThrows(dtgId.value));
    const dtgName = computed(() => dtg.value.data.name);
    const reportApi = reportApiService.get();

    const { download } = useReportDownloadBase({
        openModal({ cancel, retry, ...props }) {
            modalReport.open({
                ...props,
                dtgName,
                reportType: type,
                onCancel: cancel,
                onRetry: retry,
                onDownload: async (event) => {
                    await onDownload(props.downloadUrl.value, event);
                },
            });
        },
        startGenerating,
        startPolling,
        stopPolling,
        computeStatus: (status) => (offline.value ? ReportStatus.pending : status),
        logError,
    });

    function startPolling(params: ReportDownloadStartPollingParams): void {
        unsubscribeFromSnapshot = db.getDoc<ReportData>(params.docPath).onSnapshot(
            async (snapshot) => {
                const data = snapshot.data();
                let file;

                try {
                    file = await onSnapshot({ data, ...params });
                } catch (error) {
                    params.reject(error);

                    return;
                }

                if (file) {
                    params.resolve(file);
                }
            },
            (error) => {
                if (error.code !== 'cancelled') {
                    params.reject(error);
                }
            },
        );
    }

    function stopPolling(): void {
        unsubscribeFromSnapshot();
        cancelGenerating();
    }

    async function startGenerating(): Promise<ReportApiGenerateResponse> {
        const controller = new AbortController();

        cancelGenerating = controller.abort.bind(controller);

        return reportApi.generateReport({ dtgId: dtgId.value, type }, controller.signal);
    }

    async function onSnapshot({
        data,
        setStatus,
        setProgress,
    }: {
        data?: ReportData;
    } & Omit<ReportDownloadStartPollingParams, 'docPath'>): Promise<PdfPollResultFile | null> {
        if (!data) {
            throw new Error('Document not found');
        }

        setStatus(data.status);
        setProgress(data.progress);

        if (data.status === ReportStatus.error) {
            logError(data.error);

            throw new Error('Report generation failed');
        }

        if (!data.file) {
            return null;
        }

        stopPolling();

        const { storagePath, name } = data.file;
        const url = await getDownloadUrl(storagePath);

        return { url, name };
    }

    async function getDownloadUrl(path: string): Promise<string> {
        const reference = storage.getBlobReference({ path });

        try {
            return await reference.getUrl();
        } catch (error) {
            logError(error);

            throw new Error('Failed to get url');
        }
    }

    async function onDownload(url: string | null, event: Event): Promise<void> {
        if (ENV.VUE_APP_PLATFORM === 'web' || !url) {
            return;
        }

        event.preventDefault();

        await withBrowser(async (plugin) => {
            await plugin.Browser.open({ url });
        });
    }

    return reactive({
        download,
        disabled,
    });
}
