import { array, boolean, mixed, number, string } from 'yup';

import { truthy } from 'dtg-shared/utils/truthy';
import {
    combineConditionsOf,
    defineSchemaWithConditions,
    makeConditionsOf,
    VCSingleConfig,
} from 'dtg-shared/validation';

import { curativeValues } from './builders';
import {
    ControlPointBaseFormData,
    ControlPointCalcFormData,
    controlPointCostKeys,
    ControlPointFormData,
    CurativeItem,
    CurativeValue,
} from './types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function buildBaseSchema() {
    return {
        curative: mixed()
            .oneOf([...curativeValues, null])
            .nullable()
            .defined(),

        costs: number().label('Coût unitaire').nullable().defined(),
        totalCost: number().label('Coût total (HT)').nullable().defined(),
        qty: number().label('Quantité').nullable().defined(),
        currency: string().label('Devise').defined(),
        planning: string().label('Planification').nullable().defined(),
        family: string().label('Famille').defined(),
        localisation: string().label('Localisation').defined(),
        costKey: mixed()
            .oneOf([...controlPointCostKeys])
            .required(),
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function controlPointValidationSchema(curatives: CurativeItem[]) {
    const baseSchema = buildBaseSchema();
    const when = makeConditionsOf<ControlPointFormData>();
    const combineConditions = combineConditionsOf<ControlPointFormData>();
    const isCostKeyTotalCostConditional = when(buildCostKeyConditional('totalCost'));
    const hasCalculationsConditional = when(buildCurativeHasCalculationsConditional(curatives));

    return defineSchemaWithConditions<ControlPointFormData>()(
        {
            curative: baseSchema.curative.required(),
            comment: string().label('Note interne').defined(),
            element: string().label('Élément').required(),
            family: baseSchema.family.required(),
            localisation: baseSchema.localisation.required(),
            material: array().label('Matériaux').of(string().defined()).defined(),
            notice: array().label('Observations').of(string().defined()).defined(),
            state: array().label('État').of(string().defined()).required().min(1),
            costs: baseSchema.costs,
            qty: baseSchema.qty,
            costKey: baseSchema.costKey,
            totalCost: baseSchema.totalCost,
            currency: baseSchema.currency,
            planning: baseSchema.planning.defined(),
            workNature: array().label('Nature des travaux').of(string().defined()).required().min(1),
            commentResolved: boolean().label('Note résolue').defined(),
        },
        {
            costs: combineConditions(
                isCostKeyTotalCostConditional,
                when({
                    key: 'totalCost',
                    is: Boolean,
                }),
            ).extend({
                then: number().nullable().required(),
            }),
            totalCost: combineConditions(
                isCostKeyTotalCostConditional,
                when({
                    key: 'costs',
                    is: Boolean,
                }),
            ).extend({
                then: number().nullable().required(),
            }),
            currency: combineConditions(
                hasCalculationsConditional,
                isCostKeyTotalCostConditional,
                when({
                    keys: ['totalCost', 'costs'] as const,
                    is: (...args) => args.some(truthy),
                }),
            ).extend({
                then: string().required(),
            }),
            planning: when(buildNeedsRepairConditional(curatives)).extend({
                then: string().nullable().required(),
            }),
            commentResolved: when({
                key: 'comment',
                is: Boolean,
                then: boolean().required().oneOf([true], 'Veuillez indiquer si la note est résolue'),
            }),
        },
        [['totalCost', 'costs']],
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function controlPointCalcValidationSchema(curatives: CurativeItem[]) {
    const baseSchema = buildBaseSchema();
    const when = makeConditionsOf<ControlPointCalcFormData>();
    const combineConditions = combineConditionsOf<ControlPointCalcFormData>();

    const needsRepairConditional = when(buildNeedsRepairConditional(curatives));
    const hasCalculationsConditional = when(buildCurativeHasCalculationsConditional(curatives));

    const applicableConditional = when({
        keys: ['curative', 'planning', 'family'] as const,
        is: (...args) => args.every(truthy),
    });

    const costConditional = combineConditions(
        applicableConditional,
        hasCalculationsConditional,
        needsRepairConditional,
        when(buildCostKeyConditional('totalCost')),
    ).extend({
        then: number().nullable().required(),
    });

    const totalCostAdjustedCondition = combineConditions(
        applicableConditional,
        hasCalculationsConditional,
        when(buildCostKeyConditional('totalCostAdjusted')),
    ).extend({
        then: number().nullable().required(),
    });

    const totalCostBatiChiffrageCondition = combineConditions(
        applicableConditional,
        hasCalculationsConditional,
        when(buildCostKeyConditional('totalCostBatiChiffrage')),
    ).extend({
        then: number().nullable().required(),
    });

    return defineSchemaWithConditions<ControlPointCalcFormData>()(
        {
            curative: baseSchema.curative,
            costs: baseSchema.costs,
            qty: baseSchema.qty,
            totalCost: baseSchema.totalCost,
            totalCostAdjusted: number().label('Ajuster le cout final').nullable().defined(),
            totalCostBatiChiffrage: number().label('Trouver sur BatiChiffrage').nullable().defined(),
            costKey: baseSchema.costKey,
            family: baseSchema.family,
            localisation: baseSchema.localisation,
            planning: baseSchema.planning,
        },
        {
            costs: costConditional,
            totalCost: costConditional,
            totalCostAdjusted: totalCostAdjustedCondition,
            totalCostBatiChiffrage: totalCostBatiChiffrageCondition,
        },
    );
}

function buildNeedsRepairConditional(
    curatives: CurativeItem[],
): VCSingleConfig<ControlPointBaseFormData, 'curative'> {
    return {
        key: 'curative' as const,
        is: (curative): boolean => {
            return curatives.some(({ value, isSevere }) => value === curative && isSevere);
        },
    };
}

function buildCostKeyConditional(
    key: typeof controlPointCostKeys[number],
): VCSingleConfig<ControlPointCalcFormData, 'costKey'> {
    return {
        key: 'costKey',
        is: (costKey): boolean => costKey === key,
    };
}

function buildCurativeHasCalculationsConditional(
    curatives: CurativeItem[],
): VCSingleConfig<ControlPointBaseFormData, 'curative'> {
    return {
        key: 'curative',
        is: (curative) => curativeHasCalculations(curative, curatives),
    };
}

export function curativeHasCalculations(
    curative: CurativeValue | null,
    curativeItems: CurativeItem[],
): boolean {
    return curativeItems.some(({ hasCalculations, value }) => curative === value && hasCalculations);
}
