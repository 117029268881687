
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { getName, inRecord, propInternal, useBem, useScrollSidebar } from 'dtg-components';
import { toReadonlyRefs } from 'dtg-shared/utils/refs';

import { DashBoardRoute } from '@/composables/use-dashboard-routes';

import { DtgSidebarItem } from './elems/sidebar-item';
import { DtgSidebarView } from './types';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgSidebarItem,
    },

    props: {
        view: {
            type: String as PropType<DtgSidebarView>,
            required: true,
            validator: inRecord<DtgSidebarView>(DtgSidebarView),
        },

        open: {
            type: Boolean,
            default: false,
        },

        scrollPosition: {
            type: Object as PropType<{ top: number; left: number }>,
            required: true,
        },

        routes: {
            type: Array as PropType<DashBoardRoute[]>,
            required: true,
        },
    },

    emits: ['update:scrollPosition'],

    setup(props, { emit }) {
        const root = ref<HTMLElement>();
        const scrollPositionInternal = propInternal(props, 'scrollPosition', emit);
        const { routes } = toReadonlyRefs(props);
        const currentRoute = useRoute();

        const activeIndex = computed(() => {
            return routes.value.findIndex((route) => currentRoute.name === route.name);
        });

        const { onScroll, setRefByIndex, scrollToCurrentItem } = useScrollSidebar({
            scrollPosition: scrollPositionInternal,
            activeIndex,
            root,
        });

        watch(
            () => currentRoute.name,
            () => {
                scrollToCurrentItem();
            },
        );

        return {
            b: useBem(styles),

            root,
            setRefByIndex,

            onScroll,
        };
    },
});
