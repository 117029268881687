import { boolean, mixed, number, object, string } from 'yup';

import { LocationOption } from 'dtg-shared/types/data';
import { objValues } from 'dtg-shared/utils/object';
import {
    defineSchemaOf,
    defineSchemaWithConditions,
    email,
    makeConditionsOf,
    objectSchema,
    phoneNumber,
    ValidationSchema,
} from 'dtg-shared/validation';

import { buildCurativeRecord, CurativesRecord } from '../control-point';

import {
    DtgConclusionData,
    DtgFormBuildingComplexData,
    DtgFormInformationData,
    DtgFormLocationData,
    DtgFormPhotosData,
    DtgType,
} from './types';

export function validationSchemaBuildingComplex(): ValidationSchema<DtgFormBuildingComplexData> {
    return defineSchemaOf<DtgFormBuildingComplexData>()({
        appartmentCount: number().nullable().defined().label('Nombre de lots totaux'),
        buildingCount: number().label('Nombre de bâtiment').nullable().required(),
        shob: number().label('Surface hors œuvre brute approx.').nullable().defined(),
        typologyId: string().label('Typologie').nullable().required(),
        registerNumber: string().label('N° immatriculation').defined(),
        buildingComplexDescription: string().label('Description sommaire de l’ensemble immobilier').defined(),
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function validationSchemaInformation() {
    const when = makeConditionsOf<DtgFormInformationData>();

    const isOwnerConditional = when({
        key: 'isOwner',
        is: (value): boolean => !value,
        then: string().required(),
    });

    return defineSchemaWithConditions<DtgFormInformationData>()(
        {
            isOwner: boolean().defined(),

            dtgType: mixed()
                .oneOf([...objValues(DtgType), null])
                .label('Type de Diagnostic Technique Global')
                .required()
                .nullable(),
            visitDate: string().label('Date de la visite').required().nullable(),
            endDate: string().label('Date du rendu attendu').required().nullable(),

            nameOwner: string().label('Nom').required(),
            firstNameOwner: string().label('Prénom').defined(),
            locationOwner: objectSchema<LocationOption>().label('Adresse').nullable().required(),
            phoneOwner: phoneNumber().label('Teléphone').required(),
            emailOwner: email().label('Email').required(),

            nameContactOwner: string().label('Nom').defined(),
            firstNameContactOwner: string().label('Prénom').defined(),
            locationContactOwner: objectSchema<LocationOption>().label('Adresse').nullable().defined(),
            societyContactOwner: string().label('Société').defined(),
            functionContactOwner: string().label('Fonction').defined(),
            phoneContactOwner: phoneNumber().label('Teléphone').defined(),
            emailContactOwner: email().label('Email').defined(),
        },
        {
            nameContactOwner: isOwnerConditional,
            locationContactOwner: isOwnerConditional.extend({
                then: objectSchema().nullable().required(),
            }),
            phoneContactOwner: isOwnerConditional,
            emailContactOwner: isOwnerConditional.extend({
                then: email().required(),
            }),
        },
    );
}

export function validationSchemaLocation(): ValidationSchema<DtgFormLocationData> {
    return defineSchemaOf<DtgFormLocationData>()({
        location: objectSchema<LocationOption>().label("Lieu de I'intervention").nullable().required(),
        addressExtra: string().label('Complément d’adresse').defined(),
        addressFree: string().label('Adresse figurant sur le rapport').defined(),
        visitDate: string().label('Date de la visite').required().nullable(),
    });
}

export function validationSchemaPhotos(): ValidationSchema<DtgFormPhotosData> {
    return defineSchemaOf<DtgFormPhotosData>()({
        coverPhotoId: string().nullable().required(),
    });
}

export function validationSchemaConclusion(): ValidationSchema<DtgConclusionData> {
    return defineSchemaOf<DtgConclusionData>()({
        documentsState: string().label('').nullable().required(),
        energyState: string().label('').nullable().required(),
        visualState: string().label('').nullable().required(),
        costState: string().label('').required(),
        conclusion: string().label('').defined(),
    });
}

export function validationCurativeOpinions(): ValidationSchema<CurativesRecord> {
    const ruleEntries = Object.entries(buildCurativeRecord(false)).map(([key]) => {
        return [key, string().nullable()];
    });

    return object(Object.fromEntries(ruleEntries));
}
