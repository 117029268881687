import camelCase from 'lodash/camelCase';
import lodashPick from 'lodash/pick';

import { truthy } from './truthy';

export type Entries<T> = Array<[keyof T, Exclude<T[keyof T], undefined>]>;

export function objEntries<T extends Record<PropertyKey, unknown>>(object: T): Entries<T> {
    return Object.entries(object) as Entries<T>;
}

export function objValues<T, K extends PropertyKey>(object: Record<K, T>): T[] {
    return Object.values(object);
}

export function objKeys<T extends Record<PropertyKey, unknown>>(object: T): Array<keyof T> {
    return Object.keys(object);
}

export function objFromEntries<T extends PropertyKey, U>(
    entries: ReadonlyArray<readonly [T, U]>,
): Readonly<{ [K in T]: U }> {
    return Object.fromEntries(entries) as { [K in T]: U };
}

export function objEntriesIncludingSymbols<T extends Record<PropertyKey, unknown>>(
    object: T,
    symbols: symbol[],
): Entries<T> {
    const symbolEntries = symbols
        .map((symbol) => {
            return symbol in object && [symbol, object[symbol]];
        })
        .filter(truthy);

    return [...objEntries(object), ...symbolEntries] as Entries<T>;
}

export function objAssignExceptUndefined<T>(target: T, source: Partial<T>): T {
    for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key) && typeof source[key] !== 'undefined') {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            target[key] = source[key]!;
        }
    }

    return target;
}

export function objPick<T extends object, U extends keyof T>(obj: T, keys: Readonly<U[]>): Pick<T, U> {
    return lodashPick<T, U>(obj, keys);
}

export function objMatches<T extends Record<string, unknown>>(matchers: T) {
    return (item: Partial<Record<keyof T, unknown>>): boolean => {
        return Object.entries(matchers).every(([key, value]) => {
            return typeof value === 'undefined' || item[key] === value;
        });
    };
}

export function objCreate<T extends Record<PropertyKey, unknown>>(): T {
    return Object.create(null) as T;
}

export function objKeysToCamelCase(obj: unknown): unknown {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(objKeysToCamelCase);
    }

    const transformedEntries: Array<[string, unknown]> = objEntries(obj as Record<string, unknown>).map(
        ([key, value]) => {
            return [camelCase(key), objKeysToCamelCase(value)];
        },
    );

    return objFromEntries(transformedEntries);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function objectToConstructor(object: unknown, base: Function): Function {
    if (typeof object === 'function') {
        return object;
    }

    // @ts-expect-error base is a class
    const constructor = class extends base {};

    Object.getOwnPropertyNames(object).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.defineProperty(constructor.prototype, key, Object.getOwnPropertyDescriptor(object, key)!);
    });

    return constructor;
}
