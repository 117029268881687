import { computed, ref } from 'vue';

export type Progress = {
    value: number;
    adjustedValue: number;
    stalling: boolean;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useProgress({
    totalSteps,
    subSteps = 100,
    updateInterval = 175,
    maxStepsOvershot = 5,
}: {
    totalSteps: number;
    subSteps?: number;
    updateInterval?: number;
    maxStepsOvershot?: number;
}) {
    const stepLength = 1 / totalSteps;
    const state = ref<Progress>({ value: 0, adjustedValue: 0, stalling: false });

    let progressIntervalId = -1;

    function resetProgress(): void {
        state.value = { value: 0, adjustedValue: 0, stalling: false };

        disableProgressUpdate();
    }

    function setProgress(value: number): void {
        const adjustedValue = Math.max(value, state.value.adjustedValue);
        const stalling = adjustedValue > calculateAdjustmentClamp(value);

        state.value = {
            value,
            adjustedValue,
            stalling,
        };
    }

    function enableProgressUpdate(): void {
        disableProgressUpdate();
        progressIntervalId = window.setInterval(updateProgress, updateInterval);
    }

    function disableProgressUpdate(): void {
        clearInterval(progressIntervalId);
    }

    function updateProgress(): void {
        const { value, adjustedValue } = state.value;

        if (value === 0 || value === 1) {
            return;
        }

        const adjustmentClamp = calculateAdjustmentClamp(value);
        const newAdjustedValue = adjustedValue + stepLength / subSteps;
        const adjustment = stepLength / subSteps;

        const newAdjustedValueClamped = Math.min(adjustmentClamp, adjustedValue + adjustment);
        const stalling = newAdjustedValue > adjustmentClamp;

        state.value = { value, adjustedValue: newAdjustedValueClamped, stalling };
    }

    // Allows adjusted progress to overflow steps,
    // but disallows it from filling up the entire progress bar,
    // unless at the last step already
    function calculateAdjustmentClamp(value: number): number {
        const penultimateStep = 1 - stepLength;
        const nextStep = value + stepLength;

        return Math.min(1, Math.max(penultimateStep, nextStep), value + stepLength * maxStepsOvershot);
    }

    return {
        progress: computed(() => state.value.adjustedValue),
        stalling: computed(() => state.value.stalling),

        setProgress,
        resetProgress,
        enableProgressUpdate,
        disableProgressUpdate,
    };
}
