import { computed } from 'vue';

import { openToast, useModalConfirm } from 'dtg-components';
import { ReadonlyRef } from 'dtg-shared/types/vue';
import { PdfType } from 'dtg-shared-report';

import { useStoreModule } from '@/store';

import { buildBackofficeDtgRoute } from '@/utils/backoffice-app-routes';
import { logError } from '@/utils/log-error';
import { ERROR } from '@/utils/toast-message';

import { useBackofficeLink } from './use-backoffice-link';
import { useReportDownload } from './use-report-download';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDtgListItemSharedActions({ dtgId }: { dtgId: ReadonlyRef<string> }) {
    const {
        actions: { repairDtgValidation },
    } = useStoreModule('functions');

    const modalConfirm = useModalConfirm();

    const backofficeHref = computed(() => buildBackofficeDtgRoute(dtgId.value));
    const backofficeLinkProps = useBackofficeLink(backofficeHref);

    async function repairValidation(): Promise<void> {
        const confirmed = await modalConfirm.confirm({
            title: 'Confirmer la réparation de validation',
            text: 'Voulez-vous réparer la validation de le DTG\xa0?',
        });

        if (confirmed) {
            const toast = await openToast('Réparer la validation...', { duration: -1 });

            try {
                await repairDtgValidation({ dtgId: dtgId.value });
            } catch (error) {
                logError(error);

                void openToast(ERROR);
            } finally {
                void toast.dismiss();
            }

            void openToast('Validation réparée');
        }
    }

    function reportDownload(type: PdfType): { download: () => Promise<void>; disabled: boolean } {
        return useReportDownload({ dtgId, type });
    }

    return {
        backofficeLinkProps,
        reportDownload,
        repairValidation,
    };
}
