import { RouteRecordRaw } from 'vue-router';

import { truthy } from 'dtg-shared/utils/truthy';

import {
    PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID,
    PAGE_ASSISTANT_ADD_CONTROL_POINT_FAMILY_ID,
    PAGE_ASSISTANT_BUILDING_ID,
} from '../consts';
import {
    Route,
    RouteAssistantControlPointElementQuery,
    RouteAssistantControlPointFamilyQuery,
    RouteAssistantRecapQuery,
} from '../types';

export const assistantRoutes: RouteRecordRaw[] = [
    {
        path: '/assistant/:id',
        component: async () =>
            import(
                /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant/component.vue'
            ),
        children: [
            {
                path: '',
                name: Route.assistant,
                component: async () =>
                    import(
                        /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-buildings/component.vue'
                    ),
            },
            {
                path: 'recap',
                name: Route.assistantRecap,
                redirect: { name: Route.assistantControlPoints },
                component: async () =>
                    import(
                        /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-recap/component.vue'
                    ),
                props: (to): { query: RouteAssistantRecapQuery } => {
                    const buildingId = to.query[PAGE_ASSISTANT_BUILDING_ID];

                    return {
                        query: {
                            buildingId: buildingId ? String(buildingId) : null,
                        },
                    };
                },
                children: [
                    {
                        path: 'control-points',
                        name: Route.assistantControlPoints,
                        component: async () =>
                            import(
                                /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-control-points/component.vue'
                            ),
                    },
                    {
                        path: 'families',
                        name: Route.assistantFamilies,
                        component: async () =>
                            import(
                                /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-families/component.vue'
                            ),
                    },
                ],
            },
        ],
    },
    {
        path: '/assistant/:id/add-control-point/family',
        name: Route.assistantAddControlPointFamily,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-control-point-family/component.vue'
            ),
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        beforeEnter: (to) => {
            const { query } = to;
            const buildingId = query[PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID];

            return buildingId ? true : { name: Route.assistant, params: to.params };
        },
        props: (to): { query: RouteAssistantControlPointFamilyQuery } => {
            // query param existing was checked in the beforeEnter
            const buildingId = to.query[PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID]!;

            return {
                query: { buildingId: String(buildingId) },
            };
        },
    },
    {
        path: '/assistant/:id/add-control-point/element',
        name: Route.assistantAddControlPointElement,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-assistant" */ '../../components/pages/page-assistant-control-point-element/component.vue'
            ),
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        beforeEnter: (to) => {
            const { query } = to;
            const buildingId = query[PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID];
            const familyId = query[PAGE_ASSISTANT_ADD_CONTROL_POINT_FAMILY_ID];

            return truthy(buildingId && familyId) ? true : { name: Route.assistant, params: to.params };
        },
        props: (to): { query: RouteAssistantControlPointElementQuery } => {
            // query param existing was checked in the beforeEnter
            const buildingId = to.query[PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID]!;
            // query param existing was checked in the beforeEnter
            const familyId = to.query[PAGE_ASSISTANT_ADD_CONTROL_POINT_FAMILY_ID]!;

            return {
                query: {
                    buildingId: String(buildingId),
                    familyId: String(familyId),
                },
            };
        },
    },
];
