/* eslint-disable @typescript-eslint/consistent-type-imports */

import { Module } from 'vuex-smart-module';

import { DefineRootModule, ModuleOptionsOf } from 'dtg-shared/store/types';

// We have to define this explicitly
// otherwise "Go to definition" stops working in WebStorm on store methods
export type RootModule = DefineRootModule<{
    app: typeof import('./app')['app'];
    auth: typeof import('./auth')['auth'];
    autosave: typeof import('./autosave')['autosave'];
    collections: typeof import('./collections')['collections'];
    dtgValidation: typeof import('./dtg-validation')['dtgValidation'];
    file: typeof import('./file')['file'];
    functions: typeof import('./functions')['functions'];
    lists: typeof import('./lists')['lists'];
    pages: typeof import('./pages')['pages'];
    rules: typeof import('./rules')['rules'];
    storagePhoto: typeof import('./storage-photo')['storagePhoto'];
}>;

export type CollectionsModule = ModuleOptionsOf<RootModule>['collections'];
export type FileModule = ModuleOptionsOf<RootModule>['file'];

export async function createRootModule(): Promise<RootModule> {
    return new Module({
        modules: {
            app: (await import('./app')).app,
            auth: (await import('./auth')).auth,
            autosave: (await import('./autosave')).autosave,
            collections: (await import('./collections')).collections,
            dtgValidation: (await import('./dtg-validation')).dtgValidation,
            file: (await import('./file')).file,
            functions: (await import('./functions')).functions,
            lists: (await import('./lists')).lists,
            pages: (await import('./pages')).pages,
            rules: (await import('./rules')).rules,
            storagePhoto: (await import('./storage-photo')).storagePhoto,
        },
    });
}
