export type UnvisitedRoomData = {
    localisation: string;
    nature: string;
    motif: string;
    visibleInReport: boolean;

    createdTimestamp: number;
    valid: boolean;
};

export const unvisitedRoomFormDataKeys = ['localisation', 'nature', 'motif', 'visibleInReport'] as const;

export type UnvisitedRoomFormData = Pick<UnvisitedRoomData, typeof unvisitedRoomFormDataKeys[number]>;
