import { TokenStorage } from './types';

export class TokenLocalStorage implements TokenStorage {
    getToken(key: string): string | null {
        return localStorage.getItem(key) ?? null;
    }

    setToken(key: string, value: string | null): void {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
}
