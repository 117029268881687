import { useNetworkStatusDebug } from '@/composables/use-network-status';

import { getFirestore, getFunctions, getStorage } from '@/services/firebase';

import { useStoreModule } from '@/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitDebug() {
    const { disableNetwork, enableNetwork } = useNetworkStatusDebug();

    const {
        actions: { repairDtgValidation, repairAllDtgsValidation, generateMissingThumbnails },
    } = useStoreModule('functions');

    function initDebug(): void {
        // @ts-expect-error debug
        window.disableNetwork = disableNetwork;
        // @ts-expect-error debug
        window.enableNetwork = enableNetwork;
        // @ts-expect-error debug
        window.firestore = getFirestore();
        // @ts-expect-error debug
        window.firebaseStorage = getStorage();
        // @ts-expect-error debug
        window.firebaseFunctions = getFunctions();
    }

    function initAdminFunctions(): void {
        // @ts-expect-error admin functions
        window.adminFunctions = {
            repairDtgValidation,
            repairAllDtgsValidation,
            generateMissingThumbnails,
        };
    }

    return {
        initDebug,
        initAdminFunctions,
    };
}
