import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_title = _resolveComponent("dtg-title")!
  const _component_dtg_about_footer = _resolveComponent("dtg-about-footer")!
  const _component_dtg_layout_without_sidebar = _resolveComponent("dtg-layout-without-sidebar")!

  return (_openBlock(), _createBlock(_component_dtg_layout_without_sidebar, null, {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_dtg_title, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('content'))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _createVNode(_component_dtg_about_footer, {
        class: _normalizeClass(_ctx.b('footer'))
      }, null, 8, ["class"])
    ]),
    _: 3
  }))
}