export function clampLooped(value: number, min: number, max: number): number {
    switch (true) {
        case value > max:
            return min;
        case value < min:
            return max;
        default:
            return value;
    }
}

export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}

export function translateValue(
    value: number,
    [leftMin, leftMax]: [number, number],
    [rightMin, rightMax]: [number, number],
): number {
    const leftSpan = leftMax - leftMin;
    const rightSpan = rightMax - rightMin;

    const valueScaled = (value - leftMin) / leftSpan;

    return rightMin + valueScaled * rightSpan;
}

export function roundToPrecision(value: number, precision: number): number {
    return parseFloat(value.toFixed(precision));
}

export function areFloatsEqual(a: number, b: number): boolean {
    return Math.abs(a - b) < Number.EPSILON;
}
