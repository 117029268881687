import { generateId } from 'dtg-shared/utils/generate-id';
import { truthy } from 'dtg-shared/utils/truthy';

import { buildPath, CollectionType, pathFromType } from '../firestore';

import { PhotoData } from './types';

export function buildPhotoCollectionPath({
    parentPath,
    parentId,
    docId = null,
}: {
    parentPath: string;
    parentId?: string;
    docId?: string | null;
}): string {
    return buildPath(...[parentPath, parentId].filter(truthy), pathFromType(CollectionType.photo), docId);
}

export function buildPhotoData({
    storagePath = generateId(),
    name = storagePath,
    timestampCreated = Date.now(),
}: Partial<PhotoData> = {}): PhotoData {
    return {
        storagePath,
        name,
        timestampCreated,
    };
}
