import { ParcelData } from 'dtg-firebase/modules/parcel';
import { LatLngLiteral } from 'dtg-shared/types/data';

import { ApiResponse, ApiResponseError } from '../../classes/Api';

export type ParcelExtraParsed = {
    number: string;
    prefix: string;
    section: string;
    area: string;
};

/* eslint-disable @typescript-eslint/naming-convention */
export type CadastralEntry = {
    geometry: {
        coordinates: Array<Array<[number, number]>>;
    };
    properties: {
        cadastre_type: string;
        cadastre_id: string;
        feature_id: string;
        extra: string;
        name: string;
        geom_centroid: string;
        city_id: number;
        category: string;
        value: number;
        order: number;
        layerName: string;
        id: number;
    };
};

/* Request types */
export type ApiParcelFindRequest = LatLngLiteral & {
    number: string;
    section: string;
    prefix: string;
};

export enum ApiParcelFindErrorCodes {
    notFound = 'parcel/not-found',
}
export type ApiParcelFindError = ApiResponseError<ApiParcelFindErrorCodes>;
export type ApiParcelFindResponseRaw = ApiResponse<CadastralEntry, ApiParcelFindError>;
export type ApiParcelFindResponse = ApiResponse<ParcelData, ApiParcelFindError>;
