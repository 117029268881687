export const PAGE_CONTROL_POINTS_QUERY_ID = 'cpId';

export const PAGE_CONTROL_POINTS_QUERY_LIST = 'list';

export const PAGE_CONTROL_POINTS_BUILDING_ID = 'buildingId';

export const PAGE_ASSISTANT_BUILDING_ID = 'buildingId';

export const PAGE_ASSISTANT_ADD_CONTROL_POINT_BUILDING_ID = 'buildingId';

export const PAGE_ASSISTANT_ADD_CONTROL_POINT_FAMILY_ID = 'familyId';
