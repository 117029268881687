
import { defineComponent } from 'vue';

import { DtgLink, DtgModalContent, events, getName, useBem } from 'dtg-components';

import badgeAppStoreUrl from './badge-app-store.svg';
import { props } from './props';
import { DtgModalAppStoreEvents } from './types';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgModalContent,
        DtgLink,
    },

    props,

    emits: events(['close'])<DtgModalAppStoreEvents>(),

    setup() {
        const playMarketUrl =
            'https://play.google.com/store/apps/details?id=fr.kinaxia.dtg_mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
        const appStoreUrl = 'https://apps.apple.com/fr/app/mydtg/id1613744134';

        return {
            b: useBem(styles),

            badgeAppStoreUrl,

            appStoreUrl,
            playMarketUrl,
        };
    },
});
