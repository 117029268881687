export function doubleRAF(): Promise<void>;
export function doubleRAF(callback: () => void): void;

// eslint-disable-next-line @typescript-eslint/promise-function-async
export function doubleRAF(callback?: () => void): Promise<void> | void {
    if (typeof callback === 'function') {
        doubleRequestAnimationFrame(callback);
    } else {
        return new Promise<void>((resolve) => {
            doubleRequestAnimationFrame(resolve);
        });
    }
}

export function raf(callback?: () => void): Promise<void> | void {
    if (typeof callback === 'function') {
        requestAnimationFrame(callback);
    } else {
        return new Promise<void>((resolve) => {
            requestAnimationFrame(() => {
                resolve();
            });
        });
    }
}

function doubleRequestAnimationFrame(callback: () => void): void {
    window.requestAnimationFrame(() => {
        window.requestAnimationFrame(callback);
    });
}
