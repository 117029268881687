import { WritableRef } from '../types/vue';

export async function wrapPending<T>(promise: Promise<T>, pending: WritableRef<boolean>): Promise<T> {
    pending.value = true;

    try {
        return await promise;
    } finally {
        pending.value = false;
    }
}
