import { Id } from 'dtg-shared/types/data';

import { ApiModule } from '../../classes/ApiModule';

import { API_DTG_ROUTE, buildApiDuplicateRoute } from './routes';
import {
    ApiDtgCreateRequest,
    ApiDtgCreateResponse,
    ApiDtgDuplicateResponse,
    ApiDtgInfoResponse,
    ApiDtgListRequest,
    ApiDtgListResponse,
    apiDtgTransformShape,
    ApiDtgUpdateRequest,
    ApiDtgUpdateResponse,
} from './types';

export class ApiDtgModule extends ApiModule {
    async create({ requestData }: { requestData: ApiDtgCreateRequest }): Promise<ApiDtgCreateResponse> {
        return this.api.put(API_DTG_ROUTE, requestData, { raw: true });
    }

    async update({
        id,
        requestData,
    }: Id & {
        requestData: ApiDtgUpdateRequest;
    }): Promise<ApiDtgUpdateResponse> {
        return this.api.post(`${API_DTG_ROUTE}/${id}`, requestData, { raw: true });
    }

    async fetchList({
        includeArchived = false,
    }: { includeArchived?: boolean } = {}): Promise<ApiDtgListResponse> {
        const params: ApiDtgListRequest = {};

        if (!includeArchived) {
            params.archived = false;
        }

        return this.api.get(API_DTG_ROUTE, {
            params,
            transformShape: { response: { items: [apiDtgTransformShape] } },
        });
    }

    async fetchDetails({ id }: Id): Promise<ApiDtgInfoResponse> {
        return this.api.get(`${API_DTG_ROUTE}/${id}`, {
            transformShape: { response: apiDtgTransformShape },
        });
    }

    async duplicate({ id }: Id): Promise<ApiDtgDuplicateResponse> {
        const route = buildApiDuplicateRoute(id);

        return this.api.post(route, {}, { transformShape: { response: apiDtgTransformShape } });
    }
}
