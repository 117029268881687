import { object, ObjectSchema } from 'yup';
import { AnyObject, ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { Maybe } from 'yup/lib/types';

import { KeyOf, NoExcessProperties, RecordUnknown } from '../types/utils';
import { arrayify } from '../utils/array';
import { objEntries, objFromEntries } from '../utils/object';

import {
    ObjectSchemaOf,
    ValidationConditionsOf,
    ValidationSchema,
    ValidationShape,
    ValidationShapeOf,
} from './types';

/**
 * Define schema for an object.
 *
 * @example
 * // use when defining schemas in the body of a module
 * function exampleSchema(): ValidationSchema<ExampleType> {
 *     return defineSchema({
 *          someKey: string().defined()
 *     });
 * }
 *
 * @example
 * // use in components and composables
 * const schema: ValidationSchema<ExampleType> = defineSchema({
 *     someKey: string().defined()
 * })
 */
export function defineSchema<T extends ValidationShape>(
    schema: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): OptionalObjectSchema<T, Record<string, any>, TypeOfShape<T>> {
    return object(schema).strict();
}

/**
 * Define schema for a record type.
 *
 * Note that this function is curried:
 * The initial function takes just the generic record type,
 * then the returned function takes the schema.
 *
 * Yup only handles `schema shape -> type` conversions with TypeOfShape type.
 * We want to convert the other way around. To do this we use an assertion.
 *
 * @example
 * defineSchemaOf<ExampleType>()({
 *     someKey: string().defined()
 * });
 */
export function defineSchemaOf<T extends RecordUnknown>(
    keysToExclude: Array<[KeyOf<T>, KeyOf<T>]> = [],
): <S extends ValidationShapeOf<T>>(schema: NoExcessProperties<S, T>) => ValidationSchema<T> {
    return (schema): ValidationSchema<T> =>
        object().shape(schema, keysToExclude).strict() as unknown as ValidationSchema<T>;
}

/**
 * Define schema with conditions for a record type.
 *
 * Note that this function is curried:
 * The initial function takes just the generic record type,
 * then the returned function takes the schema and conditions.
 *
 * @example
 * const when = makeConditionsOf<ExampleType>();
 *
 * const schemaWithConditions = defineSchemaWithConditions<ExampleType>()(
 *    {
 *       someKey: string().defined(),
 *       anotherKey: string().nullable().defined(),
 *    },
 *    {
 *        someKey: when({
 *            key: 'anotherKey',
 *            is: (value) => value !== null,
 *            then: string().required(),
 *        })
 *    }
 * );
 *
 * schemaWithConditions.conditions.someKey.check(2);
 */
export function defineSchemaWithConditions<T extends RecordUnknown>(): <
    S extends ValidationShapeOf<T>,
    V extends ValidationConditionsOf<T>,
>(
    schema: NoExcessProperties<S, T>,
    conditions: NoExcessProperties<V, T>,
    // see https://github.com/jquense/yup/blob/master@%7B2017-01-20%7D/test/object.js#L609
    keysToExclude?: Array<[KeyOf<T>, KeyOf<T>]>,
) => {
    schema: ValidationSchema<T>;
    conditions: typeof conditions;
} {
    return (schema, conditions, keysToExclude) => ({
        schema: defineSchemaOf<T>(keysToExclude)(withConditions(schema, conditions)),
        conditions,
    });
}

/**
 * Applies conditions to a schema shape, returns a new shape.
 *
 * Should be used via {@link defineSchemaWithConditions}
 */
export function withConditions<T extends RecordUnknown, S extends ValidationShapeOf<T>>(
    schema: S,
    conditions: Partial<ValidationConditionsOf<T>>,
): NoExcessProperties<S, T> {
    return objFromEntries(
        objEntries(schema).map(([key, schema]) => {
            const condition = arrayify(conditions[key as keyof T]);
            const extendedSchema = condition.reduce(
                (schema, condition) => schema.when(...condition.apply()),
                schema,
            );

            return [key, extendedSchema];
        }),
    ) as unknown as NoExcessProperties<S, T>;
}

/**
 * Use instead of yup.object({}) to trick typescript into accepting your schema
 * without doing deep validation at runtime.
 *
 * @example
 * defineSchema({
 *      field: objectSchema<SomeObject>().required(),
 * })
 *
 * Can also be used to convert a ValidationSchema for usage in another schema.
 *
 * @example
 * declare function getSomeObjectSchema(): ValidationSchema<SomeObject>
 *
 * defineSchema({
 *      field: objectSchema(getSomeObjectSchema()).required(),
 * })
 */
export function objectSchema<T extends RecordUnknown>(
    schema?: ValidationSchema<T>,
): ObjectSchemaOf<Required<T>> {
    return (schema ?? object({})).defined() as ObjectSchemaOf<Required<T>>;
}

/**
 * Fix for yup.object({}).nullable().required() producing T | null.
 *
 * @example
 * objectRequired(objectSchema<T>().nullable())
 */
export function objectRequired<
    TShape extends ObjectShape,
    TContext extends AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>>,
>(schema: ObjectSchema<TShape, TContext, TIn>): ObjectSchema<TShape, TContext, NonNullable<TIn>> {
    return schema.required() as ObjectSchema<TShape, TContext, NonNullable<TIn>>;
}
