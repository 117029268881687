
import { defineComponent } from 'vue';

import { DtgIcon, getName, useBem } from 'dtg-components';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgIcon,
    },

    setup() {
        return {
            b: useBem(styles),
        };
    },
});
