import {
    ApiAuthLoginErrorCode,
    ApiAuthPasswordActionErrorCode,
    ApiAuthRestorePasswordErrorCode,
} from './types';

export const AUTH_LOGIN_ERROR_MESSAGES: Record<ApiAuthLoginErrorCode, string> = {
    [ApiAuthLoginErrorCode.invalidCredentials]: 'Email et/ou mot de passe incorrect',
    [ApiAuthLoginErrorCode.userNotFound]: 'Utilisateur introuvable',
    [ApiAuthLoginErrorCode.subscriptionNotFound]: 'Aucun abonnement actif',
    [ApiAuthLoginErrorCode.subscriptionNotActive]: 'Aucun abonnement actif',
    [ApiAuthLoginErrorCode.userArchived]: 'Utilisateur introuvable',
    [ApiAuthLoginErrorCode.credentialsUnsafe]: 'Le mot de passe est faible',
};

export const AUTH_PASSWORD_ACTION_MESSAGES: Record<ApiAuthPasswordActionErrorCode, string> = {
    [ApiAuthPasswordActionErrorCode.codeNotFound]: 'Lien déjà utilisé ou invalide',
    [ApiAuthPasswordActionErrorCode.subscriptionNotFound]: 'Aucun abonnement actif',
    [ApiAuthPasswordActionErrorCode.subscriptionNotActive]: 'Aucun abonnement actif',
    [ApiAuthPasswordActionErrorCode.userArchived]: 'Utilisateur introuvable',
};

export const AUTH_PASSWORD_RESTORE_ACTION_MESSAGES: Record<ApiAuthRestorePasswordErrorCode, string> = {
    [ApiAuthRestorePasswordErrorCode.userNotFound]: 'Utilisateur introuvable',
    [ApiAuthRestorePasswordErrorCode.subscriptionNotFound]: 'Aucun abonnement actif',
    [ApiAuthRestorePasswordErrorCode.userStatusException]: 'Utilisateur introuvable',
    [ApiAuthRestorePasswordErrorCode.userArchived]: 'Utilisateur introuvable',
};
