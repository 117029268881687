import axios from 'axios';

// Returns a localized error message based on possible axios error codes
// See https://github.com/axios/axios/blob/v0.26.1/lib/adapters/xhr.js
export function localizeErrorMessage(error: Error): string {
    if (!axios.isAxiosError(error)) {
        return error.message;
    }

    switch (error.code) {
        // Request aborted by browser (`Request aborted`, different from axios.isCancel)
        case 'ECONNABORTED':
            return 'Connexion interrompue';

        // Request timed out (`timeout exceeded`, only happens if timeout is set manually on the request)
        case 'ETIMEDOUT':
            return 'La connexion a expiré';

        // Request errored without a response from the server (`Network Error`)
        // eslint-disable-next-line no-undefined
        case undefined:
        case null:
            return 'Erreur réseau';

        default:
            return error.message;
    }
}
