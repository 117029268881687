import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_terms_of_sales = _resolveComponent("dtg-terms-of-sales")!
  const _component_dtg_layout_about = _resolveComponent("dtg-layout-about")!

  return (_openBlock(), _createBlock(_component_dtg_layout_about, null, {
    default: _withCtx(() => [
      _createVNode(_component_dtg_terms_of_sales)
    ]),
    _: 1
  }))
}