import { computed, readonly, unref } from 'vue';
import { useRouter } from 'vue-router';

import { useModalMessage } from 'dtg-components';
import { ReadonlyRef } from 'dtg-shared/types/vue';

import { useAuth } from '@/composables/use-auth';
import { useNativePlugins } from '@/composables/use-native-plugins';
import { useNetworkStatus } from '@/composables/use-network-status';

import { ENV } from '@/utils/env';

import injectAuthTokensTemplate from './inject-auth-tokens.tejs';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useBackofficeLink(link: ReadonlyRef<string | null> | string | null) {
    const { online } = useNetworkStatus();
    const modalMessage = useModalMessage();
    const withBrowsers = useNativePlugins(['InAppBrowser', 'Browser']);
    const { tokens, updateTokens } = useAuth();
    const router = useRouter();

    const href = computed(() => (online.value && ENV.VUE_APP_PLATFORM === 'web' ? unref(link) : null));

    const webViewUrl = computed(() => {
        const unrefedLink = unref(link);

        if (!unrefedLink) {
            return null;
        }

        const url = new URL(unrefedLink);

        // See apps/backoffice/src/composables/use-auth.ts
        url.searchParams.set('openedFrom', ENV.VUE_APP_PLATFORM);

        return url.toString();
    });

    async function onBackofficeLinkClick(): Promise<void> {
        if (!online.value) {
            modalMessage.open({
                title: 'Erreur',
                message:
                    'Cette action nécessite une connexion internet, veuillez vous connecter au réseau avant de vous rendre sur MyDTG Manager.',
            });

            return;
        }

        if (ENV.VUE_APP_PLATFORM === 'web') {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/naming-convention
        await withBrowsers(({ InAppBrowser, Browser }) => {
            if (!webViewUrl.value) {
                return;
            }

            const browser = InAppBrowser.InAppBrowser.create(
                webViewUrl.value,
                '_blank',
                'usewkwebview=yes,beforeload=get,hideurlbar=yes',
            );

            // Handle browser -> mobile app links
            browser.on('beforeload').subscribe(async ({ url }) => {
                const shouldOpenInBrowser = !url.startsWith(ENV.VUE_APP_BACKOFFICE_URL);
                const shouldOpenInApp = url.includes(ENV.VUE_APP_DEEP_LINK_URL);

                if (shouldOpenInApp) {
                    const path = url.replace(ENV.VUE_APP_DEEP_LINK_URL, '');

                    browser.close();

                    await router.push({ path });
                } else if (shouldOpenInBrowser) {
                    await Browser.Browser.open({ url });
                } else {
                    // Load the page as usual
                    // https://github.com/danielsogl/awesome-cordova-plugins/issues/2819#issuecomment-683468671
                    // "load after before load" is definitely in my Top 10 most reasonable function names
                    // eslint-disable-next-line no-underscore-dangle
                    browser._loadAfterBeforeload(url);
                }
            });

            let hasLoaded = false;

            // Share access tokens with the browser
            browser.on('loadstop').subscribe(async () => {
                if (hasLoaded) {
                    return;
                }

                hasLoaded = true;

                const { accessToken, refreshToken } = tokens;

                const accessTokenStr = JSON.stringify(accessToken);
                const refreshTokenStr = JSON.stringify(refreshToken);

                await browser.executeScript({
                    code: injectAuthTokensTemplate({ accessTokenStr, refreshTokenStr }),
                });
            });

            // Update app's tokens when the browser updates its
            browser.on('message').subscribe(async ({ data }) => {
                const { accessToken, refreshToken } = data as { accessToken: string; refreshToken: string };

                await updateTokens(accessToken, refreshToken);
            });
        });
    }

    return readonly({
        href,
        onClick: onBackofficeLinkClick,
    });
}
