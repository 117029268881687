import { ApiResponse, ApiResponseError } from '../../classes/Api';
import { ApiCompanyEngagementType } from '../company';
import { ApiUserRole } from '../user';

export type ApiAuthLoginRequest = {
    email: string;
    password: string;
};

export type ApiAuthLoginResponse = ApiResponse<ApiAuthLoginResult, ApiAuthLoginError>;

export type ApiAuthLoginResult = {
    accessToken: string;
    refreshToken: string;
};

export type ApiAuthParsedAccessToken = {
    // <Registered Claim Names>
    // https://datatracker.ietf.org/doc/html/rfc7519#section-4.1
    aud: string; // Audience
    iat: number; // Issued At
    exp: number; // Expiration Time
    iss: string; // Issuer
    sub: string; // Subject
    // </ Registered Claim Names>

    uid: string;
    claims: {
        roles: ApiUserRole[];
        company: {
            id: string;
            idRepresentative: string;
            name: string;
            engagement: ApiCompanyEngagementType[];
        };
        engagement: ApiCompanyEngagementType;
        status: string;
        idRepresentative: string;
        firstName: string;
        lastName: string;
        email: string;
        subscriptionType: string;
    };
};

export type ApiAuthLoginError = ApiResponseError<ApiAuthLoginErrorCode>;

export enum ApiAuthLoginErrorCode {
    userNotFound = 'auth/user-not-found',
    invalidCredentials = 'auth/credentials-invalid',
    subscriptionNotFound = 'auth/subscription-not-found',
    subscriptionNotActive = 'auth/subscription-not-active',
    userArchived = 'auth/user-archived',
    credentialsUnsafe = 'auth/credentials-unsafe',
}

export type ApiAuthRefreshTokenRequest = {
    refreshToken: string;
};

export type ApiAuthRefreshTokenResponse = ApiResponse<ApiAuthLoginResult>;

export enum ApiAuthAccessErrorCode {
    unauthorized = 'auth/unauthorized',
    tokenInvalid = 'auth/token-invalid',
    forbidden = 'auth/forbidden',
    subscriptionNotFound = 'subscription/not-active',
    subscriptionNotActive = 'subscription/not-found',
    refreshTokenInvalid = 'auth/refresh-token-invalid',
    userArchived = 'auth/current-user-archived',
}

export type ApiAuthSetPasswordRequest = { password: string };
export type ApiAuthSetPasswordResponse = ApiResponse<ApiAuthLoginResult, ApiAuthPasswordActionError>;

export type ApiAuthUpdatePasswordRequest = { password: string; code: string };
export type ApiAuthUpdatePasswordResponse = ApiResponse<ApiAuthLoginResult, ApiAuthPasswordActionError>;

export type ApiAuthRestorePasswordRequest = { email: string };
export type ApiAuthRestorePasswordResponse = ApiResponse<boolean>;

export enum ApiAuthRestorePasswordErrorCode {
    userNotFound = 'restore-link-code/user-not-found',
    subscriptionNotFound = 'restore-link-code/user-subscription-not-active',
    userStatusException = 'restore-link-code/user-status-not-active',
    userArchived = 'auth/user-archived',
}

export type ApiAuthPasswordActionError = ApiResponseError<ApiAuthPasswordActionErrorCode>;

export enum ApiAuthPasswordActionErrorCode {
    codeNotFound = 'user/code-not-found',
    subscriptionNotFound = 'auth/subscription-not-found',
    subscriptionNotActive = 'auth/subscription-not-active',
    userArchived = 'auth/user-archived',
}

export type ApiAuthPasswordCodeInfo = {
    id: string;
    userId: string;
    code: string;
    createdAt: string;
};

export type ApiAuthPasswordCodeInfoRequest = {
    code: string;
};

export enum ApiAuthPasswordCodeInfoErrorCode {
    codeNotFound = 'get-link-code/link-code-not-found',
}

export type ApiAuthPasswordCodeInfoResponse = ApiResponse<
    ApiAuthPasswordCodeInfo,
    ApiResponseError<ApiAuthPasswordCodeInfoErrorCode>
>;

export type ApiAuthVerifyTokenRequest = {
    accessToken: string;
};
