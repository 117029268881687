
import partition from 'lodash/partition';
import { computed, defineComponent, onActivated, onMounted, reactive, watch } from 'vue';

import {
    DtgAction,
    DtgCheckbox,
    DtgCol,
    DtgField,
    DtgGrid,
    DtgIcon,
    DtgIconAction,
    DtgInput,
    DtgInputDate,
    DtgRow,
    DtgRowCol,
    DtgSelect,
    DtgTitle,
    DtgTransitionGroupStack,
    getName,
    useBem,
} from 'dtg-components';
import { DtgData, DtgStatus } from 'dtg-firebase/modules/dtg';
import { useFilters } from 'dtg-shared/composables/use-filters';
import { useSortableList } from 'dtg-shared/composables/use-sortable-list';
import { Id, Option } from 'dtg-shared/types/data';
import { isSame } from 'dtg-shared/utils/date';
import { normalizeString } from 'dtg-shared/utils/string';
import { truthy } from 'dtg-shared/utils/truthy';

import { DtgLayoutWithoutSidebar } from '@/components/layouts/layout-without-sidebar';

import { useAuth } from '@/composables/use-auth';
import { useDtgListData } from '@/composables/use-dtg-list-data';
import { useDtgPrefetch } from '@/composables/use-dtg-prefetch';
import { useNetworkStatus } from '@/composables/use-network-status';

import { useStoreCollection } from '@/store';

import { DtgListItem } from './elems/list-item';
import { DtgListSortControls } from './elems/list-sort-controls';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgListItem,
        DtgGrid,
        DtgRow,
        DtgTitle,
        DtgCol,
        DtgInput,
        DtgInputDate,
        DtgSelect,
        DtgCheckbox,
        DtgField,
        DtgRowCol,
        DtgLayoutWithoutSidebar,
        DtgTransitionGroupStack,
        DtgListSortControls,
        DtgAction,
        DtgIcon,
        DtgIconAction,
    },

    setup() {
        const {
            getters: { getItems: getDtgItems, getDtgStatuses },
        } = useStoreCollection('dtg');

        const { online } = useNetworkStatus();

        const { user } = useAuth();

        const dtgs = computed(() => {
            const sortedDtgs = getDtgItems().filter((dtg) => {
                const { packId, status } = dtg.data;

                return !packId || status !== DtgStatus.planning;
            });

            const [validDtgs, invalidDtgs] = partition(sortedDtgs, ({ valid }) => valid);

            return [...invalidDtgs, ...validDtgs];
        });

        const dtgItems = computed<Array<DtgData & Id>>(() => {
            return dtgs.value.map(({ id, data }) => ({ id, ...data }));
        });

        const dtgStatuses = computed<Array<Option<DtgStatus>>>(() => {
            const availableStatuses = new Set(dtgs.value.map(({ data }) => data.status));

            return getDtgStatuses().filter(({ id }) => availableStatuses.has(id));
        });

        const shownFilters = computed(() => dtgItems.value.length > 0);

        const shownMineFilter = computed(() => {
            const userId = user.value?.uid;

            return (
                userId &&
                dtgItems.value.some((dtg) => dtg.technicianId === userId || dtg.validatorId === userId) &&
                dtgItems.value.some((dtg) => dtg.technicianId !== userId && dtg.validatorId !== userId)
            );
        });

        const { filteredItems, filtersApplied, queries, resetQueries } = useFilters({
            items: dtgItems,
            queries: reactive({
                name: '',
                visitDate: null as string | null,
                endDate: null as string | null,
                status: null as DtgStatus | null,
                mine: false,
            }),
            filters: {
                name: (value, query) => normalizeString(value).includes(normalizeString(query)),
                visitDate: (value, query) => !value || (truthy(query) && isSame(value, query)),
                endDate: (value, query) => !value || (truthy(query) && isSame(value, query)),
                mine: (item) => {
                    if (!user.value || !shownMineFilter.value) {
                        return true;
                    }

                    const { uid } = user.value;

                    return truthy(item.technicianId) && uid === item.technicianId;
                },
            },
        });

        const { list, sortKey, sortDirection, setSortKey } = useSortableList<DtgData>(
            computed(() => ({
                fields: [{ key: 'name' }, { key: 'visitDate' }, { key: 'endDate' }, { key: 'status' }],
                list: filteredItems.value,
                defaultSortKey: 'endDate',
            })),
        );

        const noDtgs = computed(() => filteredItems.value.length === 0);
        const refreshShown = computed(() => dtgItems.value.length === 0);

        const { loading, fetchDtgs } = useDtgListData();
        const { restartPrefetch } = useDtgPrefetch();

        onMounted(() => {
            void fetchDtgsInternal();
        });

        onActivated(() => {
            void fetchDtgsInternal();
        });

        watch(shownFilters, (value) => {
            if (!value) {
                resetQueries();
            }
        });

        async function fetchDtgsInternal(refresh?: boolean): Promise<void> {
            await fetchDtgs(refresh);

            restartPrefetch();
        }

        async function refresh(): Promise<void> {
            if (online.value) {
                await fetchDtgsInternal(true);
            }
        }

        async function onRefresh({ complete }: { complete?: () => Promise<void> } = {}): Promise<void> {
            void complete?.();

            await refresh();

            resetQueries();
        }

        function onResetFiltersClick(): void {
            resetQueries();
        }

        return {
            b: useBem(styles),

            noDtgs,
            refreshShown,
            loading,
            list,
            filtersApplied,
            queries,
            shownMineFilter,
            shownFilters,
            dtgStatuses,
            sortKey,
            sortDirection,

            setSortKey,
            onRefresh,
            onResetFiltersClick,
        };
    },
});
