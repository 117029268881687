import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_dropdown_item = _resolveComponent("dtg-dropdown-item")!
  const _component_dtg_dropdown = _resolveComponent("dtg-dropdown")!

  return (_openBlock(), _createBlock(_component_dtg_dropdown, null, {
    default: _withCtx(() => [
      (_ctx.sharedActionsHidden)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_dtg_dropdown_item, {
              disabled: !_ctx.canDownloadReport,
              icon: "download",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onReportDownloadClick()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Générer et télécharger ")
              ]),
              _: 1
            }, 8, ["disabled"]),
            _createVNode(_component_dtg_dropdown_item, _mergeProps(_ctx.backofficeLinkProps, { icon: "dashboard" }), {
              default: _withCtx(() => [
                _createTextVNode(" Ouvrir dans MyDTG Manager ")
              ]),
              _: 1
            }, 16)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.canRepairValidation)
        ? (_openBlock(), _createBlock(_component_dtg_dropdown_item, {
            key: 1,
            icon: "pageImprovements",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRepairValidationClick()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Réparer la validation ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}