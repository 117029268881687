import { ApiResponse } from '../../classes/Api';
import { ApiModule } from '../../classes/ApiModule';

import { BatiChiffrageApi } from './BatiChiffrageApi';
import { API_BATI_CHIFFRAGE_CONFIG_ROUTE } from './routes';
import { BatiChiffrageConfig } from './types';

export class ApiBatiChiffrageModule extends ApiModule {
    async createInstance(): Promise<BatiChiffrageApi> {
        const config = await this.fetchConfig();

        return new BatiChiffrageApi(config, this.logger);
    }

    async fetchConfig(): Promise<BatiChiffrageConfig> {
        const { result } = await this.throwIfErrors(
            this.api.get<ApiResponse<BatiChiffrageConfig>>(API_BATI_CHIFFRAGE_CONFIG_ROUTE),
        );

        return result;
    }
}
