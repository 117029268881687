/* eslint-disable @typescript-eslint/naming-convention */

import { ENV } from '@/utils/env';

import { KeyValueStorage } from './types';

export * from './types';

export async function createKeyValueStorage(
    databaseName: string,
    tableName: string,
): Promise<KeyValueStorage> {
    const isNative = ['ios', 'android'].includes(ENV.VUE_APP_PLATFORM);

    if (isNative) {
        const { NativeKeyValueStorage } = await import('./NativeKeyValueStorage');

        return new NativeKeyValueStorage(databaseName, tableName);
    }

    const { WebKeyValueStorage } = await import('./WebKeyValueStorage');

    return new WebKeyValueStorage(tableName);
}
