import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_action = _resolveComponent("dtg-action")!

  return (_openBlock(), _createBlock(_component_dtg_action, {
    class: _normalizeClass(_ctx.b({ disabled: _ctx.disabled })),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dtg_icon, {
        class: _normalizeClass(_ctx.b('icon')),
        name: _ctx.iconName
      }, null, 8, ["class", "name"]),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('text'))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ]),
    _: 3
  }, 8, ["class", "disabled"]))
}