import { Capacitor } from '@capacitor/core';

import { MaybePromise } from 'dtg-shared/types/utils';
import { objFromEntries } from 'dtg-shared/utils/object';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

import { plugins } from './plugins';
import { NativePlugin, NativePluginKey, NativePlugins } from './types';

// Checks availability and dynamically imports specified capacitor/cordova plugins
// Wraps provided action in try/catch
// because Capacitor.isPluginAvailable is not enough to ensure that any plugin will function properly
export function useNativePlugins<T extends NativePluginKey>(
    pluginNames: T[],
): (action: (plugins: Pick<NativePlugins, T>) => MaybePromise<void>) => Promise<void> {
    return async function withCapacitorPlugins(action) {
        const unavailablePluginNames = pluginNames.filter((pluginName) => {
            return plugins[pluginName].type === 'capacitor' && !Capacitor.isPluginAvailable(pluginName);
        });

        if (unavailablePluginNames.length > 0) {
            if (ENV.NODE_ENV !== 'production') {
                logError(`Unavailable native plugins: ${unavailablePluginNames.join(', ')}`);
            }

            return;
        }

        const requestedPluginPromises = await Promise.all(
            pluginNames.map(async (pluginName) => {
                const plugin = await plugins[pluginName].plugin();

                return [pluginName, plugin] as [T, NativePlugin<T>];
            }),
        );

        const requestedPlugins = objFromEntries(requestedPluginPromises) as Pick<NativePlugins, T>;

        try {
            await action(requestedPlugins);
        } catch (error) {
            logError(error);
        }
    };
}

export function useNativePlugin<T extends NativePluginKey>(
    pluginName: T,
): (action: (plugin: NativePlugin<T>) => MaybePromise<void>) => Promise<void> {
    const withPlugins = useNativePlugins([pluginName]);

    return async function withNativePlugin(action) {
        await withPlugins(async (plugins) => {
            await action(plugins[pluginName]);
        });
    };
}

export async function importNativePlugin<T extends NativePluginKey>(pluginName: T): Promise<NativePlugin<T>> {
    const withPlugins = useNativePlugins([pluginName]);

    let plugin!: NativePlugin<T>;

    await withPlugins((plugins) => {
        plugin = plugins[pluginName];
    });

    return plugin;
}
