
import { defineComponent, PropType } from 'vue';

import { DtgTitle, getName, useBem } from 'dtg-components';

import { DtgLayoutWithoutSidebar } from '@/components/layouts/layout-without-sidebar';

import { DtgAboutFooter } from './elems/about-footer';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgLayoutWithoutSidebar,
        DtgAboutFooter,
        DtgTitle,
    },

    props: {
        title: {
            type: String as PropType<string | null>,
            default: null,
        },
    },

    setup() {
        return {
            b: useBem(styles),
        };
    },
});
