import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createTextVNode as _createTextVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_spinner = _resolveComponent("dtg-spinner")!
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_col = _resolveComponent("dtg-col")!
  const _component_dtg_mark = _resolveComponent("dtg-mark")!
  const _component_dtg_row = _resolveComponent("dtg-row")!
  const _component_dtg_grid = _resolveComponent("dtg-grid")!
  const _component_dtg_action = _resolveComponent("dtg-action")!
  const _component_dtg_icon_action = _resolveComponent("dtg-icon-action")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.b({ unavailable: _ctx.unavailable }))
  }, [
    _createVNode(_component_dtg_action, {
      class: _normalizeClass(_ctx.b('card')),
      href: _ctx.dtgDashboardUrl,
      title: `Ouvrir ${_ctx.data.name}`,
      routed: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_dtg_grid, null, {
          default: _withCtx(() => [
            _createVNode(_component_dtg_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_dtg_col, {
                  class: _normalizeClass(_ctx.b('col')),
                  "size-xs": "12",
                  "size-lg": ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.fetching)
                      ? (_openBlock(), _createBlock(_component_dtg_spinner, {
                          key: 0,
                          class: _normalizeClass(_ctx.b('spinner')),
                          title: _ctx.iconTitle
                        }, null, 8, ["class", "title"]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass([_ctx.b('icon'), _ctx.b('indicator-wrapper')]),
                          title: _ctx.iconTitle
                        }, [
                          _createVNode(_component_dtg_icon, {
                            name: "folder",
                            class: _normalizeClass(_ctx.b('indicator-icon'))
                          }, null, 8, ["class"]),
                          (_ctx.iconName)
                            ? (_openBlock(), _createBlock(_component_dtg_icon, {
                                key: 0,
                                class: _normalizeClass(_ctx.b('indicator')),
                                name: _ctx.iconName
                              }, null, 8, ["class", "name"]))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_1)),
                    _createElementVNode("span", {
                      class: _normalizeClass(_ctx.b('text'))
                    }, _toDisplayString(_ctx.data.name), 3)
                  ]),
                  _: 1
                }, 8, ["class"]),
                _createVNode(_component_dtg_col, {
                  class: _normalizeClass(_ctx.b('col', { visitDate: true, empty: !_ctx.data.visitDate })),
                  "size-xs": "6",
                  "size-sm": _ctx.data.endDate ? 'auto' : '',
                  "size-lg": ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.data.visitDate)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_dtg_icon, {
                            class: _normalizeClass(_ctx.b('icon')),
                            name: "calendar"
                          }, null, 8, ["class"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.data.visitDate)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class", "size-sm"]),
                _createVNode(_component_dtg_col, {
                  class: _normalizeClass(_ctx.b('col', { endDate: true, empty: !_ctx.data.endDate })),
                  "size-xs": "6",
                  "size-sm": ""
                }, {
                  default: _withCtx(() => [
                    (_ctx.data.endDate)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_dtg_icon, {
                            class: _normalizeClass(_ctx.b('icon')),
                            name: "stopwatch"
                          }, null, 8, ["class"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.data.endDate)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class"]),
                (!_ctx.data.visitDate && !_ctx.data.endDate)
                  ? (_openBlock(), _createBlock(_component_dtg_col, {
                      key: 0,
                      class: _normalizeClass(_ctx.b('col', { datePlaceholder: true }))
                    }, null, 8, ["class"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_dtg_col, {
                  class: _normalizeClass(_ctx.b('col', { status: true })),
                  "size-xs": "12",
                  "size-sm": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_dtg_mark, {
                      class: _normalizeClass([_ctx.b('icon'), _ctx.b('status-mark')]),
                      view: _ctx.validMarkView
                    }, null, 8, ["class", "view"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.statusLabelText) + " ", 1),
                    (_ctx.isCheckVisible)
                      ? (_openBlock(), _createBlock(_component_dtg_icon, {
                          key: 0,
                          class: _normalizeClass(_ctx.b('icon')),
                          name: "checkmark",
                          title: "Valide"
                        }, null, 8, ["class"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class", "href", "title"]),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('actions'))
    }, [
      (_ctx.sharedActionsVisible)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_dtg_icon_action, {
              class: _normalizeClass(_ctx.b('action')),
              disabled: _ctx.trameReport.disabled,
              name: "download",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trameReport.download()))
            }, null, 8, ["class", "disabled"]),
            _createVNode(_component_dtg_icon_action, _mergeProps({
              class: _ctx.b('action'),
              name: "dashboard"
            }, _ctx.backofficeLinkProps), null, 16, ["class"])
          ], 64))
        : _createCommentVNode("", true),
      (!_ctx.sharedActionsVisible || _ctx.canRepairValidation)
        ? (_openBlock(), _createBlock(_component_dtg_icon_action, {
            key: 1,
            class: _normalizeClass(_ctx.b('action', { dropdown: true, open: _ctx.isDropdownOpen })),
            name: "kebab",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDropdownClick($event)))
          }, null, 8, ["class"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}