import { objPick } from 'dtg-shared/utils/object';

import { UnvisitedRoomData, UnvisitedRoomFormData, unvisitedRoomFormDataKeys } from './types';

export function buildUnvisitedRoomData({
    localisation = '',
    motif = '',
    nature = '',
    visibleInReport = true,
    createdTimestamp = Date.now(),
    valid = false,
}: Partial<UnvisitedRoomData> = {}): UnvisitedRoomData {
    return {
        localisation,
        motif,
        nature,
        visibleInReport,
        createdTimestamp,
        valid,
    };
}

export function buildUnvisitedRoomFormData(): UnvisitedRoomFormData {
    return objPick<UnvisitedRoomData, keyof UnvisitedRoomFormData>(
        buildUnvisitedRoomData(),
        unvisitedRoomFormDataKeys,
    );
}
