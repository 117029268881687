// https://firebase.google.com/docs/storage/web/handle-errors

export enum StorageErrorCode {
    unknown = 'storage/unknown',
    objectNotFound = 'storage/object-not-found',
    bucketNotFound = 'storage/bucket-not-found',
    projectNotFound = 'storage/project-not-found',
    quotaExceeded = 'storage/quota-exceeded',
    unauthenticated = 'storage/unauthenticated',
    unauthorized = 'storage/unauthorized',
    retryLimitExceeded = 'storage/retry-limit-exceeded',
    invalidChecksum = 'storage/invalid-checksum',
    canceled = 'storage/canceled',
    invalidEventName = 'storage/invalid-event-name',
    invalidUrl = 'storage/invalid-url',
    invalidArgument = 'storage/invalid-argument',
    noDefaultBucket = 'storage/no-default-bucket',
    cannotSliceBlob = 'storage/cannot-slice-blob',
    serverFileWrongSize = 'storage/server-file-wrong-size',
}
