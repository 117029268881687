import { watch } from 'vue';

import { useAuth } from '@/composables/use-auth';
import { useNetworkStatus } from '@/composables/use-network-status';

import { getStorage } from '@/services/firebase';

import { logError } from '@/utils/log-error';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitResumeUploads() {
    const { isLoggedIn } = useAuth();
    const firebaseStorage = getStorage();
    const { online } = useNetworkStatus();

    function initResumeUploads(): void {
        watch(
            [online, isLoggedIn],
            ([online, isLoggedIn]) => {
                if (online && isLoggedIn) {
                    firebaseStorage.resumeUploads().catch(logError);
                } else if (!isLoggedIn) {
                    firebaseStorage.pauseUploads();
                }
            },
            { immediate: true },
        );
    }

    return {
        initResumeUploads,
    };
}
