import { buildPath, CollectionType, pathFromType } from '../firestore';
import { buildUserDataCollectionPath } from '../user-data';

import { CustomElementData } from './types';

export function buildCustomElementCollectionPath({
    userId,
    docId = null,
}: {
    userId: string;
    docId?: string | null;
}): string {
    const userDataPath = buildUserDataCollectionPath({ userId });

    return buildPath(userDataPath, pathFromType(CollectionType.customElement), docId);
}

export function buildCustomElementData({
    value = '',
    families = [],
}: Partial<CustomElementData> = {}): CustomElementData {
    return {
        value,
        families,
    };
}
