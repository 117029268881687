import { PdfOrientation } from 'dtg-shared-report/types';

import { BuildingPlanData, BuildingPlanForm } from './types';

export function buildBuildingPlanData({
    createdTimestamp = Date.now(),
    name = '',
    buildingId = '',
    file = null,
    valid = true,
    visibleInReport = true,
    orientation = PdfOrientation.portrait,
}: Partial<BuildingPlanData> = {}): BuildingPlanData {
    return {
        createdTimestamp,
        name,
        buildingId,
        file,
        valid,
        visibleInReport,
        orientation,
    };
}

export function buildBuildingPlanForm({
    file = null,
    name = '',
    visibleInReport = true,
    orientation = PdfOrientation.portrait,
}: Partial<BuildingPlanForm> = {}): BuildingPlanForm {
    return { file, name, visibleInReport, orientation };
}
