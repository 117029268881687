
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import {
    DtgAction,
    DtgCol,
    DtgGrid,
    DtgIcon,
    DtgIconAction,
    DtgIconName,
    DtgMark,
    DtgMarkView,
    DtgRow,
    DtgSpinner,
    getName,
    positionPopover,
    useBem,
    useBp,
} from 'dtg-components';
import { DtgStatus } from 'dtg-firebase/modules/dtg';
import { formatDate } from 'dtg-shared/utils/date';
import { toReadonlyRefs } from 'dtg-shared/utils/refs';
import { PdfType } from 'dtg-shared-report';

import { useDtgListItemSharedActions } from '@/composables/use-dtg-list-item-shared-actions';
import { useNetworkStatus } from '@/composables/use-network-status';

import { useStoreCollection, useStoreModule } from '@/store';

import { Route } from '@/router';

import { useListItemDropdown } from '../list-item-dropdown';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgGrid,
        DtgRow,
        DtgCol,
        DtgAction,
        DtgIcon,
        DtgMark,
        DtgIconAction,
        DtgSpinner,
    },

    props: {
        dtgId: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const { dtgId } = toReadonlyRefs(props);

        const bp = useBp();

        const {
            getters: { getItemThrows, getDtgStatusLabel, getIsAvailableOffline, getIsFetching },
        } = useStoreCollection('dtg');

        const { getters: rules } = useStoreModule('rules');

        const { offline } = useNetworkStatus();
        const router = useRouter();
        const dropdown = useListItemDropdown();

        const { backofficeLinkProps, reportDownload, repairValidation } = useDtgListItemSharedActions({
            dtgId,
        });

        const dtg = computed(() => getItemThrows(dtgId.value));
        const data = computed(() => dtg.value.data);

        const availableOffline = computed(() => getIsAvailableOffline(dtgId.value));
        const fetching = computed(() => getIsFetching(dtgId.value));
        const loaded = computed(() => dtg.value.loaded);

        const canRepairValidation = computed(() => rules.canRepairDtgValidation(dtg.value));
        const isCheckVisible = computed(() => dtg.value.valid && data.value.status === DtgStatus.opened);
        const sharedActionsVisible = computed(() => bp.from(bp.lg));

        const dtgDashboardUrl = computed(() => {
            return router.resolve({ name: Route.dashboard, params: { id: dtgId.value } });
        });

        const unavailable = computed(() => !availableOffline.value && offline.value && loaded.value);

        const iconName = computed<DtgIconName | null>(() => {
            switch (true) {
                case availableOffline.value:
                    return 'checkmarkCircle';

                case offline.value && loaded.value:
                    return 'closeCircle';

                default:
                    return null;
            }
        });

        const iconTitle = computed<string | null>(() => {
            switch (true) {
                case fetching.value:
                    return 'Téléchargement en cours';

                case availableOffline.value:
                    return 'Disponible hors connexion';

                case offline.value && loaded.value:
                    return 'Non disponible hors connexion';

                default:
                    return null;
            }
        });

        const validMarkView = computed(() => {
            const dtgStatusMarkViewMap: Record<DtgStatus, DtgMarkView> = {
                [DtgStatus.closed]: DtgMarkView.disabled,
                [DtgStatus.ready]: DtgMarkView.success,
                [DtgStatus.opened]: DtgMarkView.warning,
                [DtgStatus.planning]: DtgMarkView.disabled,
                [DtgStatus.onValidation]: DtgMarkView.notice,
            };

            return dtgStatusMarkViewMap[data.value.status];
        });

        const statusLabelText = computed(() => {
            const { status } = data.value;

            return getDtgStatusLabel({ status });
        });

        const trameReport = reportDownload(PdfType.trame);

        function onDropdownClick(event: Event): void {
            dropdown.open(
                {
                    dtgId: dtgId.value,
                    sharedActionsHidden: !sharedActionsVisible.value,
                    backofficeLinkProps,
                    canRepairValidation: canRepairValidation.value,
                    onRepairValidation: repairValidation,
                    canDownloadReport: !trameReport.disabled,
                    onDownloadReport: trameReport.download,
                },
                positionPopover(event, { position: { horizontal: 'right' } }),
            );
        }

        return {
            b: useBem(styles),

            data,
            dtgDashboardUrl,
            validMarkView,
            statusLabelText,
            isCheckVisible,
            isDropdownOpen: dropdown.isOpen,
            backofficeLinkProps,
            sharedActionsVisible,
            canRepairValidation,
            fetching,
            iconName,
            iconTitle,
            unavailable,
            trameReport,

            formatDate,
            onDropdownClick,
        };
    },
});
