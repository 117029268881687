import { ParcelData, ParcelFormData } from './types';

export function buildParcelFormData({
    number = '',
    prefix = '',
    section = '',
}: Partial<ParcelFormData> = {}): ParcelFormData {
    return {
        number,
        prefix,
        section,
    };
}

export function buildParcelItemData({
    createdTimestamp = Date.now(),
    valid = false,
    meta = null,
    ...rest
}: Partial<ParcelData> = {}): ParcelData {
    return {
        ...buildParcelFormData(rest),
        meta,

        createdTimestamp,
        valid,
    };
}
