import { arrayIncludes } from 'dtg-shared/utils/array';

import { PdfQueryParams, PdfType } from '../types';

export function buildParams(query: Record<string, string>, isProd: boolean): PdfQueryParams {
    const dtgId = getDtgId(query.dtgId);
    const type = getPdfType(query.type, isProd);

    return {
        dtgId,
        type,
    };
}

function getDtgId(id?: string): string {
    if (!id) {
        throw new Error('dtgId needs to be provided');
    }

    return id;
}

function getPdfType(rawType: string | undefined, isProd: boolean): PdfType {
    let allowedTypes = Object.values(PdfType);

    if (isProd) {
        allowedTypes = allowedTypes.filter((type) => !arrayIncludes([PdfType.test], type));
    }

    if (arrayIncludes(allowedTypes, rawType)) {
        return rawType;
    }

    if (rawType) {
        throw new Error(`Unknown report type "${rawType}"`);
    }

    return PdfType.trame;
}
