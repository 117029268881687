import { objCreate, objEntries, objFromEntries } from '../object';

import { EnvReferenceLoopError, EnvValidationError } from './errors';
import { EnvGetters } from './types';

export function buildEnv<
    T extends Record<string, unknown>,
    U extends Record<string, string | undefined> = Record<string, string | undefined>,
>(env: U, getters: EnvGetters<T>): T {
    const envReference = objCreate<T>();
    const envFinal = objCreate<T>();
    const referencedKeys: Array<keyof T & string> = [];

    // Build an object with getters to be able to cross-reference env variables
    // Cache the values in the final object to avoid re-running the same getters multiple times
    // Check that infinite loops are not created
    const getterEntries = objEntries(getters).map(([key, getter]) => {
        const definition = {
            get: (): T[typeof key] => {
                if (referencedKeys.includes(key)) {
                    throw new EnvReferenceLoopError([...referencedKeys, key]);
                }

                if (key in envFinal) {
                    return envFinal[key];
                }

                const value = executeGetter(key, getter);

                envFinal[key] = value;

                return value;
            },
        };

        return [key, definition] as const;
    });

    Object.defineProperties(envReference, objFromEntries(getterEntries));

    // Complete the final static config
    objEntries(getters).forEach(([key, getter]) => {
        if (!(key in envFinal)) {
            envFinal[key] = executeGetter(key, getter);
        }
    });

    function executeGetter<K extends keyof T & string>(key: K, getter: EnvGetters<T>[K]): T[K] {
        const value = env[key] ?? '';

        try {
            referencedKeys.push(key);

            const finalValue = getter(value, envReference);

            referencedKeys.splice(referencedKeys.indexOf(key), 1);

            return finalValue;
        } catch (error) {
            if (error instanceof EnvReferenceLoopError || error instanceof EnvValidationError) {
                throw error;
            } else {
                throw new EnvValidationError(key, value, (error as Error).message, referencedKeys);
            }
        }
    }

    return envFinal;
}
