import { AnyMaskedOptions } from 'imask';

export const prefixMask = '***';

export const sectionMask: AnyMaskedOptions = {
    mask: '**',
    prepare: (str: string) => str.toUpperCase(),
};

export const numberMask = '****';
