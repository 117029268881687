import { ref } from 'vue';

import { Api } from 'dtg-api/classes/Api';
import { TokenManager } from 'dtg-api/classes/TokenManager';
import { TokenCookieStorage, TokenLocalStorage } from 'dtg-api/classes/TokenStorage';
import { ApiAuthModule, ApiAuthParsedAccessToken } from 'dtg-api/modules/auth';
import { ApiBatiChiffrageModule } from 'dtg-api/modules/bati-chiffrage';
import { ApiDtgModule } from 'dtg-api/modules/dtg';
import { ApiFileModule } from 'dtg-api/modules/file';
import { ApiGeocoderModule } from 'dtg-api/modules/geocoder';
import { ApiParcelModule } from 'dtg-api/modules/parcel';
import { getGmapsPromise } from 'dtg-components';
import { createService } from 'dtg-shared/utils/create-service';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

export type ApiInstance = ReturnType<typeof getApi>;
export type ApiModules = ApiInstance['modules'];
export type TokenManagerInstance = ReturnType<typeof getTokens>;
export type ApiService = ReturnType<typeof initApi>;

const apiService = createService('api', () => {
    // eslint-disable-next-line no-undefined
    const domain = ENV.NODE_ENV === 'production' ? ENV.VUE_APP_COOKIES_DOMAIN : undefined;
    const tokenStorage =
        ENV.VUE_APP_PLATFORM === 'web' ? new TokenCookieStorage(domain) : new TokenLocalStorage();

    const tokens = new TokenManager(tokenStorage, ref<ApiAuthParsedAccessToken | null>(null), {
        access: ENV.VUE_APP_TOKEN_MANAGER_ACCESS_KEY,
        refresh: ENV.VUE_APP_TOKEN_MANAGER_REFRESH_KEY,
    });

    const modules = {
        auth: new ApiAuthModule(tokens),
        dtg: new ApiDtgModule(),
        batiChiffrage: new ApiBatiChiffrageModule(),
        file: new ApiFileModule(),
        parcel: new ApiParcelModule(getGmapsPromise()),
        geocoder: new ApiGeocoderModule(),
    };

    const api = new Api(
        { modules, logger: logError },
        {
            baseURL: ENV.VUE_APP_API_BASE_URL,
        },
    );

    return { api, tokens };
});

const { init: initApi, createGetter } = apiService;

export { apiService, initApi };

export const getApi = createGetter(({ api }) => api);
export const getTokens = createGetter(({ tokens }) => tokens);

export function getApiModule<K extends keyof ApiModules>(key: K): ApiModules[K] {
    return getApi().modules[key];
}
