import { Context } from 'vuex-smart-module';

import { ContextSafe, ModulesContextSafe } from 'dtg-shared/store/types';

import { CollectionsModule, FileModule, RootModule } from './create';
import { getStore } from './service';

export type ModuleName = keyof Context<RootModule>['modules'];
export type CollectionName = keyof Context<CollectionsModule>['modules'];
export type FileModuleName = keyof Context<FileModule>['modules'];

export function useStore(): ContextSafe<RootModule> {
    const { root, store } = getStore();

    return root.context(store);
}

export function useStoreModule<T extends ModuleName>(name: T): ModulesContextSafe<RootModule>[T] {
    return useStore().modules[name];
}

export function useStoreCollection<T extends CollectionName>(
    name: T,
): ModulesContextSafe<CollectionsModule>[T] {
    return useStoreModule('collections').modules[name];
}

export function useStoreFileModule<T extends FileModuleName>(name: T): ModulesContextSafe<FileModule>[T] {
    return useStoreModule('file').modules[name];
}
