import isEqual from 'lodash/isEqual';
import { computed, reactive, ref, watch } from 'vue';

import { ReadonlyMaybeRef, ReadonlyRef, WritableRef } from '../../types/vue';
import { objFromEntries } from '../../utils/object';
import { ValidationErrors, ValidationSchema, ValidationSchemaWithConditions } from '../../validation';

import { FieldDisabled, FormComposable, FormField } from './types';
import { useFormShared } from './use-form-shared';

export function useFormFields<T extends Record<string, unknown>, U extends T = T>({
    data,
    schema,
    fieldErrors = ref({}),
    disabled,
    fieldsDisabled,
}: {
    data: WritableRef<T>;
    schema: ReadonlyMaybeRef<ValidationSchema<U> | ValidationSchemaWithConditions<U>>;
    fieldErrors?: ReadonlyRef<ValidationErrors<T>>;
    disabled?: FieldDisabled<T>;
    fieldsDisabled?: FieldDisabled<T>;
}): FormComposable<T> {
    const { formUuid, form, fields, fieldsArray, focusFirstInvalid, setErrors, setTouched, validate } =
        useFormShared({
            fieldErrors,
            validationSchema: schema,
            disabled,
            fieldsDisabled,
            initialData: data.value,
        });

    const valid = computed(() => form.meta.value.valid);

    const formValue = computed(() => {
        return objFromEntries(fieldsArray.map(([key, field]) => [key, field.value.value])) as T;
    });

    watch(
        formValue,
        (value) => {
            // It's important to call disabled without key here,
            // since this is only meant to prevent updates when the entire form is disabled
            const isDisabled = typeof disabled === 'function' ? disabled() : disabled?.value ?? false;

            if (!isDisabled) {
                data.value = {
                    ...data.value,
                    ...value,
                };
            }
        },
        { flush: 'post' },
    );

    fieldsArray.forEach(([key, field]) => {
        watch(
            () => data.value[key],
            (value) => {
                updateFieldValue(field, key, value);
            },
        );
    });

    function updateFieldValue(field: FormField, key: keyof T, value: unknown): void {
        if (!isEqual(value, field.value.value)) {
            field.updateValue(value);
        }
    }

    return {
        fields: reactive(fields),

        valid,
        fieldErrors,
        meta: form.meta,

        validate,
        setTouched,
        setErrors,
        focusFirstInvalid,
        formUuid,
    };
}
