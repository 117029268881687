import { PdfType } from 'dtg-shared-report';

import { ENV } from './env';

const BACKOFFICE_APP_ORIGIN = ENV.VUE_APP_BACKOFFICE_URL;

export const BACKOFFICE_URL = BACKOFFICE_APP_ORIGIN;
export const PROFILE_URL = `${BACKOFFICE_APP_ORIGIN}/user/me`;

export function buildBackofficeDtgRoute(dtgId: string): string {
    return `${BACKOFFICE_APP_ORIGIN}/dtg/${dtgId}/update`;
}

export function buildBackofficeDtgReportRoute({
    dtgId,
    dtgName,
    type,
    token,
}: {
    dtgId: string;
    dtgName: string;
    type: PdfType;
    token: string;
}): string {
    const search = new URLSearchParams({
        name: dtgName,
        token,
        type,
        dtgId,
    });

    return `${BACKOFFICE_APP_ORIGIN}/report-download?${search.toString()}`;
}
