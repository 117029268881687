import { computed, readonly, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useBp } from 'dtg-components';

import { DtgSidebarView } from '../types';

const isSidebarOpen = ref(false);
const scrollPosition = ref({ top: 0, left: 0 });

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSidebar() {
    const bp = useBp();
    const router = useRouter();

    const isSidebarBottom = computed(() => bp.till(bp.md));

    const sidebarPosition = computed<DtgSidebarView>(() => {
        return isSidebarBottom.value ? DtgSidebarView.bottom : DtgSidebarView.side;
    });

    function initSidebar(): void {
        router.afterEach(() => {
            toggleSidebar(false);
        });
    }

    function toggleSidebar(value?: boolean): void {
        if (typeof value === 'undefined') {
            isSidebarOpen.value = !isSidebarOpen.value;
        } else {
            isSidebarOpen.value = value;
        }
    }

    return {
        isSidebarOpen: readonly(isSidebarOpen),
        isSidebarBottom,
        sidebarPosition,
        scrollPosition,

        toggleSidebar,
        initSidebar,
    };
}
