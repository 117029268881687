import { objPick } from 'dtg-shared/utils/object';

import { buildDtgSubCollectionPath, DtgId } from '../dtg';
import { buildPath, CollectionType, pathFromType } from '../firestore';
import { FamilyListGroupItem } from '../lists';

import {
    ControlPointCalcFormData,
    controlPointCalcFormDataKeys,
    ControlPointData,
    ControlPointFormData,
    controlPointFormDataKeys,
    CurativeItem,
    CurativesRecord,
    CurativeValue,
} from './types';

export function buildControlPointCollectionPath({
    dtgId,
    buildingId,
    docId = null,
}: DtgId & { buildingId: string; docId?: string | null }): string {
    const buildingPath = buildDtgSubCollectionPath({
        dtgId,
        type: CollectionType.building,
        docId: buildingId,
    });

    return buildPath(buildingPath, pathFromType(CollectionType.controlPoint), docId);
}

export function buildControlPointData({
    name = '',
    index = 0,
    curative = null,
    family = '',
    macroFamilyId = null,
    element = '',
    material = [],
    notice = [],
    comment = '',
    localisation = '',
    state = [],
    planning = null,
    currency = '€',
    workNature = [],
    costs = null,
    createdTimestamp = Date.now(),
    commentResolved = false,
    qty = null,
    totalCost = null,
    totalCostAdjusted = null,
    totalCostBatiChiffrage = null,
    costKey = 'totalCost',
    batiChiffrageQuote = null,
    valid = false,
    validPartially = { controlPoint: false, calculations: false },
}: Partial<ControlPointData> = {}): ControlPointData {
    return {
        curative,
        family,
        macroFamilyId,
        element,
        material,
        notice,
        comment,
        localisation,
        state,
        planning,
        currency,
        workNature,
        costs,
        commentResolved,
        qty,
        totalCost,
        costKey,
        totalCostAdjusted,
        totalCostBatiChiffrage,
        batiChiffrageQuote,

        name,
        index,

        createdTimestamp,
        valid,
        validPartially,
    };
}

export function buildControlPointFormData(): ControlPointFormData {
    return objPick(buildControlPointData(), controlPointFormDataKeys);
}

export function buildControlPointCalcFormData(): ControlPointCalcFormData {
    return objPick(buildControlPointData(), controlPointCalcFormDataKeys);
}

export const curativeValues = [1, 2, 3, 4, 5] as const;

export function buildCurativeItems(): CurativeItem[] {
    return [
        {
            value: 1,
            title: 'Curatif 1',
            titleShort: 'NIV 1',
            text: 'Curatif Niveau 1',
            hasCalculations: true,
            isSevere: true,
        },
        {
            value: 2,
            title: 'Curatif 2',
            titleShort: 'NIV 2',
            text: 'Curatif Niveau 2',
            hasCalculations: true,
            isSevere: true,
        },
        {
            value: 3,
            title: 'Curatif 3',
            titleShort: 'NIV 3',
            text: 'Curatif Niveau 3',
            hasCalculations: true,
            isSevere: true,
        },
        {
            value: 4,
            title: 'Entretien',
            titleShort: 'Entretien',
            text: 'Entretien',
            hasCalculations: true,
            isSevere: false,
        },
        {
            value: 5,
            title: 'Signalements',
            titleShort: 'Signalements',
            text: 'Signalements',
            hasCalculations: false,
            isSevere: false,
        },
    ];
}

export function buildCurativeRecord<T>(value: T): CurativesRecord<T> {
    return Object.fromEntries(
        curativeValues.map((curativeValue) => [curativeValue, value]),
    ) as CurativesRecord<T>;
}

export function buildMacroFamilyIds({
    data,
    families,
}: {
    data: ControlPointData;
    families: FamilyListGroupItem[];
}): {
    macroFamilyCalculatedId: string | null;
    macroFamilyId: string | null;
    macroFamilySelectedId: string | null;
} {
    const selectedFamily = families.find((family) => family.value === data.family);
    const macroFamilyCalculatedId = selectedFamily?.macroFamilyId ?? null;

    return {
        macroFamilyCalculatedId,
        macroFamilyId: data.macroFamilyId ?? macroFamilyCalculatedId,
        macroFamilySelectedId: data.macroFamilyId,
    };
}

export function buildNatureFieldLabel(curative: CurativeValue | null): string {
    switch (curative) {
        case 4:
            return "Nature de l'entretien";

        case 5:
            return 'Nature du signalement';

        default:
            return 'Nature des travaux';
    }
}
