/* eslint-disable no-nested-ternary */

import { Option } from '../types/data';

import { normalizeString } from './string';

export function compareNumbers(a?: unknown, b?: unknown): number {
    if (typeof a !== 'number' || typeof b !== 'number') {
        return typeof a === 'number' ? 1 : -1;
    }

    return a - b;
}

export function compareStrings(a?: unknown, b?: unknown): number {
    if (typeof a !== 'string' || typeof b !== 'string') {
        return typeof a === 'string' ? 1 : -1;
    }

    const stringA = normalizeString(String(a));
    const stringB = normalizeString(String(b));

    return stringA < stringB ? -1 : stringA > stringB ? 1 : 0;
}

export function compareStringsEmptyLast(a?: unknown, b?: unknown): number {
    // eslint-disable-next-line no-negated-condition
    return a === b ? 0 : !a ? 1 : !b ? -1 : compareStrings(a, b);
}

export function compareOptionsByValue<T extends Option>(a: T, b: T): number {
    return compareStrings(a.value, b.value);
}

export function compareByOrder<T>(a: T, b: T, order: T[]): number {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);

    if (indexA === -1 || indexB === -1) {
        return indexA === -1 ? 1 : -1;
    }

    return indexA - indexB;
}
