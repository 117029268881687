import { boolean, string } from 'yup';

import { defineSchemaOf, ValidationSchema } from 'dtg-shared/validation';

import { UnvisitedRoomFormData } from './types';

export function unvisitedRoomValidationSchema(): ValidationSchema<UnvisitedRoomFormData> {
    return defineSchemaOf<UnvisitedRoomFormData>()({
        localisation: string().label('Localisation').required(),
        nature: string().label('Nature/désignation').required(),
        motif: string().label('Motif').required(),
        visibleInReport: boolean().defined(),
    });
}
