/*
 * This is the same as pdfjs-dist/webpack.js, but uses the "legacy" build.
 * I couldn't figure out how to reconfigure vue cli's webpack configuration
 * to process the non-legacy version properly.
 */

// eslint-disable-next-line no-restricted-imports
import { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf.js';
// @ts-expect-error importing a worker
import PdfjsWorker from 'worker-loader?esModule=false&filename=[name].[contenthash].js!pdfjs-dist/legacy/build/pdf.worker.js';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
GlobalWorkerOptions.workerPort = new PdfjsWorker();

// eslint-disable-next-line no-restricted-imports
export * from 'pdfjs-dist/legacy/build/pdf.js';
