/* eslint-disable @typescript-eslint/no-explicit-any */

export type Deferred<T> = {
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
    promise: Promise<T>;
};

// This should only be used for cases where we need to resolve a promise as a side effect of another action
// There should almost never be a reason to use this
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export function createDeferred<T = void>(): Deferred<T> {
    let resolve: (value: T | PromiseLike<T>) => void;
    let reject: (reason?: any) => void;

    const promise = new Promise<T>((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });

    // @ts-expect-error using deferred pattern
    return { resolve, reject, promise };
}
