import { Id } from 'dtg-shared/types/data';
import { noop } from 'dtg-shared/utils/noop';
import { objEntries } from 'dtg-shared/utils/object';

import { ApiResponse } from '../../classes/Api';
import { ApiModule } from '../../classes/ApiModule';

import { API_FILE_DOWNLOAD_URL_ROUTE, API_FILE_ROUTE, API_FILES_ROUTE } from './routes';
import {
    ApiFileCreateRequest,
    ApiFileDeleteMultipleRequest,
    ApiFileDownloadUrlResponse,
    ApiFileFetchMultipleRequest,
    ApiFileFetchMultipleResponse,
    ApiFileUploadProgress,
    ApiFileUploadResponse,
} from './types';

export class ApiFileModule extends ApiModule {
    async upload({
        requestData,
        onUploadProgress = noop,
    }: {
        requestData: ApiFileCreateRequest;
        onUploadProgress?: (progress: ApiFileUploadProgress) => void;
    }): Promise<ApiFileUploadResponse> {
        const { id, data, ...params } = requestData;
        const formData = new FormData();

        objEntries(params).forEach(([key, value]) => {
            formData.append(key, String(value));
        });

        formData.append('data', data);

        return this.api.put<ApiFileUploadResponse, FormData>(`${API_FILE_ROUTE}/${id}`, formData, {
            raw: { request: true },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;

                const progress = total ? loaded / total : 0;

                onUploadProgress({ progress });
            },
        });
    }

    async deleteMultiple({
        requestData,
    }: {
        requestData: ApiFileDeleteMultipleRequest;
    }): Promise<ApiResponse> {
        return this.api.delete<ApiResponse, ApiFileDeleteMultipleRequest>(API_FILES_ROUTE, requestData);
    }

    async fetchDownloadUrl({ id }: Id): Promise<ApiFileDownloadUrlResponse> {
        return this.api.get(`${API_FILE_DOWNLOAD_URL_ROUTE}/${id}`);
    }

    async fetchMultiple({
        requestData,
    }: {
        requestData: ApiFileFetchMultipleRequest;
    }): Promise<ApiFileFetchMultipleResponse> {
        return this.api.get<ApiFileFetchMultipleResponse>(API_FILES_ROUTE, { params: requestData });
    }
}
