export enum Route {
    authLogin = 'authLogin',
    authPasswordReset = 'authPasswordReset',
    authPasswordChoose = 'authPasswordChoose',
    list = 'list',
    dashboard = 'dashboard',
    information = 'information',
    buildingComplex = 'buildingComplex',
    buildingComplexList = 'buildingComplexList',
    energyBalance = 'energyBalance',
    location = 'location',
    documents = 'documents',
    photos = 'photos',
    controlPoints = 'controlPoints',
    improvementWorks = 'improvementWorks',
    unvisitedRooms = 'unvisitedRooms',
    legalObligations = 'legalObligations',
    overview = 'overview',
    conclusion = 'conclusion',
    support = 'support',
    about = 'about',
    privacyPolicy = 'privacyPolicy',
    termsOfSales = 'termsOfSales',
    termsOfService = 'termsOfService',
    calculation = 'calculation',
    assistant = 'assistant',
    assistantControlPoints = 'assistantControlPoints',
    assistantFamilies = 'assistantFamilies',
    assistantRecap = 'assistantRecap',
    assistantAddControlPointFamily = 'assistantAddControlPointFamily',
    assistantAddControlPointElement = 'assistantAddControlPointElement',
}

export type RouteControlPointQuery = {
    controlPointId: string | null;
    isListMode: boolean;
    buildingIds: string[];
};

export type RouteCalculationsQuery = {
    controlPointId: string | null;
};

export type RouteAssistantRecapQuery = {
    buildingId: string | null;
};

export type RouteAssistantControlPointElementQuery = {
    buildingId: string;
    familyId: string;
};

export type RouteAssistantControlPointFamilyQuery = {
    buildingId: string;
};
