
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { DtgAction, DtgCol, DtgGrid, DtgRow, getName, useBem } from 'dtg-components';

import { useAuth } from '@/composables/use-auth';
import { useBackofficeLink } from '@/composables/use-backoffice-link';

import { BACKOFFICE_URL } from '@/utils/backoffice-app-routes';

import { Route } from '@/router';

import { DtgHeaderButton } from './elems/header-button';
import { DtgHeaderDropdown } from './elems/header-dropdown';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgGrid,
        DtgRow,
        DtgCol,
        DtgAction,
        DtgHeaderButton,
        DtgHeaderDropdown,
    },

    setup() {
        const { user } = useAuth();
        const router = useRouter();

        const aboutHref = router.resolve({ name: Route.about });
        const listHref = router.resolve({ name: Route.list });
        const loginHref = router.resolve({ name: Route.authLogin });
        const backofficeLinkProps = useBackofficeLink(BACKOFFICE_URL);

        return {
            b: useBem(styles),

            user,
            aboutHref,
            listHref,
            loginHref,
            backofficeLinkProps,
        };
    },
});
