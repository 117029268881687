/* eslint-disable @typescript-eslint/no-explicit-any */

import memoize from 'lodash/memoize';
import { Store } from 'vuex';

import { objFromEntries } from '../utils/object';

import { AnyModule, ContextSafe, ModuleOptionsOf, UnknownModule } from './types';

export type InjectableAnyTarget = {
    $init: (store: Store<any>) => void;
};

export type InjectableModuleTarget<
    T extends AnyModule,
    K extends keyof ModuleOptionsOf<T>,
    P extends string,
> = InjectableAnyTarget & {
    [KK in P]: ContextSafe<ModuleOptionsOf<T>[K]>;
};

export function createStoreModuleInjector<T extends UnknownModule>(
    getModule: <K extends keyof ModuleOptionsOf<T>>(name: K) => ModuleOptionsOf<T>[K],
) {
    return function inject<K extends keyof ModuleOptionsOf<T>>(name: K) {
        return function decorate<P extends string>(
            target: InjectableModuleTarget<T, K, P>,
            propertyKey: P,
        ): void {
            const origInit = target.$init;

            target.$init = function $init(store: Store<any>): void {
                origInit.call(this, store);

                Object.defineProperty(this, propertyKey, {
                    get: memoize(() => getModule(name).context(store)),
                });
            };
        };
    };
}

export type InjectableGroupTarget<
    T extends AnyModule,
    K extends keyof ModuleOptionsOf<T>,
    P extends string,
> = InjectableAnyTarget & {
    [KK in P]: {
        [KKK in K]: ContextSafe<ModuleOptionsOf<T>[KKK]>;
    };
};

export function createStoreModuleGroupInjector<T extends UnknownModule>(
    getModule: <K extends keyof ModuleOptionsOf<T>>(name: K) => ModuleOptionsOf<T>[K],
) {
    return function inject<K extends keyof ModuleOptionsOf<T>>(names: readonly K[]) {
        return function decorate<P extends string>(
            target: InjectableGroupTarget<T, K, P>,
            propertyKey: P,
        ): void {
            const origInit = target.$init;

            target.$init = function $init(store: Store<any>): void {
                origInit.call(this, store);

                Object.defineProperty(this, propertyKey, {
                    get: memoize(() => {
                        return objFromEntries(
                            names.map((name) => {
                                return [name, getModule(name).context(store)];
                            }),
                        );
                    }),
                });
            };
        };
    };
}

export type InjectableServiceTarget<T, P extends string> = InjectableAnyTarget & { [KK in P]: T };

export function injectService<T, U extends T>(getter: () => T) {
    return function decorate<P extends string>(target: InjectableServiceTarget<U, P>, propertyKey: P): void {
        const origInit = target.$init;

        target.$init = function $init(store: Store<any>): void {
            origInit.call(this, store);

            Object.defineProperty(this, propertyKey, {
                get: memoize(getter),
            });
        };
    };
}
