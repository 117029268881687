import firebase from 'firebase/compat/app';

import { Id, NestedItemSimplified } from 'dtg-shared/types/data';
import { Path, PathValue } from 'dtg-shared/types/utils';

import { FieldArrayType, FieldPrimitiveType, OrderByArg, OrderByArgArray, UpdateData } from './types';
import { buildWithId } from './utils';

import Firestore = firebase.firestore.Firestore;
import DocumentReference = firebase.firestore.DocumentReference;
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export class Db {
    private readonly firestore: Firestore;

    constructor(firestore: Firestore) {
        this.firestore = firestore;
    }

    getCollection<T>(path: string, reference?: DocumentReference): CollectionReference<T> {
        const document = reference ?? this.firestore;

        return document.collection(path) as CollectionReference<T>;
    }

    async fetchCollectionData<T>(
        reference: string | Query<T>,
        orderBy?: OrderByArg<T>,
    ): Promise<Array<T & Id>> {
        const collection = typeof reference === 'string' ? this.getCollection<T>(reference) : reference;

        return this.orderCollectionBy(collection, orderBy)
            .get()
            .then((snapshot) => snapshot.docs.map((doc) => buildWithId(doc)));
    }

    queryCollection<T, P extends Path<T>, V extends PathValue<T, P>>(
        collection: Query<T>,
        path: P,
        opStr: '<' | '<=' | '==' | '!=' | '>=' | '>',
        value: V extends FieldPrimitiveType ? V : never,
    ): Query<T>;

    queryCollection<T, P extends Path<T>, V extends PathValue<T, P>>(
        collection: Query<T>,
        path: P,
        opStr: 'in' | 'not-in',
        value: V[],
    ): Query<T>;

    queryCollection<T, P extends Path<T>, V extends PathValue<T, P>>(
        collection: Query<T>,
        path: P,
        opStr: 'array-contains',
        value: V extends FieldArrayType ? V[number] : never,
    ): Query<T>;

    // For the case of T | T[] where T is one of FieldPrimitiveType, specify the generics explicitly
    // e.g. for type Data = { field: string | string[]; }
    // call db.queryCollection<Data, 'field', string[]>(...)
    queryCollection<T, P extends Path<T>, V extends PathValue<T, P>>(
        collection: Query<T>,
        path: P,
        opStr: 'array-contains-any',
        value: V extends FieldArrayType ? V | V[] : never,
    ): Query<T>;

    queryCollection<T>(collection: Query<T>, path: string, opStr: WhereFilterOp, value: unknown): Query<T> {
        return collection.where(path, opStr, value);
    }

    orderCollectionBy<T>(reference: CollectionReference<T> | Query<T>, orderBy?: OrderByArg<T>): Query<T> {
        if (!orderBy) {
            return reference;
        }

        const args: OrderByArgArray<T> = Array.isArray(orderBy) ? orderBy : [orderBy];

        return reference.orderBy(...args);
    }

    getDoc<T>(path: string, reference?: CollectionReference<T>): DocumentReference<T> {
        const collection = reference ?? this.firestore;

        return collection.doc(path) as DocumentReference<T>;
    }

    fetchDocData<T>(docReference: DocumentReference<T>): Promise<T & Id>;
    fetchDocData<T>(docReference: string, collectionReference?: CollectionReference): Promise<T & Id>;
    async fetchDocData<T>(
        docReference: string | DocumentReference<T>,
        collectionReference?: CollectionReference<T>,
    ): Promise<T & Id> {
        const doc =
            typeof docReference === 'string'
                ? this.getDoc<T>(docReference, collectionReference)
                : docReference;

        return doc.get().then((doc) => {
            if (!doc.exists) {
                throw new Error("Document doesn't exist");
            }

            return buildWithId(doc);
        });
    }

    async updateDoc<T extends Record<string, unknown>>(
        reference: DocumentReference<T>,
        data: UpdateData<T>,
    ): Promise<void> {
        return reference.update(data);
    }

    // This has type safety in the results, but not in the implementation
    // T must have the same length as paths
    // Unlike fetchDocData and fetchCollectionData, T must include { id: string } to match the output
    /* eslint-disable @typescript-eslint/no-explicit-any */
    async fetchNestedCollectionData<T extends NestedItemSimplified>(
        paths: string[],
        orderBys: Array<OrderByArg<any>> = [],
        documentReference?: DocumentReference<any>,
    ): Promise<T[]> {
        if (paths.length === 0) {
            return [];
        }

        const [path, ...nestedPaths] = paths;
        const [orderBy, ...nestedOrderBys] = orderBys;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const collection = this.getCollection<any>(path!, documentReference);

        const docs = await this.orderCollectionBy(collection, orderBy)
            .get()
            .then((snapshot) => snapshot.docs);

        if (nestedPaths.length === 0) {
            return docs.map(buildWithId) as unknown as T[];
        }

        const promises = docs.map(async (doc) => {
            const children = await this.fetchNestedCollectionData(nestedPaths, nestedOrderBys, doc.ref);
            const data = buildWithId(doc) as Record<any, any>;

            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return { children, ...data } as T;
        });

        return Promise.all(promises);
    }

    async deleteNestedCollection(paths: string[], documentReference?: DocumentReference<any>): Promise<void> {
        if (paths.length === 0) {
            return;
        }

        const [path, ...nestedPaths] = paths;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const collection = this.getCollection<any>(path!, documentReference);

        await collection.get().then(async (snapshot) => {
            const { docs } = snapshot;

            const deletionPromises = docs.map(async (doc) => {
                return doc.ref.delete();
            });

            const nestedDeletionPromises = docs.map(async (doc) => {
                return this.deleteNestedCollection(nestedPaths, doc.ref);
            });

            await Promise.all([...deletionPromises, ...nestedDeletionPromises]);
        });
    }

    async updateNestedCollection<T extends Id>(
        items: Array<NestedItemSimplified<T>>,
        paths: string[],
        documentReference?: DocumentReference<any>,
    ): Promise<void> {
        if (paths.length === 0) {
            return;
        }

        const [path, ...nestedPaths] = paths;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const collection = this.getCollection<any>(path!, documentReference);

        const promises = items.map(async (item) => {
            const { id, children, ...data } = item;

            const doc = collection.doc(id);

            return doc.set(data).then(async () => {
                if (!children || nestedPaths.length === 0) {
                    return Promise.resolve();
                }

                return this.updateNestedCollection(children, nestedPaths, doc);
            });
        });

        await Promise.all(promises);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
