import { openToast } from 'dtg-components';

import { logError } from '@/utils/log-error';
import { RESOURCES_CACHED, RESOURCES_UPDATED } from '@/utils/toast-message';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitServiceWorkerEvents() {
    function initServiceWorkerEvents(): void {
        window.addEventListener('service-worker-updated', onUpdated);
        window.addEventListener('service-worker-cached', onCached);
        window.addEventListener('service-worker-error', onError);

        if (window.serviceWorkerUpdated) {
            onUpdated();
        }
    }

    function onUpdated(): void {
        void openToast(RESOURCES_UPDATED, {
            duration: -1,
            buttons: [
                {
                    text: 'RAFRAICHIR',
                    handler: (): void => {
                        window.location.reload();
                    },
                },
            ],
        });
    }

    function onCached(): void {
        void openToast(RESOURCES_CACHED);
    }

    function onError(event: Event): void {
        logError((event as CustomEvent).detail);
    }

    return {
        initServiceWorkerEvents,
    };
}
