import { string } from 'yup';

import { defineSchemaOf, email, phoneNumber, ValidationSchema } from 'dtg-shared/validation';

import { ContactFormData } from './types';

export function contactValidationSchema(): ValidationSchema<ContactFormData> {
    return defineSchemaOf<ContactFormData>()({
        name: string().label('Nom').required(),
        firstname: string().label('Prénom').defined(),
        society: string().label('Société').required(),
        function: string().label('Fonction').required(),
        phone: phoneNumber().label('Téléphone').required(),
        email: email().label('Email').defined(),
    });
}
