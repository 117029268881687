import { Option } from 'dtg-shared/types/data';
import { createUtcDate } from 'dtg-shared/utils/date';
import { objPick } from 'dtg-shared/utils/object';
import { truthy } from 'dtg-shared/utils/truthy';

import { buildCurativeRecord } from '../control-point';
import { buildPath, CollectionType, pathFromType } from '../firestore';

import {
    DtgConclusionData,
    dtgConclusionDataKeys,
    DtgCurativeOpinionsData,
    DtgData,
    DtgDataValid,
    DtgFormBuildingComplexData,
    dtgFormBuildingComplexDataKeys,
    DtgFormInformationData,
    dtgFormInformationDataKeys,
    DtgFormLocationData,
    dtgFormLocationDataKeys,
    DtgFormPhotosData,
    dtgFormPhotosDataKeys,
    DtgId,
    DtgStatus,
} from './types';

export function buildDtgData(data?: Partial<DtgData>): DtgData {
    return {
        idRepresentative: '',

        name: '',
        archived: false,
        createdTimestamp: Date.now(),
        updatedTimestamp: Date.now(),

        status: DtgStatus.planning,
        expertise: null,
        technicianId: null,
        validatorId: null,
        directorId: null,
        parentCompanyId: null,
        companyId: '',
        packId: null,

        location: null,
        addressExtra: '',
        addressFree: '',

        coverPhotoId: null,

        typologyId: null,
        registerNumber: '',
        appartmentCount: null,
        buildingCount: null,
        shob: null,
        buildingComplexDescription: '',

        endDate: createUtcDate().toISOString(),
        visitDate: createUtcDate().toISOString(),
        dtgType: null,
        nameOwner: '',
        phoneOwner: '',
        emailOwner: '',
        firstNameOwner: '',
        locationOwner: null,
        nameContactOwner: '',
        firstNameContactOwner: '',
        locationContactOwner: null,
        societyContactOwner: '',
        functionContactOwner: '',
        phoneContactOwner: '',
        emailContactOwner: '',
        isOwner: false,

        documentsState: null,
        energyState: null,
        visualState: null,
        costState: '',
        conclusion: '',

        curativeOpinions: buildCurativeRecord(''),

        valid: false,
        validPartially: buildDtgDataValidPartially(),
        comment: '',

        ...data,
    };
}

export function buildDtgDataValidPartially(value = false): DtgDataValid {
    return {
        location: value,
        information: value,
        buildingComplex: value,
        photos: value,
        conclusion: value,
        curativeOpinions: value,
    };
}

export function buildDtgCollectionPath({ dtgId }: { dtgId: string | null }): string {
    return buildPath(pathFromType(CollectionType.dtg), dtgId);
}

export function buildDtgSubCollectionPath({
    dtgId,
    type,
    docId = null,
}: DtgId & { type: CollectionType; docId?: string | null }): string {
    const dtgPath = buildDtgCollectionPath({ dtgId });

    return buildPath(dtgPath, pathFromType(type), docId);
}

export function buildDtgMissionName(dtg: { data: Partial<DtgData> } | null): string {
    if (!dtg) {
        return '';
    }

    const { name, nameOwner } = dtg.data;

    return [name, nameOwner].filter(truthy).join(' ');
}

export function buildDtgConclusionData(): DtgConclusionData {
    return objPick<DtgData, keyof DtgConclusionData>(buildDtgData(), dtgConclusionDataKeys);
}

export function buildDtgCurativeOpinionsData(): DtgCurativeOpinionsData {
    const curativeOpinions = buildCurativeRecord('');

    return {
        curativeOpinions,
    };
}

export function buildDtgFormLocationData(): DtgFormLocationData {
    return objPick<DtgData, keyof DtgFormLocationData>(buildDtgData(), dtgFormLocationDataKeys);
}

export function buildDtgFormPhotosData(): DtgFormPhotosData {
    return objPick<DtgData, keyof DtgFormPhotosData>(buildDtgData(), dtgFormPhotosDataKeys);
}

export function buildDtgFormBuildingComplexData(): DtgFormBuildingComplexData {
    return objPick<DtgData, keyof DtgFormBuildingComplexData>(buildDtgData(), dtgFormBuildingComplexDataKeys);
}

export function buildDtgFormInformationData(): DtgFormInformationData {
    return objPick<DtgData, keyof DtgFormInformationData>(buildDtgData(), dtgFormInformationDataKeys);
}

export function buildDtgStatuses(): Array<Option<DtgStatus>> {
    return [
        { id: DtgStatus.planning, value: 'PLANIFICATION' },
        { id: DtgStatus.opened, value: 'SAISIE OUVERTE' },
        { id: DtgStatus.onValidation, value: 'EN VALIDATION' },
        { id: DtgStatus.ready, value: 'BON POUR ENVOI' },
        { id: DtgStatus.closed, value: 'CLOTURE' },
    ];
}

export const DTG_PACK_PLANNING_STATUS_LABEL = 'DISPONIBLE';
