/* eslint-disable no-restricted-imports */

import { RouteRecordRaw } from 'vue-router';

import { aboutRoutes } from '@/router/routes/about';
import { authRoutes } from '@/router/routes/auth';
import { dtgRoutes } from '@/router/routes/dtg';
import { supportRoutes } from '@/router/routes/support';

import { Route } from '../types';

import { assistantRoutes } from './assistant';

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/list',
    },
    ...authRoutes,
    ...assistantRoutes,
    ...dtgRoutes,
    ...aboutRoutes,
    ...supportRoutes,
    {
        path: '/:catchAll(.*)',
        redirect: {
            name: Route.list,
        },
    },
];
