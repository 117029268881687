import { watch } from 'vue';

import { useAuth } from '@/composables/use-auth';

import { useStoreCollection } from '@/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitDtgStore() {
    const { isLoggedIn, wrapWithRefreshRetry } = useAuth();

    const {
        actions: { initAutosave, clearItemsCache },
    } = useStoreCollection('dtg');

    async function initDtgStore(): Promise<void> {
        watch(isLoggedIn, async (isLoggedIn) => {
            if (!isLoggedIn) {
                await clearItemsCache();
            }
        });

        await initAutosave({ actionWrapper: wrapWithRefreshRetry });
    }

    return {
        initDtgStore,
    };
}
