import { computed, ref } from 'vue';

import { KeyOf } from '../types/utils';
import { ReadonlyRef, WritableRef } from '../types/vue';
import { compareNumbers, compareStringsEmptyLast } from '../utils/compare';

export type SortableListConfig<T extends Record<string, unknown>> = {
    list: T[];
    fields: SortableListFields<T>;
    defaultSortKey?: KeyOf<T>;
    fallbackSortKey?: KeyOf<T>;
    defaultSortDirection?: SortableListDirection;
};

export type SortableListDirection = 'desc' | 'asc';

export type SortableListFields<T> = Array<SortableListField<T>>;

export type SortableListField<T> = keyof T extends infer K
    ? K extends KeyOf<T>
        ? SortableListFieldInner<T, K>
        : never
    : never;

export type SortableListFieldInner<T, K extends KeyOf<T>> = {
    key: K;
    sortable?: boolean;
    comparator?: (a: T[K], b: T[K]) => number;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useSortableList<T extends Record<string, unknown>>(
    config: ReadonlyRef<SortableListConfig<T>>,
) {
    const defaultSortDirection = computed(() => config.value.defaultSortDirection ?? 'asc');
    const defaultSortKey = computed(() => config.value.defaultSortKey ?? null);
    const fallbackSortKey = computed(() => config.value.fallbackSortKey ?? defaultSortKey.value);

    const sortKey: WritableRef<keyof T | null> = ref(defaultSortKey.value);
    const sortDirection = ref<SortableListDirection>(defaultSortDirection.value);

    const fallbackField = computed(() => {
        const { fields } = config.value;

        return fields.find(({ key }) => key === fallbackSortKey.value) ?? null;
    });

    const sortedList = computed(() => {
        const { list, fields } = config.value;

        if (sortKey.value === null) {
            return list;
        }

        const field = fields.find(({ key }) => key === sortKey.value);

        if (!field) {
            return list;
        }

        const { sortable, key, comparator: fieldComparator } = field;

        if (sortable === false) {
            return list;
        }

        const comparator = fieldComparator ?? defaultComparator;

        return list.slice().sort((itemA, itemB) => {
            return getSortDirectionModifier(sortDirection.value) * compare({ itemA, itemB, comparator, key });
        });
    });

    function compare({
        itemA,
        itemB,
        comparator,
        key,
    }: {
        itemA: T;
        itemB: T;
        comparator: (a: T[KeyOf<T>], b: T[KeyOf<T>]) => number;
        key: KeyOf<T>;
    }): number {
        const result = comparator(itemA[key], itemB[key]);

        if (result === 0 && fallbackField.value) {
            const fallbackFieldComparator = fallbackField.value.comparator ?? defaultComparator;
            const fallbackKey = fallbackField.value.key;

            return fallbackFieldComparator(itemA[fallbackKey], itemB[fallbackKey]);
        }

        return result;
    }

    // eslint-disable-next-line complexity
    function setSortKey(fieldKey: keyof T): void {
        const field = config.value.fields.find(({ key }) => key === fieldKey);

        if (!field || field.sortable === false) {
            return;
        }

        const isAsc = sortDirection.value === 'asc';

        if (sortKey.value === fieldKey) {
            if (fieldKey === defaultSortKey.value || isAsc) {
                sortDirection.value = isAsc ? 'desc' : 'asc';
            } else {
                reset();
            }
        } else {
            sortKey.value = fieldKey;
            sortDirection.value = 'asc';
        }
    }

    function reset(): void {
        sortKey.value = defaultSortKey.value;
        sortDirection.value = defaultSortDirection.value;
    }

    function isFieldSortable(fieldKey: keyof T): boolean {
        const field = config.value.fields.find(({ key }) => fieldKey === key);

        return field?.sortable !== false;
    }

    function getSortDirectionModifier(direction: SortableListDirection): -1 | 1 {
        return direction === 'desc' ? -1 : 1;
    }

    function getFieldSortDirection(key: keyof T): SortableListDirection | undefined {
        // eslint-disable-next-line no-undefined
        return sortKey.value === key ? sortDirection.value : undefined;
    }

    return {
        list: sortedList,
        sortKey,
        sortDirection,

        setSortKey,
        reset,
        isFieldSortable,
        getFieldSortDirection,
    };
}

function defaultComparator(a: unknown, b: unknown): number {
    if (typeof a === 'number' || typeof b === 'number') {
        return compareNumbers(a, b);
    }

    return compareStringsEmptyLast(a, b);
}
