
import { defineComponent } from 'vue';

import { DtgTermsOfService } from 'dtg-components';

import { DtgLayoutAbout } from '@/components/layouts/layout-about';

export default defineComponent({
    name: 'dtg-page-terms-of-service',

    components: {
        DtgLayoutAbout,
        DtgTermsOfService,
    },
});
