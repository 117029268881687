import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_spinner = _resolveComponent("dtg-spinner")!
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_mark = _resolveComponent("dtg-mark")!
  const _component_dtg_action = _resolveComponent("dtg-action")!

  return (_openBlock(), _createBlock(_component_dtg_action, {
    class: _normalizeClass(_ctx.b({ view: _ctx.view, disabled: _ctx.disabled })),
    href: _ctx.href,
    "active-class": _ctx.b({ active: true }),
    disabled: _ctx.disabled,
    routed: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('icon-wrapper'))
      }, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_dtg_spinner, {
              key: 0,
              class: _normalizeClass(_ctx.b('icon'))
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_dtg_icon, {
              key: 1,
              class: _normalizeClass(_ctx.b('icon')),
              name: _ctx.icon
            }, null, 8, ["class", "name"])),
        (!_ctx.valid)
          ? (_openBlock(), _createBlock(_component_dtg_mark, {
              key: 2,
              class: _normalizeClass(_ctx.b('mark')),
              view: "warning"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('label'))
      }, _toDisplayString(_ctx.label), 3)
    ]),
    _: 1
  }, 8, ["class", "href", "active-class", "disabled"]))
}