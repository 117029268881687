import { ReportApi } from 'dtg-api/classes/ReportApi';
import { TokenManager } from 'dtg-api/classes/TokenManager';
import { createService } from 'dtg-shared/utils/create-service';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

const reportApiService = createService('reportApi', (tokens: TokenManager) => {
    return new ReportApi(
        { logger: logError, tokens },
        {
            // in case baseUrl is relative, assume that the reports are served from the same origin
            baseURL: new URL(ENV.VUE_APP_GENERATE_REPORT_URL, location.origin).toString(),
        },
    );
});

export { reportApiService };
