import { ResultSuccessOf } from 'dtg-shared/types/data';
import { truthy } from 'dtg-shared/utils/truthy';

import { Api, ApiResponse, ApiResponseError } from './Api';

export class ApiModule {
    protected api!: Api;
    protected logger!: (error: unknown) => void;

    setApi(api: Api): void {
        this.api = api;
    }

    setLogger(logger: (error: unknown) => void): void {
        this.logger = logger;
    }

    async throwIfErrors<TResult, TError extends ApiResponseError>(
        promise: Promise<ApiResponse<TResult, TError>>,
    ): Promise<ResultSuccessOf<ApiResponse<TResult, TError>>> {
        return this.api.throwIfErrors(promise);
    }

    static all(responses: ApiResponse[]): ApiResponse {
        const error = this.combineErrors(responses);

        if (error.length === 0) {
            return { ok: true, result: null };
        }

        return { ok: false, error };
    }

    static combineErrors<T extends ApiResponseError>(responses: Array<ApiResponse<unknown, T>>): T[] {
        return responses.flatMap((response) => (response.ok ? null : response.error)).filter(truthy);
    }
}
