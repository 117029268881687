import { OverlayType, useBem, useOverlay } from 'dtg-components';

import DtgHeaderDropdownList from '../component.vue';
import { DtgListItemDropdownListeners, DtgListItemDropdownProps } from '../types';

import styles from '../style.module.scss';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useListItemDropdown() {
    const b = useBem(styles);

    return useOverlay<DtgListItemDropdownProps, DtgListItemDropdownListeners>({
        component: DtgHeaderDropdownList,
        overlayType: OverlayType.popover,
        cssClass: b('container'),
    });
}
