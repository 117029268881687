import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { reactive, watch } from 'vue';

import { Id } from '../../types/data';
import { ReadonlyRef } from '../../types/vue';
import { objAssignExceptUndefined } from '../../utils/object';

/**
 * @deprecated Use {@link useFormItemValidation} instead.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFormItem<T extends Record<string, unknown>>(
    item: ReadonlyRef<(Id & { data: T }) | null>,
    initialData: T,
    onUpdate: (id: string, data: T) => void,
) {
    const state = reactive(cloneDeep(initialData));

    watch(
        item,
        (value) => {
            const data = value ? value.data : initialData;

            if (!isEqual(state, data)) {
                objAssignExceptUndefined(state as T, cloneDeep(data));
            }
        },
        { immediate: true, deep: true },
    );

    watch(
        state,
        (value) => {
            if (item.value && !isEqual(value, item.value.data)) {
                onUpdate(item.value.id, cloneDeep(value) as T);
            }
        },
        { deep: true },
    );

    return state;
}
