import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_action = _resolveComponent("dtg-action")!

  return (_openBlock(), _createBlock(_component_dtg_action, {
    class: _normalizeClass(_ctx.b({ direction: _ctx.direction })),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSortClick())),
    onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSortClick()), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.b('label'))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('sort'))
      }, [
        _createVNode(_component_dtg_icon, {
          class: _normalizeClass(_ctx.b('sort-arrow', { asc: true })),
          name: "chevron"
        }, null, 8, ["class"]),
        _createVNode(_component_dtg_icon, {
          class: _normalizeClass(_ctx.b('sort-arrow', { desc: true })),
          name: "chevron"
        }, null, 8, ["class"])
      ], 2)
    ]),
    _: 3
  }, 8, ["class"]))
}