import { computed, watch } from 'vue';

import { openToast } from 'dtg-components';

import { useNativePlugin } from '@/composables/use-native-plugins';

import { getFirestore } from '@/services/firebase';

import { useStoreModule } from '@/store';

import { ENV } from '@/utils/env';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useNetworkStatus() {
    const {
        getters: { getIsOnline },
        mutations: { setIsOnline },
    } = useStoreModule('app');

    const withNetwork = useNativePlugin('Network');

    const online = computed(() => getIsOnline());
    const offline = computed(() => !getIsOnline());

    async function initNetworkStatus(): Promise<void> {
        if (ENV.VUE_APP_PLATFORM === 'web') {
            onChange(navigator.onLine);

            window.addEventListener('online', () => {
                onChange(true);
            });

            window.addEventListener('offline', () => {
                onChange(false);
            });
        } else {
            await withNetwork(async (plugin) => {
                const { connected } = await plugin.Network.getStatus();

                onChange(connected);

                await plugin.Network.addListener('networkStatusChange', ({ connected }) => {
                    onChange(connected);
                });
            });
        }

        watch(online, showNetworkStatusToast);

        if (!online.value) {
            showNetworkStatusToast();
        }
    }

    function onChange(connected: boolean): void {
        setIsOnline({ value: connected });
    }

    function showNetworkStatusToast(): void {
        void openToast(online.value ? 'Connexion réseau restaurée' : 'La connexion réseau est perdue');
    }

    return {
        online,
        offline,

        initNetworkStatus,
    };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useNetworkStatusDebug() {
    const {
        mutations: { setIsOnline },
    } = useStoreModule('app');

    const firestore = getFirestore();

    async function disableNetwork(): Promise<void> {
        setIsOnline({ value: false });

        await firestore.disableNetwork();
    }

    async function enableNetwork(): Promise<void> {
        setIsOnline({ value: true });

        await firestore.enableNetwork();
    }

    return {
        disableNetwork,
        enableNetwork,
    };
}
