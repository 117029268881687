
import md5 from 'md5';
import { computed, defineComponent } from 'vue';

import { DtgAction, DtgAvatar, DtgIcon, getName, positionPopover, useBem } from 'dtg-components';
import { truthy } from 'dtg-shared/utils/truthy';

import { useAuth } from '@/composables/use-auth';
import { useBackofficeLink } from '@/composables/use-backoffice-link';

import { BACKOFFICE_URL, PROFILE_URL } from '@/utils/backoffice-app-routes';

import { useHeaderDropdownList } from '../header-dropdown-list';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgAction,
        DtgAvatar,
        DtgIcon,
    },

    setup() {
        const { isOpen, open } = useHeaderDropdownList();
        const { logoutWithConfirmation, user } = useAuth();

        const fullName = computed(() => {
            return [user.value?.firstName, user.value?.lastName].filter(truthy).join(' ');
        });

        const userEmail = computed(() => {
            return user.value?.email ?? '';
        });

        const rolesLabel = computed(() => {
            return user.value?.rolesLabel ?? '';
        });

        const userEmailHash = computed(() => {
            const email = userEmail.value;

            if (!email) {
                return '';
            }

            return md5(email).toLowerCase().trim();
        });

        const avatarUrl = computed(() => `https://gravatar.com/avatar/${userEmailHash.value}?d=identicon`);

        const profileLinkProps = useBackofficeLink(PROFILE_URL);
        const backofficeLinkProps = useBackofficeLink(BACKOFFICE_URL);

        function onActivatorClick(event: Event): void {
            open(
                {
                    onLogout,
                    profileLinkProps,
                    backofficeLinkProps,
                },
                positionPopover(event),
            );
        }

        async function onLogout(): Promise<void> {
            await logoutWithConfirmation();
        }

        return {
            b: useBem(styles),

            active: isOpen,
            avatarUrl,
            fullName,
            rolesLabel,

            onActivatorClick,
        };
    },
});
