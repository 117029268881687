import { CollectionSubscription } from './CollectionSubscription';

export class CollectionSubscriptions {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private readonly subscriptions: Set<CollectionSubscription<any>> = new Set();

    /**
     * Resolves after fetching the current state of the collection (from remote or local db).
     * Rejects if fails to fetch initial state or if canceled during initial fetch.
     * On initial load and collection update, calls onSnapshot.
     * On collection update error, calls onError.
     * If canceled after resolving/rejecting, does not call onError.
     *
     * Cancelling is done via {@link CollectionSubscriptions#removeAll}
     * or by calling {@link CollectionSubscription#unsubscribe} on the returned subscription.
     */
    async add<T>(
        options: ConstructorParameters<typeof CollectionSubscription<T>>[0],
    ): Promise<CollectionSubscription<T>> {
        const subscription = new CollectionSubscription(options);

        this.subscriptions.add(subscription);

        try {
            await subscription.subscribe();
        } catch (error) {
            subscription.unsubscribe();

            this.subscriptions.delete(subscription);

            throw error;
        }

        return subscription;
    }

    removeAll(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        this.subscriptions.clear();
    }
}
