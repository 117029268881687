import { captureException } from '@sentry/vue';

import { ApiError } from 'dtg-api/classes/Error';
import { ErrorWithContext } from 'dtg-shared/classes/ErrorWithContext';

export function logError(error: unknown): void {
    // eslint-disable-next-line no-console
    console.warn(error);

    if (error instanceof Error) {
        captureException(error);
    } else {
        captureException(new ErrorWithContext(error));
    }
}

export function logNonApiError(error: unknown): void {
    if (!(error instanceof ApiError)) {
        logError(error);
    }
}
