export function withCachedPromise<T extends unknown[], U>(
    origFunc: (...args: T) => Promise<U>,
    tillResolve = false,
): (...args: T) => Promise<U> {
    let promise: Promise<U> | null = null;

    return async function value(...args: T): Promise<U> {
        if (!promise) {
            promise = origFunc(...args);

            if (tillResolve) {
                promise.then(
                    () => {
                        promise = null;
                    },
                    (error) => {
                        promise = null;

                        throw error;
                    },
                );
            } else {
                promise.catch((error) => {
                    promise = null;

                    throw error;
                });
            }
        }

        return promise;
    };
}
