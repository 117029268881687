import { truthy } from 'dtg-shared/utils/truthy';

export type TransformShape = { [key: string]: boolean | TransformShape } | [TransformShape];

export function transformObject(
    obj: unknown,
    transformKey: (key: string) => string,
    transformValue: (value: unknown) => unknown,
    shape: TransformShape | null = null,
): unknown {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const valueShape = Array.isArray(shape) ? shape[0] : null;

        return obj
            .map(transformValue)
            .filter((el) => typeof el !== 'undefined')
            .map((el) => transformObject(el, transformKey, transformValue, valueShape));
    }

    const transformedEntries = Object.entries(obj as Record<string, unknown>)
        .map(([key, value]) => {
            const shouldTransform = shape === null || Array.isArray(shape) ? true : shape[key];

            if (!shouldTransform) {
                return [key, value];
            }

            const valueShape = typeof shouldTransform === 'object' ? shouldTransform : null;
            const transformedValueShallow = transformValue(value);

            if (typeof transformedValueShallow === 'undefined') {
                return null;
            }

            const transformedKey = transformKey(key);
            const transformedValue = transformObject(
                transformedValueShallow,
                transformKey,
                transformValue,
                valueShape,
            );

            return [transformedKey, transformedValue];
        })
        .filter(truthy);

    return Object.fromEntries(transformedEntries);
}
