
import { defineComponent } from 'vue';

import { DtgDropdown, DtgDropdownItem, events, getName } from 'dtg-components';

import { props } from './props';
import { DtgListItemDropdownEvents } from './types';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgDropdown,
        DtgDropdownItem,
    },

    props,

    emits: events(['close', 'downloadReport', 'repairValidation'])<DtgListItemDropdownEvents>(),

    setup(props, { emit }) {
        function onRepairValidationClick(): void {
            emit('repairValidation');
        }

        function onReportDownloadClick(): void {
            emit('downloadReport');
        }

        return {
            onRepairValidationClick,
            onReportDownloadClick,
        };
    },
});
