
import { defineComponent } from 'vue';

import { DtgPrivacyPolicy } from 'dtg-components';

import { DtgLayoutAbout } from '@/components/layouts/layout-about';

export default defineComponent({
    name: 'dtg-page-privacy-policy',

    components: {
        DtgLayoutAbout,
        DtgPrivacyPolicy,
    },
});
