import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      (_ctx.initialized)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }, {
            default: _withCtx(({ Component, route }) => [
              (_openBlock(), _createBlock(_KeepAlive, { max: 5 }, [
                (route.meta.keepAlive)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                      key: route.path
                    }))
                  : _createCommentVNode("", true)
              ], 1024)),
              (!route.meta.keepAlive)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}