import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_title = _resolveComponent("dtg-title")!
  const _component_dtg_input = _resolveComponent("dtg-input")!
  const _component_dtg_field = _resolveComponent("dtg-field")!
  const _component_dtg_col = _resolveComponent("dtg-col")!
  const _component_dtg_input_date = _resolveComponent("dtg-input-date")!
  const _component_dtg_select = _resolveComponent("dtg-select")!
  const _component_dtg_checkbox = _resolveComponent("dtg-checkbox")!
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_action = _resolveComponent("dtg-action")!
  const _component_dtg_row = _resolveComponent("dtg-row")!
  const _component_dtg_grid = _resolveComponent("dtg-grid")!
  const _component_dtg_row_col = _resolveComponent("dtg-row-col")!
  const _component_dtg_list_sort_controls = _resolveComponent("dtg-list-sort-controls")!
  const _component_dtg_list_item = _resolveComponent("dtg-list-item")!
  const _component_dtg_transition_group_stack = _resolveComponent("dtg-transition-group-stack")!
  const _component_dtg_icon_action = _resolveComponent("dtg-icon-action")!
  const _component_dtg_layout_without_sidebar = _resolveComponent("dtg-layout-without-sidebar")!

  return (_openBlock(), _createBlock(_component_dtg_layout_without_sidebar, {
    class: _normalizeClass(_ctx.b()),
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_dtg_title, { view: "1" }, {
        default: _withCtx(() => [
          _createTextVNode(" Liste des DTG ")
        ]),
        _: 1
      }),
      _createVNode(_component_dtg_grid, null, {
        default: _withCtx(() => [
          (_ctx.shownFilters)
            ? (_openBlock(), _createBlock(_component_dtg_row_col, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.b('filters'))
                  }, [
                    _createVNode(_component_dtg_grid, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_dtg_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_dtg_col, {
                              "size-xs": "12",
                              "size-sm": "6",
                              "size-lg": ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_dtg_field, { label: "Nom dossier" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_input, {
                                      modelValue: _ctx.queries.name,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queries.name) = $event)),
                                      name: "name"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_dtg_col, {
                              "size-xs": "12",
                              "size-sm": "6",
                              "size-lg": "2.4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_dtg_field, { label: "Date de visite" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_input_date, {
                                      modelValue: _ctx.queries.visitDate,
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queries.visitDate) = $event)),
                                      name: "visitDate"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_dtg_col, {
                              "size-xs": "12",
                              "size-sm": "6",
                              "size-lg": "2.4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_dtg_field, { label: "Date de rendu attendu" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_input_date, {
                                      modelValue: _ctx.queries.endDate,
                                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.queries.endDate) = $event)),
                                      name: "endDate"
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_dtg_col, {
                              "size-xs": "12",
                              "size-sm": "6",
                              "size-lg": "2.9"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_dtg_field, { label: "Statut" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_select, {
                                      modelValue: _ctx.queries.status,
                                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queries.status) = $event)),
                                      options: _ctx.dtgStatuses,
                                      name: "status",
                                      tag: ""
                                    }, null, 8, ["modelValue", "options"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_ctx.shownMineFilter)
                              ? (_openBlock(), _createBlock(_component_dtg_col, {
                                  key: 0,
                                  "size-lg": "1.8"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_field, { label: "Mes affectations" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_dtg_checkbox, {
                                          modelValue: _ctx.queries.mine,
                                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.queries.mine) = $event)),
                                          name: "mine"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Afficher ")
                                          ]),
                                          _: 1
                                        }, 8, ["modelValue"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_dtg_col, {
                              class: _normalizeClass(_ctx.b('col-reset'))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_dtg_action, {
                                  class: _normalizeClass(_ctx.b('reset-button')),
                                  disabled: !_ctx.filtersApplied,
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onResetFiltersClick()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_dtg_icon, {
                                      class: _normalizeClass(_ctx.b('reset-button-icon')),
                                      name: "closeCircle"
                                    }, null, 8, ["class"]),
                                    _createTextVNode(" Réinitialiser les filtres ")
                                  ]),
                                  _: 1
                                }, 8, ["class", "disabled"])
                              ]),
                              _: 1
                            }, 8, ["class"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ], 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.shownFilters)
            ? (_openBlock(), _createBlock(_component_dtg_row, {
                key: 1,
                class: _normalizeClass(_ctx.b('sort-row'))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_dtg_col, {
                    class: _normalizeClass(_ctx.b('col-sort'))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_dtg_list_sort_controls, {
                        sortKey: _ctx.sortKey,
                        "onUpdate:sortKey": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sortKey) = $event)),
                        sortDirection: _ctx.sortDirection,
                        "onUpdate:sortDirection": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.sortDirection) = $event)),
                        onSortClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setSortKey($event)))
                      }, null, 8, ["sortKey", "sortDirection"])
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true),
          _createVNode(_component_dtg_row_col, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.b('list-wrapper'))
              }, [
                _createVNode(_component_dtg_transition_group_stack, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id
                      }, [
                        _createVNode(_component_dtg_list_item, {
                          class: _normalizeClass(_ctx.b('item')),
                          "dtg-id": item.id
                        }, null, 8, ["class", "dtg-id"])
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ], 2)
            ]),
            _: 1
          }),
          (_ctx.noDtgs)
            ? (_openBlock(), _createBlock(_component_dtg_row_col, { key: 2 }, {
                default: _withCtx(() => [
                  _createVNode(_component_dtg_title, { view: "2" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Aucun DTG "),
                      (_ctx.refreshShown)
                        ? (_openBlock(), _createBlock(_component_dtg_icon_action, {
                            key: 0,
                            name: "reload",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onRefresh()))
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "loading"]))
}