import Cookies from 'js-cookie';

import { TokenStorage } from './types';

export class TokenCookieStorage implements TokenStorage {
    private readonly domain?: string;
    private readonly expires: number;

    constructor(domain?: string, expires = 365) {
        this.domain = domain;
        this.expires = expires;
    }

    getToken(key: string): string | null {
        return Cookies.get(key) ?? null;
    }

    setToken(key: string, value: string | null): void {
        const { domain, expires } = this;

        if (value) {
            Cookies.set(key, value, { domain, expires });
        } else {
            Cookies.remove(key, { domain });
        }
    }
}
