import { FreeDate, Id } from 'dtg-shared/types/data';
import { TemplateFriendlyObject } from 'dtg-shared/utils/template-friendly-object';

export enum BuildingType {
    interior = 'interior',
    exterior = 'exterior',
    basement = 'basement',
}

export enum BuildingFamilyTreatmentType {
    notApplicable = 'notApplicable',
    belongs = 'belongs',
    notBelongs = 'notBelongs',
}

export type BuildingData = {
    type: BuildingType | null;
    name: string;
    heatingMode: string | null;
    entriesNumber: number | null;
    level: string;
    undergroundLevel: string;
    yearConstruction: string | null;
    dateConstruction: FreeDate | null;
    roofType: string | null;
    hasInteriorElevator: boolean | null;
    hasBasementElevator: boolean | null;
    fireSafetyType: string | null;
    hasAttic: boolean | null;
    hasLocalTrash: boolean | null;
    hasGarage: boolean | null;
    hasCellar: boolean | null;
    hasLocalServices: boolean | null;
    hasTransformationStation: boolean | null;
    hasCirculation: boolean | null;
    hasExteriorParking: boolean | null;
    hasBasementParking: boolean | null;
    hasOutbuilding: boolean | null;
    hasGate: boolean | null;
    hasGarden: boolean | null;
    hasPool: boolean | null;

    wasAuditPerformed: BuildingAuditStatus | null;
    auditType: BuildingAuditType.audit | BuildingAuditType.dpe | null;
    auditFile: BuildingAuditFile | null;
    auditProvidedBy: string;
    auditAttendant: string;
    auditDate: string | null;

    energyConsumption: number | null;
    energyConsumptionClass: string | null;
    gasEmission: number | null;
    gasEmissionClass: string | null;

    treatedFamilies: Array<{
        familyId: string;
        value: BuildingFamilyTreatmentType;
        comment: string;
    }>;

    createdTimestamp: number;
    valid: boolean;
    validPartially: {
        building: boolean;
        energyBalance: boolean;
    };
};

export enum BuildingAuditType {
    audit = 'audit',
    dpe = 'dpe',
    notConcerned = 'notConcerned',
}

export enum BuildingAuditStatus {
    performed = 'performed',
    notPerformed = 'notPerformed',
    inProgress = 'inProgress',
}

export type BuildingAuditFile = Id & {
    name: string;
};

export const buildingFormDataKeys = [
    'type',
    'name',

    // interior keys
    'heatingMode',
    'entriesNumber',
    'level',
    'yearConstruction',
    'dateConstruction',
    'hasInteriorElevator',
    'roofType',
    'fireSafetyType',
    'hasAttic',
] as const;

export const exteriorBuildingFormDataKeys = [
    'hasCirculation',
    'hasExteriorParking',
    'hasOutbuilding',
    'hasGate',
    'hasGarden',
    'hasPool',
] as const;

export const basementBuildingFormDataKeys = [
    'undergroundLevel',
    'hasBasementElevator',
    'hasLocalTrash',
    'hasGarage',
    'hasCellar',
    'hasLocalServices',
    'hasTransformationStation',
    'hasBasementParking',
] as const;

export type BuildingFormData = Pick<BuildingData, typeof buildingFormDataKeys[number]> &
    Pick<BuildingData, typeof exteriorBuildingFormDataKeys[number]> &
    Pick<BuildingData, typeof basementBuildingFormDataKeys[number]>;

export const buildingFormEnergyBalanceDataKeys = [
    'type',
    'yearConstruction',
    'dateConstruction',
    'wasAuditPerformed',
    'auditType',
    'auditFile',
    'auditProvidedBy',
    'auditAttendant',
    'auditDate',
    'energyConsumption',
    'energyConsumptionClass',
    'gasEmission',
    'gasEmissionClass',
] as const;

export type BuildingFormEnergyBalanceData = Pick<
    BuildingData,
    typeof buildingFormEnergyBalanceDataKeys[number]
>;

export enum BuildingHeatingMode {
    individual = 'individuel',
    collective = 'collectif',
}

export type BuildingViewItem = TemplateFriendlyObject<
    BuildingData & Id,
    [
        'level',
        'hasInteriorElevator',
        'hasBasementElevator',
        'roofType',
        'entriesNumber',
        'heatingMode',
        'dateConstruction',
        'auditProvidedBy',
        'auditAttendant',
        'auditDate',
        'hasLocalTrash',
        'hasLocalTrash',
        'hasGarage',
        'hasCellar',
        'hasLocalServices',
        'hasTransformationStation',
        'hasCirculation',
        'hasExteriorParking',
        'hasBasementParking',
        'hasOutbuilding',
        'hasGate',
        'hasGarden',
        'hasPool',
        'hasAttic',
    ]
> & {
    typeValue: string;
    auditTypeLabel: string;
};
