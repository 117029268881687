import { Id, LatLngLiteral } from 'dtg-shared/types/data';

import { ApiResponse, ApiResponseError } from '../../classes/Api';
import { ApiOption, ApiRadius } from '../../types/data';

export type ApiCompany = {
    id: string;
    uuid: string;
    parent?: string;
    parentCompany?: ApiOption;
    name: string;
    active: boolean;
    siret?: string;
    apeCode?: string;
    expertises: ApiOption[];

    city: string;
    postalCode: string;
    country: string;
    addressExtra: string;
    route: string;
    coordinates?: LatLngLiteral;

    radius: ApiRadius;

    activeDate: string;
    createdAt: string;
    updatedAt: string;

    dtgAffectedQty: number;
    dtgCheckedQty?: number;
    usersQty?: number;

    website?: string;
    email?: string;
    phoneNumber?: string;
    assurance?: string;
    logo?: ApiCompanyLogo;
    primaryColor?: string;
    secondaryColor?: string;

    billingName: string;
    billingLastName: string;
    billingPhone: string;
    billingEmail: string;
    billingLink: string;

    companyEngagement: ApiCompanyEngagement[];
};

export type ApiCompanyShallow = Pick<
    ApiCompany,
    | 'uuid'
    | 'id'
    | 'name'
    | 'siret'
    | 'apeCode'
    | 'active'
    | 'activeDate'
    | 'website'
    | 'email'
    | 'phoneNumber'
    | 'assurance'
    | 'primaryColor'
    | 'secondaryColor'
    | 'createdAt'
    | 'updatedAt'
>;

export type ApiCompanyLogo = {
    id: string;
    url: string;
    filename: string;
    mimeType: string;
};

export type ApiCompanyLogoPayload = {
    base64: string;
    filename: string;
    mimeType: string;
};

export type ApiCompanyCreate = Required<
    Pick<
        ApiCompany,
        | 'name'
        | 'siret'
        | 'apeCode'
        | 'website'
        | 'email'
        | 'phoneNumber'
        | 'assurance'
        | 'primaryColor'
        | 'secondaryColor'
        | 'city'
        | 'country'
        | 'postalCode'
        | 'route'
        | 'addressExtra'
        | 'billingName'
        | 'billingLastName'
        | 'billingPhone'
        | 'billingEmail'
        | 'billingLink'
    >
> & {
    expertises: string[];
    radius: string;
    parent: string | null;
    coordinates: LatLngLiteral | null;
    logo?: ApiCompanyLogoPayload | null;
    companyEngagement?: ApiCompanyEngagementPayload[];
};

export type ApiCompanyUpdate = ApiCompanyCreate & Required<Pick<ApiCompany, 'active'>>;

export enum ApiCompanyEngagementType {
    subscription = 'subscription',
    pack = 'pack',
}

export type ApiCompanyEngagement = Id & {
    createdAt: string;
} & (
        | {
              type: ApiCompanyEngagementType.subscription;
          }
        | {
              type: ApiCompanyEngagementType.pack;
              pack: ApiCompanyEngagementPack;
          }
    );

export type ApiCompanyEngagementPack = {
    idRepresentative: string;
    startDate: string;
    endDate: string;
    dtgUsedQty: number;
    dtgTotalQty: number;
    status: ApiCompanyEngagementPackStatus;
};

export type ApiCompanyEngagementPayload = Partial<Id> &
    (
        | {
              type: ApiCompanyEngagementType.subscription;
          }
        | {
              type: ApiCompanyEngagementType.pack;
              pack: Pick<ApiCompanyEngagementPack, 'startDate' | 'endDate'>;
          }
    );

export enum ApiCompanyEngagementPackStatus {
    pending = 'pending',
    active = 'active',
    expiring = 'expiring',
    inactive = 'inactive',
}

/* Request types */
export type ApiCompanyCreateRequest = ApiCompanyCreate;
export type ApiCompanyCreateResponse = ApiResponse<{ company: ApiCompany }, ApiCompanyCreateUpdateError>;

export type ApiCompanyUpdateRequest = ApiCompanyUpdate;
export type ApiCompanyUpdateResponse = ApiResponse<
    { updatedCompany: ApiCompany; accessRevoked: boolean },
    ApiCompanyCreateUpdateError
>;

export type ApiCompanyCreateUpdateError = ApiResponseError<ApiCompanyCreateUpdateErrorCodes>;
export enum ApiCompanyCreateUpdateErrorCodes {
    sameName = 'company/same-name-company',
    sameSiret = 'company/same-siret-company',
}

export type ApiCompanyListRequest = { active?: boolean };
export type ApiCompanyListResponse = ApiResponse<{ items: ApiCompany[] }>;

export type ApiCompanyInfoResponse = ApiResponse<{ item: ApiCompany }>;
