import { ComponentPublicInstance } from 'vue';

export function getElementFromRef<T extends HTMLElement>(
    reference: ComponentPublicInstance | T | null | undefined,
): T | null {
    if (!reference) {
        return null;
    }

    return '$el' in reference ? (reference.$el as T) : reference;
}
