const thumbnailPrefix = 'thumb_';
const coverPrefix = 'cover_';

export function buildPhotoPath(name: string): string {
    return `photos/${name}`;
}

export function buildPhotoThumbnailPath(path: string): string {
    const pathParts = path.split('/');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const name = pathParts[pathParts.length - 1]!;

    return buildPhotoPath(`${thumbnailPrefix}${name}`);
}

export function buildPhotoCoverPath(path: string): string {
    const pathParts = path.split('/');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const name = pathParts[pathParts.length - 1]!;

    return buildPhotoPath(`${coverPrefix}${name}`);
}

export function isPhotoThumbnailPath(path?: string): boolean {
    return path?.startsWith(thumbnailPrefix) ?? false;
}

export function isPhotoCoverPath(path?: string): boolean {
    return path?.startsWith(coverPrefix) ?? false;
}
