import { OverlayType, useOverlay } from 'dtg-components';

import DtgHeaderDropdownList from '../component.vue';
import { DtgHeaderDropdownListeners, DtgHeaderDropdownProps } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useHeaderDropdownList() {
    return useOverlay<DtgHeaderDropdownProps, DtgHeaderDropdownListeners>({
        component: DtgHeaderDropdownList,
        overlayType: OverlayType.popover,
    });
}
