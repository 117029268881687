/* Adds additional information to a vue component prop validator for better inference in Storybook */

import { truthy } from './truthy';

const propValidatorKey = '__propValidatorKey__';

type PropValidator<T> = {
    (value: T): boolean;
    options: unknown;
    [propValidatorKey]?: boolean;
};

export function propValidator<T>(validator: (value: T) => boolean, options: unknown): PropValidator<T> {
    return Object.assign(validator, { options, [propValidatorKey]: true });
}

export function isPropValidator(
    validator?: (value: unknown) => boolean,
): validator is PropValidator<unknown> {
    return truthy(validator) && propValidatorKey in validator;
}
