/*
 * Normalizes string by removing accents from it.
 */
export function normalizeString(string: string): string {
    return String(string)
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase()
        .trim();
}

export function capitalizeSentence(sentence: string): string {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function stringifyError(error: unknown): string {
    return JSON.stringify(error, Object.getOwnPropertyNames(error), 4);
}
