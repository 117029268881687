import { useField } from 'vee-validate';
import { ComponentPublicInstance, computed, ref, shallowReadonly, unref } from 'vue';

import { ReadonlyRef, WritableRef } from '../../types/vue';

import {
    FieldDisabled,
    FormField,
    FormFieldProps,
    FormInputProps,
    FormValidationResult,
    Validate,
} from './types';

export function extendField<T = unknown>(
    field: ReturnType<typeof useField<T>>,
    {
        uuid,
        disabled,
        fieldsDisabled,
    }: {
        uuid: string;
        disabled?: FieldDisabled<T>;
        fieldsDisabled?: FieldDisabled<T>;
    },
): FormField<T> {
    const required = ref(false);
    const { errorMessage, label, name, meta, validate, errors, setErrors, setTouched } = field;
    const touched = computed(() => meta.touched);
    const invalid = computed(() => (meta.valid ? null : true));
    const disabledRef = unwrapRefOrCallback(field, disabled);
    const fieldsDisabledRef = unwrapRefOrCallback(field, fieldsDisabled);
    const componentInstance = ref<ComponentPublicInstance | null>(null);
    const vmodel = computed({
        get: () => field.value.value,
        set: updateValue,
    });

    const props: FormInputProps<T> = {
        /* eslint-disable @typescript-eslint/naming-convention */
        name,
        'data-invalid': invalid,
        'data-form-uuid': uuid,
        'modelValue': field.value,
        'onUpdate:modelValue': updateValue,
        'onBlur': () => {
            if (field.errors.value.length === 0) {
                void validate();
            }

            setTouched(true);
        },
        'disabled': disabledRef,
        'ref': (value): void => {
            componentInstance.value = value;
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    };

    const fieldProps: FormFieldProps = {
        required,
        errorMessage,
        touched,
        label: label ?? name,
        disabled: fieldsDisabledRef,
    };

    function updateValue(value: T): void {
        field.value.value = value;
    }

    return {
        name,
        meta,

        errors,
        errorMessage,
        setErrors(error: string | string[] | null): void {
            setErrors(error ?? '');
            setTouched(true);
        },

        props,
        fieldProps,

        ref: componentInstance,
        value: shallowReadonly(field.value),
        vmodel,

        updateValue,
        setTouched,
        validate,
        toWritableRef(): WritableRef<T> {
            return vmodel;
        },
        validateAndSetTouched: async (): Promise<void> => {
            await field.validate();
            field.setTouched(true);
        },
        withReplacedModelValue<U = T>(modelValue: WritableRef<U>): FormInputProps<U> {
            return {
                ...props,
                modelValue,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'onUpdate:modelValue': (value): void => {
                    modelValue.value = value;
                },
            };
        },
    };
}

function unwrapRefOrCallback<T>(
    field: ReturnType<typeof useField<T>>,
    refOrCallback?: FieldDisabled<T>,
): ReadonlyRef<boolean> | undefined {
    if (typeof refOrCallback !== 'function') {
        return refOrCallback;
    }

    return computed(() => {
        // name can be nested (some.nested.field), but we don't use nested fields, so it's fine
        const key = unref(field.name) as keyof T;

        return refOrCallback(key);
    });
}

export async function runValidators(validators: Validate[], focus = true): Promise<FormValidationResult> {
    return validators.reduce<Promise<FormValidationResult>>(async (promise, validate) => {
        const prevResult = await promise;
        const curResult = await validate({ focus: focus && prevResult.valid });

        const valid = prevResult.valid && curResult.valid;
        const errors = { ...prevResult.errors, ...curResult.errors };

        return Promise.resolve({ valid, errors });
    }, Promise.resolve({ valid: true, errors: {} }));
}
