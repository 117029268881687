import { PdfGenerateResponse, PdfPollResponse, routes } from 'dtg-shared-report';

import { addErrorMessageLocalization, ApiBase, ApiBaseOptions, ApiRequestConfig } from '../Api';
import { TokenManager } from '../TokenManager';

import {
    ReportApiGenerateRequest,
    ReportApiGenerateResponse,
    ReportApiPollRequest,
    ReportApiPollResponse,
} from './types';

export class ReportApi extends ApiBase {
    private readonly tokens;

    constructor(
        options: ApiBaseOptions & {
            tokens: TokenManager;
        },
        config?: ApiRequestConfig,
    ) {
        super(options, config);
        this.tokens = options.tokens;

        addErrorMessageLocalization(this.axios);

        this.addRequestInterceptor((config) => {
            const { accessToken } = this.tokens;

            if (accessToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            }

            return config;
        });
    }

    async generateReport(
        requestData: ReportApiGenerateRequest,
        signal?: AbortSignal,
    ): Promise<ReportApiGenerateResponse> {
        const reportUrl = this.buildUrl({ requestData, action: 'generate' });
        const { result } = await this.post<PdfGenerateResponse>(reportUrl, {}, { signal });

        return result;
    }

    async pollResult(
        requestData: ReportApiPollRequest,
        signal?: AbortSignal,
    ): Promise<ReportApiPollResponse> {
        const reportUrl = this.buildUrl({ requestData, action: 'pollResult' });
        const { result } = await this.get<PdfPollResponse>(reportUrl, { signal });

        return result;
    }

    private buildUrl({
        action,
        requestData,
    }: {
        action: keyof typeof routes;
        requestData: Record<string, string>;
    }): string {
        const params = new URLSearchParams(requestData);
        const route = routes[action];

        return `${route}?${params.toString()}`;
    }
}
