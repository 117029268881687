
import { defineComponent } from 'vue';

import { DtgLayoutAuth, getName, useBem } from 'dtg-components';

import { DtgOfflineIndicator } from '@/components/molecules/offline-indicator';

import { useNativePlugin } from '@/composables/use-native-plugins';
import { useNetworkStatus } from '@/composables/use-network-status';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgLayoutAuthBase: DtgLayoutAuth,
        DtgOfflineIndicator,
    },

    setup() {
        const withStatusBar = useNativePlugin('StatusBar');

        const { offline } = useNetworkStatus();

        void withStatusBar(async (plugin) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { StatusBar, Style } = plugin;

            await StatusBar.setBackgroundColor({ color: '#F7F7F7' });
            await StatusBar.setStyle({ style: Style.Light });
        });

        return {
            b: useBem(styles),

            offline,
        };
    },
});
