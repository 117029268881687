/* eslint-disable @typescript-eslint/naming-convention */

import { Capacitor } from '@capacitor/core';

import { arrayIncludes } from 'dtg-shared/utils/array';
import { buildEnv } from 'dtg-shared/utils/build-env';
import { identity } from 'dtg-shared/utils/identity';

const nodeEnv = ['development', 'production', 'test'] as const;
const platform = ['web', 'ios', 'android'] as const;

// See apps/mobile/.env and .env.development for explanations
export type ENVType = {
    NODE_ENV: typeof nodeEnv[number];

    BASE_URL: string;

    VUE_APP_PLATFORM: typeof platform[number];

    VUE_APP_DEEP_LINK_URL: string;
    VUE_APP_API_BASE_URL: string;
    VUE_APP_BACKOFFICE_URL: string;
    VUE_APP_GENERATE_REPORT_URL: string;

    VUE_APP_COOKIES_DOMAIN: string;

    VUE_APP_DEFAULT_EMAIL: string;
    VUE_APP_DEFAULT_PASSWORD: string;

    VUE_APP_GOOGLE_MAPS_API_KEY: string;

    VUE_APP_FIREBASE_CONFIG: Record<string, string>;
    VUE_APP_PERSISTENCE_ENABLED: boolean;

    VUE_APP_USE_FIRESTORE_EMULATOR: boolean;
    VUE_APP_FIREBASE_EMULATOR_HOST: string;
    VUE_APP_AUTH_EMULATOR_PORT: number;
    VUE_APP_FIRESTORE_EMULATOR_PORT: number;
    VUE_APP_FUNCTIONS_EMULATOR_PORT: number;
    VUE_APP_STORAGE_EMULATOR_PORT: number;

    VUE_APP_VERSION: string;
    VUE_APP_BUILD_ID: string;

    VUE_APP_VUEX_LOGGER: boolean;

    VUE_APP_SENTRY_ENABLED: boolean;
    VUE_APP_SENTRY_DSN: string;
    VUE_APP_SENTRY_ENV: string;
    VUE_APP_SENTRY_TRACE_SAMPLE_RATE: number;
    VUE_APP_SENTRY_TRACING_ORIGINS: string[];
    VUE_APP_SENTRY_DEBUG: boolean;

    VUE_APP_LOCALHOST: string;
    VUE_APP_ANDROID_LOCALHOST: string;

    VUE_APP_SERVICE_WORKER_ENABLED: boolean;

    VUE_APP_TOKEN_MANAGER_ACCESS_KEY: string;
    VUE_APP_TOKEN_MANAGER_REFRESH_KEY: string;
};

const ENV = buildEnv<ENVType>(process.env, {
    NODE_ENV: (value) => {
        if (!value) {
            return 'development';
        }

        if (!arrayIncludes(nodeEnv, value)) {
            throw new Error(`Must be one of ${nodeEnv.join(', ')}`);
        }

        return value;
    },

    BASE_URL: replaceLocalhost,

    VUE_APP_PLATFORM: (value) => {
        if (!value) {
            switch (Capacitor.getPlatform()) {
                case 'ios':
                    return 'ios';
                case 'android':
                    return 'android';
                default:
                    return 'web';
            }
        }

        if (!arrayIncludes(platform, value)) {
            throw new Error(`Must be one of ${platform.join(', ')}`);
        }

        return value;
    },

    VUE_APP_DEEP_LINK_URL: (value, env) => {
        if (!value && env.VUE_APP_PLATFORM !== 'web') {
            throw new Error('Must not be empty');
        }

        return replaceLocalhost(value, env);
    },
    VUE_APP_API_BASE_URL: replaceLocalhost,
    VUE_APP_BACKOFFICE_URL: replaceLocalhost,
    VUE_APP_GENERATE_REPORT_URL: replaceLocalhost,

    VUE_APP_COOKIES_DOMAIN: replaceLocalhost,

    VUE_APP_DEFAULT_EMAIL: identity,
    VUE_APP_DEFAULT_PASSWORD: identity,

    VUE_APP_GOOGLE_MAPS_API_KEY: identity,

    VUE_APP_FIREBASE_CONFIG: (value) => JSON.parse(value) as Record<string, string>,
    VUE_APP_PERSISTENCE_ENABLED: Boolean,

    VUE_APP_USE_FIRESTORE_EMULATOR: Boolean,
    VUE_APP_FIREBASE_EMULATOR_HOST: replaceLocalhost,
    VUE_APP_AUTH_EMULATOR_PORT: (value) => parseInt(value, 10),
    VUE_APP_FIRESTORE_EMULATOR_PORT: (value) => parseInt(value, 10),
    VUE_APP_FUNCTIONS_EMULATOR_PORT: (value) => parseInt(value, 10),
    VUE_APP_STORAGE_EMULATOR_PORT: (value) => parseInt(value, 10),

    VUE_APP_VERSION: identity,
    VUE_APP_BUILD_ID: identity,

    VUE_APP_VUEX_LOGGER: Boolean,

    VUE_APP_SENTRY_DSN: identity,
    VUE_APP_SENTRY_ENABLED: Boolean,
    VUE_APP_SENTRY_ENV: identity,
    VUE_APP_SENTRY_TRACE_SAMPLE_RATE: (value) => parseFloat(value) || 1,
    VUE_APP_SENTRY_TRACING_ORIGINS: (value) => JSON.parse(value) as string[],
    VUE_APP_SENTRY_DEBUG: Boolean,

    VUE_APP_LOCALHOST: identity,
    VUE_APP_ANDROID_LOCALHOST: identity,

    VUE_APP_SERVICE_WORKER_ENABLED: Boolean,

    VUE_APP_TOKEN_MANAGER_ACCESS_KEY: (value) => value || 'accessToken',
    VUE_APP_TOKEN_MANAGER_REFRESH_KEY: (value) => value || 'refreshToken',
});

if (ENV.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(ENV);
}

function replaceLocalhost(value: string, env: ENVType): string {
    if (env.NODE_ENV === 'production') {
        return value;
    }

    if (env.VUE_APP_ANDROID_LOCALHOST && env.VUE_APP_PLATFORM === 'android') {
        return value.replace(/localhost/g, env.VUE_APP_ANDROID_LOCALHOST);
    }

    if (env.VUE_APP_LOCALHOST) {
        return value.replace(/localhost/g, env.VUE_APP_LOCALHOST);
    }

    return value;
}

export { ENV };
