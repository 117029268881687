import axios, { AxiosInstance } from 'axios';

import { ResultSuccessOf } from 'dtg-shared/types/data';

import { ApiError } from '../Error';

import {
    ApiBaseOptions,
    ApiInternalError,
    ApiRequestConfig,
    ApiResponse,
    ApiResponseError,
    ApiResponseWrapper,
} from './types';

// Wrapper over axios that returns ApiResponse instead of AxiosResponse.
export class ApiBase {
    protected readonly axios: AxiosInstance;
    protected readonly logger: (error: unknown) => void;

    constructor({ logger, axios: axiosInstance }: ApiBaseOptions, config?: ApiRequestConfig) {
        this.axios = axiosInstance ?? axios.create(config);
        this.logger = logger;
    }

    async get<TResponse extends ApiResponse>(url: string, config?: ApiRequestConfig): Promise<TResponse> {
        return this.axios.get<TResponse>(url, config).then((response) => response.data);
    }

    async put<TResponse extends ApiResponse, TData = Record<string, never> | undefined>(
        url: string,
        data?: TData,
        config?: ApiRequestConfig,
    ): Promise<TResponse> {
        return this.axios.put<TResponse>(url, data, config).then((response) => response.data);
    }

    async post<TResponse extends ApiResponse, TData = Record<string, never> | undefined>(
        url: string,
        data?: TData,
        config?: ApiRequestConfig,
    ): Promise<TResponse> {
        return this.axios.post<TResponse>(url, data, config).then((response) => response.data);
    }

    async delete<TResponse extends ApiResponse, TData = Record<string, never> | undefined>(
        url: string,
        data?: TData,
        config?: ApiRequestConfig,
    ): Promise<TResponse> {
        return this.axios.delete<TResponse>(url, { ...config, data }).then((response) => response.data);
    }

    async request<TResponse>(config: ApiRequestConfig): Promise<ApiResponseWrapper<TResponse>> {
        return this.axios.request<TResponse>(config);
    }

    addRequestInterceptor(
        onFulfilled?: (value: ApiRequestConfig) => ApiRequestConfig | Promise<ApiRequestConfig>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRejected?: (error: Error | ApiInternalError) => any,
    ): number {
        return this.axios.interceptors.request.use(onFulfilled, onRejected);
    }

    addResponseInterceptor<TValue = ApiResponse, TReturn = TValue, TError = ApiError>(
        onFulfilled?: (
            value: ApiResponseWrapper<TValue>,
        ) => ApiResponseWrapper<TReturn> | Promise<ApiResponseWrapper<TReturn>>,
        onRejected?: (
            error: Error | TError,
            // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        ) => ApiResponseWrapper<TReturn> | Promise<ApiResponseWrapper<TReturn> | void> | void,
    ): number {
        return this.axios.interceptors.response.use(onFulfilled, onRejected);
    }

    isCancel(error: unknown): boolean {
        return axios.isCancel(error);
    }

    isInternalError(error: unknown): error is ApiInternalError {
        return axios.isAxiosError(error);
    }

    async throwIfErrors<TResult, TError extends ApiResponseError>(
        promise: Promise<ApiResponse<TResult, TError>>,
    ): Promise<ResultSuccessOf<ApiResponse<TResult, TError>>> {
        const response = await promise;

        if (response.ok) {
            return response;
        }

        const { error: errors } = response;
        const [error] = errors;
        const message = error ? error.message : '';

        this.logger(errors);

        throw new ApiError({ message, errors });
    }
}
