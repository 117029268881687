import { ApiResponse, ApiResponseError } from '../../classes/Api';
import { ApiExpertise, ApiOption } from '../../types/data';
import { ApiCompany, ApiCompanyShallow } from '../company';
import { ApiSubscriptionBase } from '../subscription';

export type ApiUserSubscriptionCreate = Pick<ApiSubscriptionBase, 'startDate' | 'endDate'> & { type: string };

export type ApiUser = {
    id: string;
    idRepresentative: string;

    status: ApiUserStatus;
    archived: boolean;
    numberOfDtg: number;

    email?: string;
    firstName: string;
    lastName: string;
    phone?: string;
    phoneFix?: string;
    jobPosition?: string;

    company: ApiCompany;
    userExpertise: ApiExpertise[];
    roles: ApiUserRoleOption[];
    educationLevel?: ApiEducation | null;
    subscription?: ApiSubscriptionBase | null;
};

export type ApiUserShallow = {
    id: string;
    idRepresentative: string;
    company: ApiCompanyShallow;

    status: ApiUserStatus;

    firstName: string;
    lastName: string;
    email?: string;

    archived: boolean;

    numberOfDtg: number;

    createdAt: string;
    updatedAt: string;
};

export enum ApiUserRole {
    admin = 'admin',
    service = 'service',
    networkAdmin = 'network_admin',
    companyAdmin = 'company_admin',
    technician = 'technic',
    validator = 'validator',
}

export enum ApiUserStatus {
    predraft = 'predraft',
    draft = 'draft',
    pending = 'pending',
    active = 'active',
    inactive = 'inactive',
}

export type ApiUserCreate = Required<
    Pick<ApiUser, 'email' | 'firstName' | 'lastName' | 'phone' | 'phoneFix' | 'jobPosition'>
> & {
    roles: string[];
    userExpertise: string[];
    educationLevel: string;
    companyId: string;
    subscription: ApiUserSubscriptionCreate | null;
};

export type ApiUserUpdate = ApiUserCreate & Pick<ApiUser, 'archived'>;

export type ApiUserRoleOption = { id: string; label: ApiUserRole };
export type ApiEducation = ApiOption;

/* Request types */
export type ApiUserCreateRequest = ApiUserCreate;
export type ApiUserCreateResponse = ApiResponse<{ data: { id: string } }, ApiUserCreateUpdateError>;

export type ApiUserUpdateRequest = ApiUserUpdate;
export type ApiUserUpdateResponse = ApiResponse<
    { accessRevoked: boolean; updatedUser: ApiUser },
    ApiUserCreateUpdateError
>;

export type ApiUserListRequest = { activeOnly?: boolean; archived?: boolean };
export type ApiUserListResponse = ApiResponse<{ items: ApiUser[] }>;

export type ApiUserInfoResponse = ApiResponse<{ item: ApiUser }>;

export type ApiUserNotifyRequest = { userId: string };
export type ApiUserNotifyResponse = ApiResponse<{ status: ApiUserStatus }>;

export type ApiUserCreateUpdateError = ApiResponseError<ApiUserCreateUpdateErrorCode>;

export enum ApiUserCreateUpdateErrorCode {
    sameEmail = 'user/email-already-taken',
    companyCannotBeChanged = 'user/cannot-change-company-user-has-dtgs',
    technicianRoleMissing = 'user/technician-role-missing',
    validatorRoleMissing = 'user/validator-role-missing',
    directorRoleMissing = 'user/director-role-missing',
    subscriptionNotAllowed = 'user/subscription-not-allowed',
}
