import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { readonly, ref, watch } from 'vue';

import { Id } from '../../types/data';
import { ReadonlyRef } from '../../types/vue';
import { objFromEntries } from '../../utils/object';

import { FormItem } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useUpdateBuffer<T>({
    item,
    immediateFields = [],
    onUpdate,
    debounce,
}: {
    item: ReadonlyRef<FormItem<T> | null>;
    immediateFields?: Array<keyof T>;
    onUpdate: (payload: Id & { data: Partial<T> }) => void;
    debounce: number;
}) {
    const buffer = new Map<keyof T, T[keyof T]>();
    let bufferItemId: string | null = null;
    let debounceId = -1;

    const isBufferEmpty = ref(true);

    watch(
        item,
        (newValue, oldValue) => {
            if (newValue?.id !== oldValue?.id) {
                if (oldValue) {
                    const { id, data } = oldValue;

                    flushBufferTo(id, data);
                } else if (typeof oldValue !== 'undefined') {
                    clearBuffer();
                }

                bufferItemId = newValue?.id ?? null;
            }
        },
        { immediate: true, flush: 'post' },
    );

    function debounceFlushBuffer(): void {
        window.clearTimeout(debounceId);

        const shouldFlushNow = [...buffer.keys()].some((key) => immediateFields.includes(key));

        if (shouldFlushNow) {
            flushBuffer();

            return;
        }

        debounceId = window.setTimeout(flushBuffer, debounce);
    }

    function flushBuffer(): void {
        if (!item.value) {
            clearBuffer();

            return;
        }

        const { id, data } = item.value;

        flushBufferTo(id, data);
    }

    function getBufferData(): Partial<T> {
        return objFromEntries([...buffer.entries()]) as Partial<T>;
    }

    function flushBufferTo(itemId: string, curData: T): void {
        if (buffer.size === 0) {
            return;
        }

        const dataToCompare = pick(curData, [...buffer.keys()]);

        if (!isEqual(getBufferData(), dataToCompare)) {
            onUpdate({ id: itemId, data: clearBuffer() });
        }
    }

    function clearBuffer(): Partial<T> {
        window.clearTimeout(debounceId);

        const data = getBufferData();

        buffer.clear();

        if (!isBufferEmpty.value) {
            isBufferEmpty.value = true;
        }

        return data;
    }

    function addToBuffer(key: keyof T, value: T[keyof T]): void {
        buffer.set(key, value);

        if (isBufferEmpty.value) {
            isBufferEmpty.value = false;
        }
    }

    function removeFromBuffer(key: keyof T): void {
        buffer.delete(key);

        if (buffer.size === 0) {
            isBufferEmpty.value = true;
        }
    }

    function isInBuffer(key: keyof T): boolean {
        return buffer.has(key) && bufferItemId === item.value?.id;
    }

    return {
        isBufferEmpty: readonly(isBufferEmpty),

        debounceFlushBuffer,
        flushBuffer,
        addToBuffer,
        removeFromBuffer,
        isInBuffer,
        clearBuffer,
    };
}
