import { PdfType } from '../types';

export type PdfOptions = {
    coverPage: boolean;
    toc: boolean;
    timeout?: number;
};

export const PDF_OPTIONS: Record<PdfType, PdfOptions> = {
    [PdfType.trame]: {
        coverPage: true,
        toc: true,
    },
    [PdfType.technique]: {
        coverPage: false,
        toc: false,
    },
    [PdfType.pppt]: {
        coverPage: true,
        toc: true,
    },
    [PdfType.test]: {
        coverPage: true,
        toc: true,
    },
};
