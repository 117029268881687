// TODO: replace with an object/enum/i18n module, use for all localized strings
export const ERROR = 'Erreur';
// TODO: add localization
export const SAVING_ERROR = 'Error while saving DTG';
export const PREFETCH_ERROR = 'Erreur de préchargement';
export const ATTENTION = 'Attention requise\xa0!';
export const PHOTO_UPLOAD_SUCCESS = 'Photo ajoutée';
export const PHOTO_UPDATE_SUCCESS = 'Photo mise à jour';
export const PHOTO_UPLOAD_ERROR = 'Échec du téléchargement de la photo';
export const RESOURCES_CACHED = 'L’application est prête à être utilisée en mode hors ligne';
export const RESOURCES_UPDATED = "'L'application a été mise à jour, merci d’appuyer sur “RAFRAICHIR”";
export const CONTROL_POINT_DUPLICATE_SUCCESS = 'Point de contrôle dupliqué';
export const CONTROL_POINT_PHOTOS_DUPLICATE_ERROR = 'Impossible de dupliquer les photos';
export const ERROR_VALIDATION = 'Erreur de validation';
export const VALIDATION_DTG_INVALID =
    "Certains champs sont manquants, veuillez compléter la saisie avant d'envoyer en validation.";
export const SEND_TO_VALIDATION_IN_OFFLINE =
    "Cette action nécessite une connexion internet, veuillez vous connecter au réseau avant d'envoyer en validation.";
export const RETURN_FROM_VALIDATION_IN_OFFLINE =
    'Cette action nécessite une connexion internet, veuillez vous connecter au réseau avant annuler la mise en validation.';
export const RESOLVE_VALIDATION_IN_OFFLINE =
    'Cette action nécessite une connexion internet, veuillez vous connecter au réseau avant de valider le DTG.';
export const REJECT_VALIDATION_IN_OFFLINE =
    'Cette action nécessite une connexion internet, veuillez vous connecter au réseau avant de rejeter le DTG.';
