import { Option } from 'dtg-shared/types/data';
import { range } from 'dtg-shared/utils/array';

import { BuildingAuditType, BuildingFormData, BuildingType } from './types';

export const BUILDING_AUDIT_TYPE_OPTIONS: Array<Option<BuildingAuditType>> = [
    { id: BuildingAuditType.dpe, value: "DPE à l'immeuble" },
    { id: BuildingAuditType.audit, value: 'Audit énergétique' },
];

export const BUILDING_TYPE_OPTIONS: Array<Option<BuildingType> & { shortValue: string }> = [
    { id: BuildingType.interior, value: 'Batiment', shortValue: 'Bati' },
    { id: BuildingType.exterior, value: 'Extérieur', shortValue: 'Extérieur' },
    { id: BuildingType.basement, value: 'Sous-sol', shortValue: 'Sous-sol' },
];

export const BUILDING_TYPE_ICON_NAMES: Record<BuildingType, string> = {
    [BuildingType.interior]: 'home',
    [BuildingType.exterior]: 'exterior',
    [BuildingType.basement]: 'staircase',
};

export const FIELD_ENABLED_BUILDING_TYPE_MAP: Record<keyof BuildingFormData, BuildingType[] | true> = {
    type: true, // enabled even if no type is selected
    name: true,
    entriesNumber: [BuildingType.interior],
    heatingMode: [BuildingType.interior],
    level: [BuildingType.interior],
    yearConstruction: [BuildingType.interior],
    dateConstruction: [BuildingType.interior],
    hasInteriorElevator: [BuildingType.interior],
    hasBasementElevator: [BuildingType.basement],
    roofType: [BuildingType.interior],
    undergroundLevel: [BuildingType.basement],
    hasAttic: [BuildingType.interior],
    fireSafetyType: [BuildingType.interior],
    hasLocalTrash: [BuildingType.basement],
    hasGarage: [BuildingType.basement],
    hasCellar: [BuildingType.basement],
    hasLocalServices: [BuildingType.basement],
    hasTransformationStation: [BuildingType.basement],
    hasCirculation: [BuildingType.exterior],
    hasExteriorParking: [BuildingType.exterior],
    hasBasementParking: [BuildingType.basement],
    hasOutbuilding: [BuildingType.exterior],
    hasGate: [BuildingType.exterior],
    hasGarden: [BuildingType.exterior],
    hasPool: [BuildingType.exterior],
};

const LEVEL_MAX = 20;

export const LEVEL_OPTIONS: Option[] = [
    ...range(LEVEL_MAX + 1).map((level) => ({ id: String(level), value: `R+${level}` })),
    { id: String(LEVEL_MAX + 1), value: `>R+${LEVEL_MAX}` },
];
