import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useAuth } from '@/composables/use-auth';
import { useDtg } from '@/composables/use-dtg';
import { useNetworkStatus } from '@/composables/use-network-status';
import { useStoreData } from '@/composables/use-store-data';

import { useStoreCollection, useStoreModule } from '@/store';

import { logError } from '@/utils/log-error';
import { SAVING_ERROR } from '@/utils/toast-message';

import { Route } from '@/router';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDtgData({ errorMessage }: { errorMessage?: string } = {}) {
    const {
        actions: { fetchItem, saveItems },
        getters: { getIsItemDirty, getIsFetching, getIsSaving, getIsAvailableOffline },
    } = useStoreCollection('dtg');

    const {
        getters: { canEditDtg },
    } = useStoreModule('rules');

    const router = useRouter();
    const { refreshTokens, wrapWithRefreshRetry } = useAuth();
    const { dtgId, dtg, dtgName } = useDtg();
    const { offline } = useNetworkStatus();

    const { fetchData } = useStoreData({
        load: async (id?: string) => {
            await fetchItem({
                dtgId: id ?? dtgId.value,
                onError: async (error) => {
                    logError(error);

                    await refreshTokens();
                },
            });
        },
        errorMessage,
    });

    const disabled = computed(() => !canEditDtg(dtg.value));
    const loading = computed(() => getIsFetching(dtgId.value));
    const loaded = computed(() => Boolean(dtg.value?.loaded));
    const submitting = computed(() => getIsSaving());
    const availableOffline = computed(() => getIsAvailableOffline(dtgId.value));
    const dirty = computed(() => getIsItemDirty(dtgId.value));
    const unavailable = computed(() => {
        return offline.value && loaded.value && !loading.value && !availableOffline.value;
    });

    async function fetchDtg(id = dtgId.value, redirectOnError = true): Promise<boolean> {
        const success = await fetchData(id);

        if (!success && redirectOnError) {
            void router.push({ name: Route.list });
        }

        return success;
    }

    async function saveDtg(): Promise<void> {
        await wrapWithRefreshRetry(saveItems, SAVING_ERROR);
    }

    return {
        dtgId,
        dtg,
        dtgName,

        disabled,
        loading,
        loaded,
        submitting,
        dirty,
        availableOffline,
        unavailable,

        fetchDtg,
        saveDtg,
    };
}
