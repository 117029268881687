// eslint-disable-next-line complexity
export function stringifyWithFallback(
    value: unknown,
    config: { fallback?: string; yesText?: string; noText?: string } = {},
): string {
    const fallback = config.fallback ?? '-';

    if (value === true) {
        return config.yesText ?? 'Oui';
    }

    if (value === false) {
        return config.noText ?? 'Non';
    }

    if (Array.isArray(value)) {
        return value.length > 0 ? value.join(', ') : fallback;
    }

    if (typeof value === 'number') {
        return Number.isInteger(value) ? value.toString() : value.toFixed(2);
    }

    return value ? String(value) : fallback;
}

export function withFallback(value?: string | null, fallback = '-'): string {
    return value ? value : fallback;
}
