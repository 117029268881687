/**
 * Makes object easier to use in templates.
 *
 * Converts fields from the `keys` array to string. Adds original object as `data` field.
 */

import { stringifyWithFallback } from './stringify-with-fallback';

export type TemplateFriendlyObject<T extends Record<string, unknown>, U extends Array<keyof T>> = {
    data: T;
} & Omit<T, U[number]> &
    Record<U[number], string>;

export function templateFriendlyObject<T extends Record<string, unknown>, U extends Array<keyof T & string>>(
    data: T,
    keys: Readonly<U>,
    fallback?: string,
): TemplateFriendlyObject<T, U> {
    const entries = keys.map((key): [string, string] => {
        const value = data[key];
        const label = stringifyWithFallback(value, { fallback });

        return [key, label];
    });

    const valuesWithFallbacks = Object.fromEntries(entries) as Record<U[number], string>;

    return {
        data,
        ...data,
        ...valuesWithFallbacks,
    };
}
