/* eslint-disable no-restricted-imports */

import { RouteRecordRaw } from 'vue-router';

import DtgPageAbout from '@/components/pages/page-about/component.vue';
import DtgPagePrivacyPolicy from '@/components/pages/page-privacy-policy/component.vue';
import DtgPageTermsOfSales from '@/components/pages/page-terms-of-sales/component.vue';
import DtgPageTermsOfService from '@/components/pages/page-terms-of-service/component.vue';

import { Route } from '../types';

export const aboutRoutes: RouteRecordRaw[] = [
    {
        path: '/about',
        name: Route.about,
        component: DtgPageAbout,
        meta: {
            noAuth: true,
        },
    },
    {
        path: '/privacy-policy',
        name: Route.privacyPolicy,
        component: DtgPagePrivacyPolicy,
        meta: {
            noAuth: true,
        },
    },
    {
        path: '/terms-of-sales',
        name: Route.termsOfSales,
        component: DtgPageTermsOfSales,
        meta: {
            noAuth: true,
        },
    },
    {
        path: '/terms-of-service',
        name: Route.termsOfService,
        component: DtgPageTermsOfService,
        meta: {
            noAuth: true,
        },
    },
];
