import { arrayIncludes } from 'dtg-shared/utils/array';

import { LegalObligationData, LegalObligationListItem, LegalObligationStatus } from './types';

export function getIsObligationShown({
    hidden,
    status,
    files,
}: Pick<LegalObligationListItem, 'hidden'> & Pick<LegalObligationData, 'status' | 'files'>): boolean {
    const isHidden =
        hidden && arrayIncludes([LegalObligationStatus.notApplicable, null], status) && files.length === 0;

    return !isHidden;
}
