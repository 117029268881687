import firebase from 'firebase/compat/app';

/* Upload */
export type Upload = UploadDataWithMeta & {
    task?: firebase.storage.UploadTask;
    eventHandler?: UploadEventHandler;
};

export enum UploadDataType {
    base64 = 'base64',
    base64Url = 'base64Url',
    dataUrl = 'data_url',
    raw = 'raw',
    blob = 'blob',
}

export type UploadDataTypeString =
    | UploadDataType.base64
    | UploadDataType.base64Url
    | UploadDataType.dataUrl
    | UploadDataType.raw;

export type UploadDataTypeBlob = UploadDataType.blob;

export type InferUploadType<T extends UploadDataType> = T extends UploadDataTypeString
    ? string
    : T extends UploadDataTypeBlob
    ? Blob
    : never;

export type UploadDataTypeUnion = string | Blob;

export type UploadData = (UploadString | UploadBlob) & {
    path: string;
};

export type UploadString = {
    data: string;
    type: UploadDataTypeString;
};

export type UploadBlob = {
    data: Blob;
    type: UploadDataTypeBlob;
};

export type UploadDataWithMeta = UploadData & {
    meta: UploadMeta;
};

export type UploadMeta = {
    timeCreated: string;
    name: string;
};

/* Upload reference */
export type UploadReferenceLocal<T extends UploadDataTypeUnion = UploadDataTypeUnion> = UploadReference<
    T,
    UploadReferenceType.local
>;
export type UploadReferenceRemote<T extends UploadDataTypeUnion = UploadDataTypeUnion> = UploadReference<
    T,
    UploadReferenceType.remote
>;

export type UploadReference<
    TData extends UploadDataTypeUnion = UploadDataTypeUnion,
    TReference extends UploadReferenceType = UploadReferenceType,
> = {
    type: TReference;
    getUrl: () => Promise<string>;
    getData: () => Promise<TData>;
    getMetadata: () => Promise<UploadMeta>;
    addEventHandler?: (eventHandler: UploadEventHandler) => void;
};

export type UploadReferenceString = UploadReference<string>;

export type UploadReferenceBlob = UploadReference<Blob>;

export enum UploadReferenceType {
    local = 'local',
    remote = 'remote',
}

/* Upload task */
export type UploadTaskItem = {
    path: string;
    task: firebase.storage.UploadTask;
    eventHandler?: UploadEventHandler;
};

export type UploadEventHandler = (event: UploadEvent) => void;

export enum UploadEventStatus {
    progress = 'progress',
    error = 'error',
    complete = 'complete',
    canceled = 'canceled',
}

export type UploadEvent =
    | {
          status: UploadEventStatus.progress;
          progress: number;
      }
    | {
          status: UploadEventStatus.error;
          error: firebase.storage.FirebaseStorageError;
      }
    | {
          status: UploadEventStatus.complete;
      }
    | {
          status: UploadEventStatus.canceled;
      };
