import { objEntries } from 'dtg-shared/utils/object';
import { truthy } from 'dtg-shared/utils/truthy';

import { COLLECTION_PATH, CollectionType } from './types';

export function pathFromType(type: CollectionType): string {
    return COLLECTION_PATH[type];
}

export function typeFromPath(path: string): CollectionType | null {
    const [collectionType] = objEntries(COLLECTION_PATH).find(([, otherPath]) => otherPath === path) ?? [];

    return collectionType ?? null;
}

export function buildPath(...paths: [...string[], string | null]): string {
    return paths.filter(truthy).join('/');
}
