
import { computed, defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { DtgFormLogin, DtgFormLoginForm, DtgLink, openToast, useModalWeakCredentials } from 'dtg-components';
import { useFormSubmitHandler, Validate } from 'dtg-shared/composables/use-form';
import { ValidationErrors } from 'dtg-shared/validation';

import { DtgLayoutAuth } from '@/components/layouts/layout-auth';

import { useAuth } from '@/composables/use-auth';

import { useStoreModule } from '@/store';

import { ENV } from '@/utils/env';

import { Route } from '@/router';

export default defineComponent({
    name: 'dtg-page-auth-login',

    components: {
        DtgLink,
        DtgFormLogin,
        DtgLayoutAuth,
    },

    setup() {
        const modalWeakCredentials = useModalWeakCredentials();

        const router = useRouter();
        const route = useRoute();

        const {
            actions: { restorePassword },
        } = useStoreModule('auth');

        const form = ref<DtgFormLoginForm>({
            email: ENV.VUE_APP_DEFAULT_EMAIL,
            password: ENV.VUE_APP_DEFAULT_PASSWORD,
        });

        const fieldErrors = ref<ValidationErrors<DtgFormLoginForm>>({});

        const { login } = useAuth();

        const { submitting, submit } = useFormSubmitHandler({
            onSubmit: async () => login({ data: form.value }),
            onSuccess: async () => {
                const { redirect } = route.params;

                if (redirect && route.path !== redirect) {
                    await router.push({ path: String(redirect) });
                } else {
                    await router.push({ name: Route.list });
                }
            },
            onError: (error) => {
                if (!error.weakCredentials) {
                    return;
                }

                modalWeakCredentials.open({
                    onConfirm: async () => {
                        const response = await restorePassword({ email: form.value.email });

                        const message = response.ok
                            ? 'Les instructions ont été envoyées avec succès à votre boîte e-mail'
                            : "Erreur\xa0! MyDTG n'a pas pu envoyer correctement les instructions à votre boîte mail. Veuillez réessayer.";

                        void openToast(message);
                    },
                });
            },
            fieldErrors,
            openToast,
        });

        const passwordResetHref = computed(() => router.resolve({ name: Route.authPasswordReset }));
        const aboutHref = computed(() => router.resolve({ name: Route.about }));

        async function onSubmit(validate: Validate): Promise<void> {
            await submit(validate);
        }

        return {
            form,
            fieldErrors,
            passwordResetHref,
            aboutHref,
            submitting,

            onSubmit,
        };
    },
});
