import { ApiUserCreateUpdateErrorCode, ApiUserRole } from './types';

export const userRoleLabel: Record<ApiUserRole, string> = {
    [ApiUserRole.admin]: 'Super admin',
    [ApiUserRole.networkAdmin]: 'Admin réseau',
    [ApiUserRole.companyAdmin]: 'Administrateur',
    [ApiUserRole.validator]: 'Validateur',
    [ApiUserRole.technician]: 'Technicien',
    [ApiUserRole.service]: 'Service',
};

export const USER_CREATE_UPDATE_ERROR_MESSAGES: Record<ApiUserCreateUpdateErrorCode, string> = {
    [ApiUserCreateUpdateErrorCode.sameEmail]: 'Cette adresse est reliée à un autre utilisateur',
    [ApiUserCreateUpdateErrorCode.companyCannotBeChanged]:
        "La société ne peut pas être modifiée. L'utilisateur est affecté aux DTG.",
    [ApiUserCreateUpdateErrorCode.technicianRoleMissing]:
        'Il manque le rôle "Technicien". L\'utilisateur est affecté aux DTG.',
    [ApiUserCreateUpdateErrorCode.validatorRoleMissing]:
        'Il manque le rôle "Validateur". L\'utilisateur est affecté aux DTG.',
    [ApiUserCreateUpdateErrorCode.directorRoleMissing]:
        'Il manque le rôle "Administrateur". L\'utilisateur est affecté aux DTG.',
    [ApiUserCreateUpdateErrorCode.subscriptionNotAllowed]:
        "Impossible d'associer un abonnement à cet utilisateur. La société à laquelle il appartient a un engagement de type PACK.",
};
