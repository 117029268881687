import { User as AuthUser } from '@firebase/auth';
import { computed, watch } from 'vue';
import { RouteLocationNormalized, RouteLocationRaw, useRouter } from 'vue-router';

import { ApiBase, ApiResponse, ApiResponseWrapper } from 'dtg-api/classes/Api';
import { ApiHandledError } from 'dtg-api/classes/HandledError';
import { ApiAuthParsedAccessToken } from 'dtg-api/modules/auth';

import { useNetworkStatus } from '@/composables/use-network-status';

import { getApi, getTokens } from '@/services/api';
import { reportApiService } from '@/services/report-api';

import { useStoreModule } from '@/store';

import { Route } from '@/router';

import { useAuthLogout } from './use-auth-logout';
import { useAuthRefreshTokens } from './use-auth-refresh-tokens';
import { useAuthRouting } from './use-auth-routing';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuth() {
    const {
        actions: { initFirebaseAuth, login, setUserFromToken, updateTokens },
        getters: { getUser, getFirebaseUser, getIsLoggedIn },
    } = useStoreModule('auth');

    const router = useRouter();
    const api = getApi();
    const reportApi = reportApiService.get();
    const tokens = getTokens();
    const { online } = useNetworkStatus();

    const { redirectToAuth, getAuthRoute, getAccessErrorRedirectRoute } = useAuthRouting();
    const { logoutWithRedirect, logoutWithConfirmation } = useAuthLogout();
    const { refreshTokensAndRetryRequest, refreshTokens, wrapWithRefreshRetry } = useAuthRefreshTokens();

    const user = computed(() => getUser());
    const firebaseUser = computed(() => getFirebaseUser());
    const isLoggedIn = computed(() => getIsLoggedIn());

    // Must be called before anything else to ensure the first navigation is handled
    function initBackofficeAuth(): void {
        watch(
            [tokens.parsedTokenRef, firebaseUser],
            async ([parsedToken, firebaseUser]) => onTokensChange(parsedToken, firebaseUser),
            { immediate: true },
        );

        api.addResponseInterceptor(async (response) => onApiResponse(api, response));
        reportApi.addResponseInterceptor(async (response) => onApiResponse(reportApi, response));
        router.beforeEach(onBeforeRouteChange);
    }

    async function onTokensChange(
        parsedToken: ApiAuthParsedAccessToken | null,
        firebaseUser: AuthUser | null,
    ): Promise<void> {
        if (parsedToken && firebaseUser) {
            await setUserFromToken({ parsedToken });
        } else {
            await setUserFromToken({ parsedToken: null });
        }
    }

    // Adds token refresh and request retry when calling the BO API
    // eslint-disable-next-line complexity
    async function onApiResponse(
        api: ApiBase,
        response: ApiResponseWrapper<ApiResponse>,
    ): Promise<ApiResponseWrapper<unknown>> {
        const { data } = response;

        if (data.ok) {
            return response;
        }

        const retryResult = await refreshTokensAndRetryRequest({
            api,
            errors: data.error,
            requestConfig: response.config,
        });

        switch (retryResult.type) {
            case 'success':
                return retryResult.response;

            case 'authError': {
                const { error, message } = retryResult;
                const routeName = getAccessErrorRedirectRoute(error.code);

                if (routeName === Route.authLogin) {
                    void logoutWithRedirect();
                } else {
                    await router.push({ name: routeName });
                }

                throw new ApiHandledError({ message });
            }

            case 'refreshError': {
                const { message } = retryResult;

                void logoutWithRedirect();

                throw new ApiHandledError({ message });
            }

            case 'otherError':
                return response;
        }
    }

    // eslint-disable-next-line complexity
    async function onBeforeRouteChange(to: RouteLocationNormalized): Promise<RouteLocationRaw | boolean> {
        // Keep the tokens refreshed on route changes since the user
        // might not be accessing the BO API for a long time
        if (tokens.isAccessTokenExpired && online.value) {
            await refreshTokens();
        }

        await initFirebaseAuth();

        const requiresAuth = to.matched.some(({ meta }) => !meta.noAuth && !meta.onlyNoAuth);
        const onlyNoAuth = to.matched.some(({ meta }) => meta.onlyNoAuth);

        switch (true) {
            case requiresAuth && !isLoggedIn.value:
                return getAuthRoute(to) ?? true;

            case onlyNoAuth && isLoggedIn.value:
                return { name: Route.list };

            default:
                return true;
        }
    }

    async function updateTokensInternal(
        accessToken: string | null,
        refreshToken: string | null,
    ): Promise<void> {
        const response = await updateTokens({ accessToken, refreshToken });

        if (!response.ok) {
            return;
        }

        switch (response.result) {
            case 'login':
                await router.push({ name: Route.list });
                break;

            case 'logout':
                await redirectToAuth();
                break;

            case 'noChange':
                break;
        }
    }

    return {
        isLoggedIn,
        user,
        tokens,

        login,
        logout: logoutWithRedirect,
        logoutWithConfirmation,

        refreshTokens,
        wrapWithRefreshRetry,
        updateTokens: updateTokensInternal,

        initBackofficeAuth,
        initFirebaseAuth,
    };
}
