import { boolean, mixed, string } from 'yup';
import { AnySchema } from 'yup/lib/schema';

import { arrayIncludes } from 'dtg-shared/utils/array';
import { objEntries, objFromEntries } from 'dtg-shared/utils/object';
import {
    defineSchema,
    defineSchemaWithConditions,
    makeConditionsOf,
    objectSchema,
    ValidationSchema,
} from 'dtg-shared/validation';

import {
    LegalObligationAdditionalData,
    LegalObligationField,
    LegalObligationFormData,
    LegalObligationStatus,
} from './types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function legalObligationValidationSchema(fields: LegalObligationField[]) {
    const additionalDataValidation = objectSchema(legalObligationAdditionalValidationSchema(fields));
    const when = makeConditionsOf<LegalObligationFormData>();

    return defineSchemaWithConditions<LegalObligationFormData>()(
        {
            status: mixed<LegalObligationStatus>()
                .oneOf(Object.values(LegalObligationStatus))
                .nullable()
                .required(),
            additionalData: objectSchema().defined(),
            comment: string().defined(),
            visibleInReport: boolean().defined(),
            filesVisibleInReport: boolean().defined(),
        },
        {
            additionalData: when({
                key: 'status',
                is: (value): boolean =>
                    arrayIncludes(
                        [LegalObligationStatus.delivered, LegalObligationStatus.notDelivered],
                        value,
                    ),
                then: additionalDataValidation.required().nullable(),
                otherwise: objectSchema().defined().nullable(),
            }),
        },
    );
}

export function legalObligationAdditionalValidationSchema(
    fields: LegalObligationField[],
): ValidationSchema<Partial<LegalObligationAdditionalData>> {
    return defineSchema(
        requiredByFields(
            {
                situation: string().nullable().defined(),
                reference: string().defined(),
                madeBy: string().defined(),
                madeByDate: string().nullable().defined(),
            },
            fields,
        ),
    ) as ValidationSchema<Partial<LegalObligationAdditionalData>>;
}

function requiredByFields<T extends Record<string, AnySchema>>(
    schema: T,
    fields: LegalObligationField[],
): Partial<T> {
    const entries = objEntries(schema)
        .filter(([key]) => fields.some((field) => field.key === key))
        .map(([key, schema]) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const field = fields.find((field) => field.key === key)!;

            const schemaWithLabel = schema.label(field.label);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const updatedSchema = field.required ? schemaWithLabel.required() : schemaWithLabel;

            return [key, updatedSchema] as const;
        });

    return objFromEntries(entries);
}
