import { ContactData, ContactFormData } from './types';

export function buildContactFormData({
    name = '',
    firstname = '',
    society = '',
    function: func = '',
    phone = '',
    email = '',
}: Partial<ContactData> = {}): ContactFormData {
    return {
        name,
        firstname,
        society,
        function: func,
        phone,
        email,
    };
}

export function buildContactItemData({
    createdTimestamp = Date.now(),
    valid = false,
    ...rest
}: Partial<ContactData> = {}): ContactData {
    return {
        ...buildContactFormData(rest),

        createdTimestamp,
        valid,
    };
}
