import { string } from 'yup';

import { defineSchemaOf, ValidationSchema } from 'dtg-shared/validation';

import { ParcelFormData } from './types';

export function parcelValidationSchema(): ValidationSchema<ParcelFormData> {
    return defineSchemaOf<ParcelFormData>()({
        prefix: string().label('Préfixe').defined(),
        number: string().label('Numéro').required(),
        section: string().label('Section').required(),
    });
}
