import { useOverlay } from 'dtg-components';

import DtgModalAppStore from '../component.vue';
import { DtgModalAppStoreListeners, DtgModalAppStoreProps } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useModalAppStore() {
    return useOverlay<DtgModalAppStoreProps, DtgModalAppStoreListeners>({
        component: DtgModalAppStore,
    });
}
