
import { defineComponent } from 'vue';

import { getName, useBem } from 'dtg-components';

import { DtgLayoutBase } from '@/components/layouts/layout-base';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgLayoutBase,
    },

    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            b: useBem(styles),
        };
    },
});
