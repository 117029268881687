/* eslint-disable no-console */

import { register } from 'register-service-worker';

export function initServiceWorker(baseUrl: string): void {
    const scriptPath = `${baseUrl}service-worker.js`;

    register(scriptPath, {
        ready() {
            console.log('App is being served from cache by a service worker.');
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');

            window.dispatchEvent(new Event('service-worker-cached'));
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');

            window.dispatchEvent(new Event('service-worker-updated'));

            // Set this in case the listener for the event above hasn't been registered yet
            window.serviceWorkerUpdated = true;

            const { waiting } = registration;

            if (waiting) {
                waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.warn(error);

            window.dispatchEvent(new CustomEvent('service-worker-error', { detail: error }));
        },
    });
}
