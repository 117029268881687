import { BasicItem, Id } from 'dtg-shared/types/data';
import { formatFreeDate } from 'dtg-shared/utils/date';
import { objPick } from 'dtg-shared/utils/object';
import { stringifyWithFallback, withFallback } from 'dtg-shared/utils/stringify-with-fallback';
import { templateFriendlyObject } from 'dtg-shared/utils/template-friendly-object';
import { truthy } from 'dtg-shared/utils/truthy';

import { ConstructionDateListItem } from '../lists';

import { BUILDING_TYPE_OPTIONS } from './consts';
import {
    basementBuildingFormDataKeys,
    BuildingAuditType,
    BuildingData,
    BuildingFormData,
    buildingFormDataKeys,
    BuildingFormEnergyBalanceData,
    buildingFormEnergyBalanceDataKeys,
    BuildingViewItem,
    exteriorBuildingFormDataKeys,
} from './types';

export function buildBuildingFormData(): BuildingFormData {
    return objPick(buildBuildingData(), [
        ...buildingFormDataKeys,
        ...exteriorBuildingFormDataKeys,
        ...basementBuildingFormDataKeys,
    ]);
}

export function buildBuildingFormEnergyBalanceData(): BuildingFormEnergyBalanceData {
    return objPick(buildBuildingData(), buildingFormEnergyBalanceDataKeys);
}

export function buildBuildingData({
    name = '',
    heatingMode = null,
    level = '',
    undergroundLevel = '',
    entriesNumber = null,
    type = null,
    yearConstruction = null,
    dateConstruction = null,
    hasInteriorElevator = null,
    hasBasementElevator = null,
    roofType = null,
    hasAttic = null,
    fireSafetyType = null,
    hasLocalTrash = null,
    hasGarage = null,
    hasCellar = null,
    hasLocalServices = null,
    hasTransformationStation = null,
    hasCirculation = null,
    hasExteriorParking = null,
    hasBasementParking = null,
    hasOutbuilding = null,
    hasGate = null,
    hasGarden = null,
    hasPool = null,

    wasAuditPerformed = null,
    auditType = null,
    auditFile = null,
    auditProvidedBy = '',
    auditAttendant = '',
    auditDate = null,

    energyConsumption = null,
    energyConsumptionClass = null,
    gasEmission = null,
    gasEmissionClass = null,

    treatedFamilies = [],

    createdTimestamp = Date.now(),
    valid = false,
    validPartially = { building: false, energyBalance: true },
}: Partial<BuildingData> = {}): BuildingData {
    return {
        name,
        heatingMode,
        level,
        undergroundLevel,
        entriesNumber,
        type,
        yearConstruction,
        dateConstruction,
        hasInteriorElevator,
        hasBasementElevator,
        roofType,
        fireSafetyType,
        hasAttic,
        hasLocalTrash,
        hasGarage,
        hasCellar,
        hasLocalServices,
        hasTransformationStation,
        hasCirculation,
        hasExteriorParking,
        hasBasementParking,
        hasOutbuilding,
        hasGate,
        hasGarden,
        hasPool,

        wasAuditPerformed,
        auditType,
        auditFile,
        auditProvidedBy,
        auditAttendant,
        auditDate,
        energyConsumption,
        energyConsumptionClass,
        gasEmission,
        gasEmissionClass,

        treatedFamilies,

        createdTimestamp,
        valid,
        validPartially,
    };
}

export function buildBuildingNamesMap(
    buildings: Array<Pick<BuildingData, 'type' | 'name'> & Id>,
    options = BUILDING_TYPE_OPTIONS,
): Record<string, string> {
    return options.reduce((titleMap: Record<string, string>, buildingType) => {
        const filteredBuildings = buildings.filter(({ type }) => type === buildingType.id);
        const onlyOneBuilding = filteredBuildings.length === 1;

        filteredBuildings.forEach((building, index) => {
            const buildingNumber = onlyOneBuilding ? '' : `#${index + 1}`;
            const genericName = [buildingNumber, buildingType.shortValue].filter(truthy).join(' ');

            titleMap[building.id] = [building.name, genericName].filter(truthy).join(' - ');
        });

        return titleMap;
    }, {});
}

export function buildBuildingViewItem(
    item: BuildingData & Id,
    {
        roofTypes,
        constructionDates,
        namesMap,
        formatDate,
        fireSafetyTypes,
    }: {
        roofTypes: BasicItem[];
        constructionDates: ConstructionDateListItem[];
        namesMap: Record<string, string>;
        formatDate: (str: string | null) => string;
        fireSafetyTypes: BasicItem[];
    },
): BuildingViewItem {
    const typeItem = BUILDING_TYPE_OPTIONS.find(({ id }) => id === item.type) ?? null;
    const roofTypeItem = roofTypes.find(({ id }) => id === item.roofType) ?? null;
    const fireSafetyTypeItem = fireSafetyTypes.find(({ id }) => id === item.fireSafetyType) ?? null;

    const name = stringifyWithFallback(namesMap[item.id]);
    const type = stringifyWithFallback(typeItem?.value);
    const roofType = stringifyWithFallback(roofTypeItem?.value);
    const fireSafetyType = stringifyWithFallback(fireSafetyTypeItem?.value);
    const dateConstruction = formatBuildingDateConstruction(item, constructionDates);
    const auditDate = formatDate(item.auditDate);
    const auditTypeLabel = getBuildingAuditTypeLabel(item);

    return {
        ...templateFriendlyObject(item, [
            'entriesNumber',
            'heatingMode',
            'dateConstruction',
            'auditProvidedBy',
            'auditAttendant',
            'undergroundLevel',
            'hasInteriorElevator',
            'hasLocalTrash',
            'hasGarage',
            'hasCellar',
            'hasLocalServices',
            'hasTransformationStation',
            'hasCirculation',
            'hasExteriorParking',
            'hasBasementParking',
            'hasOutbuilding',
            'hasGate',
            'hasGarden',
            'hasPool',
            'hasBasementElevator',
            'level',
            'hasAttic',
        ]),
        auditDate,
        name,
        dateConstruction,
        typeValue: type,
        roofType,
        auditTypeLabel,
        fireSafetyType,
    };
}

function getBuildingAuditTypeLabel(building: BuildingData): string {
    switch (building.auditType) {
        case BuildingAuditType.audit:
            return 'Audit énergétique';

        case BuildingAuditType.dpe:
            return "DPE à l'immeuble";

        default:
            return withFallback();
    }
}

function formatBuildingDateConstruction(
    item: BuildingData,
    constructionDates: ConstructionDateListItem[],
): string {
    const { yearConstruction, dateConstruction } = item;

    const yearConstructionItem = constructionDates.find(({ id }) => id === yearConstruction);
    const yearConstructionText = yearConstructionItem?.value;
    const dateConstructionText = formatFreeDate(dateConstruction);

    // In case there's a specific date and the NA option is selected for yearConstruction
    // (indicated by the lack of a dateRange) we just render the specific date
    if (yearConstructionText && (!dateConstruction || yearConstructionItem.dateRange)) {
        const dateConstructionFormatted = dateConstructionText && `(${dateConstructionText})`;

        return [yearConstructionText, dateConstructionFormatted].filter(truthy).join(' ');
    }

    return stringifyWithFallback(dateConstructionText);
}
