import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useField, useForm } from 'vee-validate';
import { nextTick, onMounted, unref, watch, watchEffect } from 'vue';
import { SchemaDescription } from 'yup/lib/schema';

import { KeyOf, KeysOf } from '../../types/utils';
import { ReadonlyMaybeRef, ReadonlyRef } from '../../types/vue';
import { arrayify } from '../../utils/array';
import { doubleRAF, raf } from '../../utils/double-raf';
import { generateId } from '../../utils/generate-id';
import { getElementFromRef } from '../../utils/get-element-from-ref';
import { objEntries, objFromEntries, objKeys } from '../../utils/object';
import { scrollIntoView } from '../../utils/scroll';
import { truthy } from '../../utils/truthy';
import {
    ValidationErrors,
    ValidationSchema,
    ValidationSchemaWithConditions,
    VCAnyConfig,
} from '../../validation';

import { FieldDisabled, FormField, FormFields, Validate } from './types';
import { useFormValidationSchema } from './use-form-validation-schema';
import { extendField } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFormShared<T extends Record<string, unknown>, U extends T>({
    fieldErrors,
    validationSchema,
    initialData,
    disabled,
    fieldsDisabled,
    shouldFocusInvalid = true,
}: {
    fieldErrors: ReadonlyRef<ValidationErrors<T>>;
    validationSchema: ReadonlyMaybeRef<ValidationSchemaWithConditions<U> | ValidationSchema<U>>;
    initialData: T;
    disabled?: FieldDisabled<T>;
    fieldsDisabled?: FieldDisabled<T>;
    shouldFocusInvalid?: ReadonlyMaybeRef<boolean>;
}) {
    const dataKeys = objKeys(initialData) as KeysOf<T>;
    const formUuid = generateId();

    const {
        schema,
        conditions,
        description: schemaDescription,
    } = useFormValidationSchema({ schema: validationSchema });

    const form = useForm({
        validationSchema: schema,
        initialValues: cloneDeep(initialData),
    });

    const fieldsArray: Array<[keyof T, FormField]> = dataKeys.map((key) => {
        const fieldDescription = schemaDescription.value[key];
        const { label } = fieldDescription;
        const value = form.values[key];

        // eslint-disable-next-line no-undefined,@typescript-eslint/no-unnecessary-type-arguments
        const field = useField<unknown>(key, undefined, { label, initialValue: value });

        return [key, extendField(field, { uuid: formUuid, disabled, fieldsDisabled })];
    });

    const fields = Object.fromEntries(fieldsArray) as FormFields<T>;

    fieldsArray.forEach(([key, field]) => {
        watchEffect(() => {
            const fieldDescription = schemaDescription.value[key];
            const { required } = field.fieldProps;

            required.value = isRequired(
                fieldDescription,
                arrayify(conditions.value[key]).map(({ condition }) => condition),
            );
        });

        // VeeValidate doesn't always trigger validation on conditional schemas
        // So we have to do it manually
        let unwatchField: (() => void) | null = null;

        watch(
            () => conditions.value[key],
            (fieldConditions) => {
                if (unwatchField) {
                    unwatchField();
                    unwatchField = null;
                }

                if (!fieldConditions) {
                    return;
                }

                const keys = arrayify(fieldConditions).flatMap(({ condition }) => {
                    return 'key' in condition ? [condition.key] : condition.keys;
                });
                const valueRefs = keys.map((key) => fields[key].value);

                unwatchField = watch(valueRefs, () => {
                    void field.validate();
                });
            },
            { immediate: true },
        );
    });

    // eslint-disable-next-line func-style
    const validate: Validate = async function validate({ focus = true, silent = false } = {}) {
        const { valid, errors } = await form.validate();

        // Errors can be nested, i.e. field.subField or field[0]
        // This is usually a mistake in code because we don't validate objects/arrays deeply
        // and use nested forms if a single property contains multiple fields
        // However, we can still try to bind the error back to its field by taking its first part
        const unboundErrors = objEntries(errors as Record<string, string>).filter(
            ([key]) => !dataKeys.includes(key),
        );

        if (unboundErrors.length > 0) {
            // eslint-disable-next-line no-console
            console.warn('Found unbound errors', unboundErrors);
        }

        const reboundErrors = unboundErrors
            .map(([nestedKey, error]) => {
                const [key] = /^[^.[]+/.exec(nestedKey) ?? [];

                return [key ?? '', error] as const;
            })
            .filter((entry): entry is readonly [KeyOf<T>, string] => dataKeys.includes(entry[0]));

        reboundErrors.forEach(([key, error]) => {
            const field = fields[key];

            field.setErrors([...field.errors.value, error]);
        });

        if (!silent) {
            setTouched(true);
        }

        if (focus) {
            void focusFirstInvalidDelayed();
        }

        return {
            valid,
            errors: errors as Record<string, string>,
        };
    };

    function setTouched(value: boolean): void {
        const touchedEntries = dataKeys.map((key): [keyof T, boolean] => [key, value]);
        const touched = objFromEntries(touchedEntries);

        form.setTouched(touched);
    }

    async function setErrors(errors: ValidationErrors<T>): Promise<void> {
        const shouldFocus = unref(shouldFocusInvalid);
        const { activeElement } = document;

        // When we focus an invalid field, another field can be in focus, which will be blurred
        // Blurring causes a revalidation, which can clear errors that we're about to set
        if (shouldFocus && activeElement) {
            (activeElement as Partial<HTMLElement>).blur?.();
        }

        await nextTick();
        await doubleRAF();

        const touched = objFromEntries(objEntries(errors).map(([key]) => [key, true]));

        form.setErrors(errors);
        form.setTouched(touched);

        if (shouldFocus) {
            focusFirstInvalid();
        }
    }

    async function focusFirstInvalidDelayed(): Promise<void> {
        await nextTick();
        await raf();

        focusFirstInvalid();
    }

    // eslint-disable-next-line complexity
    function focusFirstInvalid(): void {
        const invalidFields = fieldsArray.filter(([, field]) => !field.meta.valid).map(([, field]) => field);

        if (invalidFields.length === 0) {
            return;
        }

        const fieldEls = invalidFields.map((field) => {
            const componentInstance = field.ref.value;
            const el = getElementFromRef(componentInstance);

            return {
                // Cast wide to be able to check for focus method easier
                componentInstance: componentInstance as Record<string, unknown> | null,
                el,
            } as const;
        });

        // Find topmost leftmost element
        const fieldElEntriesSorted = fieldEls.sort(({ el: a }, { el: b }) => {
            if (!a || !b) {
                // eslint-disable-next-line no-nested-ternary
                return a ? -1 : b ? 1 : 0;
            }

            const rectA = a.getBoundingClientRect();
            const rectB = b.getBoundingClientRect();

            return rectA.top - rectB.top || rectA.left - rectB.left;
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { el, componentInstance } = fieldElEntriesSorted[0]!;

        if (!componentInstance) {
            return;
        }

        if (typeof componentInstance.focus === 'function') {
            componentInstance.focus();
        } else if (el) {
            // If there's no focus method defined on the component instance,
            // find the element to which the field props are bound and try to focus it instead
            const selector = getInvalidSelector();
            const elToFocus = el.matches(selector) ? el : el.querySelector<HTMLElement>(selector);

            if (elToFocus) {
                elToFocus.focus();
            }
        }

        if (el) {
            void scrollIntoView(el);
        }
    }

    function getInvalidSelector(): string {
        return `[data-form-uuid="${formUuid}"][data-invalid]`;
    }

    // Sets invalid fields touched, only if they don't have the default value
    function setInvalidTouched(): void {
        const touchedEntries = fieldsArray.map(([key, { value, meta }]): [keyof T, boolean] => {
            const touched = !meta.valid && !isEqual(value.value, initialData[key]);

            return [key, touched];
        });

        form.setTouched(objFromEntries(touchedEntries));
    }

    /* Field rule checks */
    function hasRuleRequired(description: SchemaDescription | undefined): boolean {
        return hasRule(description, 'required');
    }

    function hasRule(description: SchemaDescription | undefined, name: string): boolean {
        return truthy(description) && description.tests.some((rule) => rule.name === name);
    }

    function isRequired(description: SchemaDescription, fieldConditions: Array<VCAnyConfig<T>>): boolean {
        const isRequiredUnconditionally = hasRuleRequired(description);

        if (isRequiredUnconditionally || fieldConditions.length === 0) {
            return isRequiredUnconditionally;
        }

        return fieldConditions.some((condition) => {
            const isConditionMet = checkCondition(condition);
            const conditionalSchema = isConditionMet ? condition.then : condition.otherwise;

            return hasRuleRequired(conditionalSchema?.describe() as SchemaDescription | undefined);
        });
    }

    function checkCondition(condition: VCAnyConfig<T>): boolean {
        if ('key' in condition) {
            return condition.is(fields[condition.key].value.value);
        }

        const values = condition.keys.map((key) => fields[key].value.value);

        return condition.is(...values);
    }

    watch(fieldErrors, async (value) => {
        if (!isEmpty(value)) {
            await setErrors(value);
        }
    });

    onMounted(async () => {
        await raf();

        if (!isEmpty(fieldErrors.value)) {
            await setErrors(fieldErrors.value);
        }
    });

    return {
        form,
        formUuid,
        fieldsArray,
        fields,

        focusFirstInvalid: focusFirstInvalidDelayed,
        setErrors,
        setTouched,
        validate,
        setInvalidTouched,
    };
}
