import cloneDeep from 'lodash/cloneDeep';
import { computed } from 'vue';

import { ReadonlyRef } from '../../types/vue';
import { isEqualIgnoringOrder } from '../../utils/is-equal-ignoring-order';
import { normalizeString } from '../../utils/string';

import { FilterGeneric, Filters, FilterSpecific } from './types';

export function useFilters<T extends Record<string, unknown>, U extends Partial<Record<keyof T, unknown>>>({
    items,
    queries,
    defaultQueries = {},
    filters = {},
}: {
    items: ReadonlyRef<T[]>;
    queries: U;
    defaultQueries?: Partial<U>;
    filters?: Partial<Filters<T, U>>;
}): {
    filteredItems: ReadonlyRef<T[]>;
    filtersApplied: ReadonlyRef<boolean>;
    queries: typeof queries;

    resetQueries: () => void;
} {
    const emptyQueries = cloneDeep(queries);
    const emptyQueriesWithDefaults = { ...emptyQueries, ...defaultQueries };

    Object.assign(queries, defaultQueries);

    const filteredItems = computed(() => {
        return items.value.filter((item) => {
            return Object.entries(queries).every(([key, query]) => {
                return matchesQuery(item, key, query as U[typeof key]);
            });
        });
    });

    const filtersApplied = computed(() => {
        return Object.entries(emptyQueriesWithDefaults).some(([key, defaultQuery]) => {
            return !isEqualIgnoringOrder(queries[key], defaultQuery);
        });
    });

    function matchesQuery<K extends (keyof U & keyof T) | keyof T>(
        item: T,
        key: K,
        query: U[typeof key],
    ): boolean {
        // In case the query is the same as the empty query, but not the same as the default query,
        // we skip the match check
        if (
            isEqualIgnoringOrder(query, emptyQueries[key]) &&
            !(key in defaultQueries && isEqualIgnoringOrder(emptyQueries[key], defaultQueries[key]))
        ) {
            return true;
        }

        return key in item ? matchesQuerySpecific(item, key, query) : matchesQueryGeneric(item, key, query);
    }

    function matchesQuerySpecific<K extends keyof T & keyof U>(item: T, key: K, query: U[K]): boolean {
        const filter = (filters[key] as FilterSpecific<T, U, K> | undefined) ?? defaultFilter;
        const value = item[key];

        return filter(value, query, item);
    }

    function matchesQueryGeneric<K extends keyof U>(item: T, key: K, query: U[K]): boolean {
        const filter = filters[key] as FilterGeneric<T, U, K> | undefined;

        return filter ? filter(item, query) : true;
    }

    function defaultFilter(value: unknown, query: unknown): boolean {
        if (Array.isArray(query)) {
            return defaultArrayFilter(query, value, true);
        }

        if (Array.isArray(value)) {
            return defaultArrayFilter(value, query, false);
        }

        return defaultPrimitiveFilter(value, query);
    }

    function defaultArrayFilter(a: unknown[], b: unknown, reverseComparison: boolean): boolean {
        return a.some((aPart) => {
            if (Array.isArray(b)) {
                return b.some((bPart) => {
                    const values: [unknown, unknown] = reverseComparison ? [bPart, aPart] : [aPart, bPart];

                    return defaultPrimitiveFilter(...values);
                });
            }

            const values: [unknown, unknown] = reverseComparison ? [b, aPart] : [aPart, b];

            return defaultPrimitiveFilter(...values);
        });
    }

    function defaultPrimitiveFilter(value: unknown, query: unknown): boolean {
        if (typesMatch(value, query, ['number', 'boolean'])) {
            return value === query;
        }

        const normalizedValue = normalizeString(String(value));
        const normalizedQuery = normalizeString(String(query));

        return normalizedValue.includes(normalizedQuery);
    }

    function typesMatch(a: unknown, b: unknown, allowedTypes: string[]): boolean {
        return typeof a === typeof b && allowedTypes.includes(typeof a);
    }

    function resetQueries(): void {
        Object.assign(queries, cloneDeep(emptyQueriesWithDefaults));
    }

    return {
        filteredItems,
        filtersApplied,
        queries,

        resetQueries,
    };
}
