import { AttendantData, AttendantFormData } from './types';

export function buildAttendantItemData({
    firstname = '',
    position = '',
    name = '',
    phoneNumber = '',
    createdTimestamp = Date.now(),
    valid = false,
}: Partial<AttendantData> = {}): AttendantData {
    return {
        firstname,
        position,
        name,
        phoneNumber,

        createdTimestamp,
        valid,
    };
}

export function buildAttendantFormData({
    firstname = '',
    position = '',
    name = '',
    phoneNumber = '',
}: Partial<AttendantData> = {}): AttendantFormData {
    return {
        firstname,
        position,
        name,
        phoneNumber,
    };
}
