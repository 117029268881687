
import { computed, defineComponent } from 'vue';

import { DtgCard, DtgCardTitle } from 'dtg-components';

import { DtgLayoutAbout } from '@/components/layouts/layout-about';

import { ENV } from '@/utils/env';

export default defineComponent({
    name: 'dtg-page-about',

    components: {
        DtgCard,
        DtgCardTitle,
        DtgLayoutAbout,
    },

    setup() {
        const version = computed(() => ENV.VUE_APP_VERSION);

        return { version };
    },
});
