import firebase from 'firebase/compat/app';

import { KeyValueStorage } from '@/services/key-value-storage';

import { FirebaseUploadReferences } from './FirebaseUploadReferences';
import { FirebaseUploadTasks } from './FirebaseUploadTasks';
import {
    UploadData,
    UploadDataType,
    UploadDataTypeString,
    UploadEventHandler,
    UploadReferenceBlob,
    UploadReferenceString,
} from './types';

export class FirebaseStorage {
    private readonly storage: firebase.storage.Reference;
    private readonly references: FirebaseUploadReferences;
    private readonly uploads: FirebaseUploadTasks;

    constructor(storage: firebase.storage.Reference, persistentStorage: KeyValueStorage) {
        this.storage = storage;
        this.uploads = new FirebaseUploadTasks(persistentStorage);
        this.references = new FirebaseUploadReferences(storage, this.uploads);
    }

    async initialize(): Promise<void> {
        await this.uploads.initialize();
    }

    /* Downloading */

    async downloadUrl({
        path,
        type = UploadDataType.dataUrl,
    }: {
        path: string;
        type?: UploadDataType;
    }): Promise<string> {
        return this.references.getReference({ path, type }).getUrl();
    }

    async downloadString({
        path,
        type = UploadDataType.dataUrl,
    }: {
        path: string;
        type?: UploadDataTypeString;
    }): Promise<string> {
        return this.references.getReference({ path, type }).getData();
    }

    async downloadBlob({ path }: { path: string }): Promise<Blob> {
        return this.references.getReference({ path, type: UploadDataType.blob }).getData();
    }

    /* Uploading */

    async upload(payload: UploadData & { eventHandler?: UploadEventHandler }): Promise<void> {
        if (payload.type === UploadDataType.blob) {
            await this.uploadBlob(payload);
        } else {
            await this.uploadString(payload);
        }
    }

    async uploadBlob({
        path,
        data,
        eventHandler,
    }: {
        path: string;
        data: Blob;
        eventHandler?: UploadEventHandler;
    }): Promise<void> {
        const upload = {
            path,
            data,
            type: UploadDataType.blob as const,
        };

        const task = this.storage.child(path).put(data);

        await this.uploads.addUpload({ path, upload, task, eventHandler });
    }

    async uploadString({
        path,
        data,
        type = UploadDataType.dataUrl,
        eventHandler,
    }: {
        path: string;
        data: string;
        type?: UploadDataTypeString;
        eventHandler?: UploadEventHandler;
    }): Promise<void> {
        const upload = {
            path,
            data,
            type,
        };

        const task = this.storage.child(path).putString(data, type);

        await this.uploads.addUpload({ path, upload, task, eventHandler });
    }

    async cancelUpload({ path }: { path: string }): Promise<void> {
        await this.uploads.cancelUpload({ path });
    }

    /* References */

    getStringReference({
        path,
        type = UploadDataType.dataUrl,
    }: {
        path: string;
        type?: UploadDataTypeString;
    }): UploadReferenceString {
        return this.references.getReference({ path, type });
    }

    getBlobReference({ path }: { path: string }): UploadReferenceBlob {
        return this.references.getReference({ path, type: UploadDataType.blob });
    }

    /* Pending uploads */

    pauseUploads(): void {
        this.uploads.pauseUploads();
    }

    async resumeUploads(): Promise<void> {
        await Promise.all(
            this.uploads.getPendingUploads().map(async (pendingUpload) => {
                return this.upload(pendingUpload);
            }),
        );
    }

    get hasPendingUploads(): boolean {
        return this.uploads.getPendingUploads().length > 0;
    }
}
