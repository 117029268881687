
import { computed, defineComponent, PropType } from 'vue';

import {
    DtgCol,
    DtgField,
    DtgGrid,
    DtgRadioGroup,
    DtgRow,
    DtgSelect,
    getName,
    propInternal,
    useBem,
    useBp,
} from 'dtg-components';
import { DtgData } from 'dtg-firebase/modules/dtg';
import { SortableListDirection } from 'dtg-shared/composables/use-sortable-list';
import { Option } from 'dtg-shared/types/data';

import { DtgSortControl } from '../sort-control';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgGrid,
        DtgRow,
        DtgCol,
        DtgSelect,
        DtgField,
        DtgRadioGroup,
        DtgSortControl,
    },

    props: {
        sortKey: {
            type: String,
            required: true,
        },

        sortDirection: {
            type: String as PropType<SortableListDirection>,
            required: true,
        },
    },

    emits: ['sortClick', 'update:sortDirection', 'update:sortKey'],

    setup(props, { emit }) {
        const bp = useBp();

        const sortKeyInternal = propInternal(props, 'sortKey', emit);
        const sortDirectionInternal = propInternal(props, 'sortDirection', emit);

        const sortOptions = computed<Array<{ id: keyof DtgData; value: string }>>(() => {
            return [
                { id: 'name', value: 'Nom dossier' },
                {
                    id: 'visitDate',
                    value: 'Date\nde\u00A0visite',
                },
                {
                    id: 'endDate',
                    value: 'Date de\u00A0rendu attendu',
                },
                {
                    id: 'status',
                    value: 'Statut',
                },
            ];
        });

        const sortDirections: Array<Option<SortableListDirection>> = [
            { id: 'asc', value: 'Ascendant' },
            { id: 'desc', value: 'Descendant' },
        ];

        const isWideScreen = computed(() => {
            return bp.from(bp.lg);
        });

        function onSortClick(key: keyof DtgData): void {
            emit('sortClick', key);
        }

        function getFieldSortDirection(key: keyof DtgData): SortableListDirection | undefined {
            const { sortDirection, sortKey } = props;

            // eslint-disable-next-line no-undefined
            return sortKey === key ? sortDirection : undefined;
        }

        return {
            b: useBem(styles),

            sortKeyInternal,
            isWideScreen,
            sortOptions,
            sortDirections,
            sortDirectionInternal,

            onSortClick,
            getFieldSortDirection,
        };
    },
});
