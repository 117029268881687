import { useRoute, useRouter } from 'vue-router';

import { arrayIncludes } from 'dtg-shared/utils/array';

import { useNativePlugin } from '@/composables/use-native-plugins';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

import { Route } from '@/router';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitNativeApp() {
    const router = useRouter();
    const route = useRoute();

    const withNativeApp = useNativePlugin('App');

    async function initNativeApp(): Promise<void> {
        if (ENV.VUE_APP_PLATFORM === 'web') {
            return;
        }

        await withNativeApp(async (plugin) => {
            await plugin.App.addListener('backButton', () => {
                const isFirstScreen = arrayIncludes([Route.authLogin, Route.list], route.name);

                if (isFirstScreen) {
                    void plugin.App.exitApp();
                } else {
                    router.back();
                }
            });

            await plugin.App.addListener('appUrlOpen', (event) => {
                try {
                    const { pathname } = new window.URL(event.url);

                    if (pathname) {
                        void router.push({ path: pathname });
                    }
                } catch (error) {
                    logError(error);
                }
            });
        });
    }

    return {
        initNativeApp,
    };
}
