
import { defineComponent, PropType } from 'vue';

import {
    DtgAction,
    DtgIcon,
    DtgIconName,
    DtgMark,
    DtgSpinner,
    getName,
    inRecord,
    useBem,
} from 'dtg-components';

import { DtgSidebarView } from '../../types';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgAction,
        DtgIcon,
        DtgMark,
        DtgSpinner,
    },

    props: {
        view: {
            type: String as PropType<DtgSidebarView>,
            required: true,
            validator: inRecord<DtgSidebarView>(DtgSidebarView),
        },

        href: {
            type: [String, Object] as PropType<string | { href: string } | null>,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        valid: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            required: true,
        },

        icon: {
            type: String as PropType<DtgIconName>,
            required: true,
        },
    },

    setup() {
        const b = useBem(styles);

        return {
            b,
        };
    },
});
