import firebase from 'firebase/compat/app';

import { fetchBlobAs } from 'dtg-shared/utils/fetch-blob-as';
import { BlobFormat } from 'dtg-shared/utils/read-blob';

import {
    InferUploadType,
    UploadDataType,
    UploadMeta,
    UploadReferenceRemote,
    UploadReferenceType,
} from './types';

export class FirebaseUploadReferenceRemote<T extends UploadDataType>
    implements UploadReferenceRemote<InferUploadType<T>>
{
    readonly type = UploadReferenceType.remote;

    private readonly dataType: T;
    private readonly reference: firebase.storage.Reference;

    constructor(dataType: T, reference: firebase.storage.Reference) {
        this.dataType = dataType;
        this.reference = reference;
    }

    async getUrl(): Promise<string> {
        return this.reference.getDownloadURL();
    }

    async getData(): Promise<InferUploadType<T>> {
        const downloadUrl = await this.reference.getDownloadURL();
        const format = this.convertUploadTypeToBlobFormat(this.dataType);

        return fetchBlobAs(downloadUrl, format) as Promise<InferUploadType<T>>;
    }

    async getMetadata(): Promise<UploadMeta> {
        return this.reference.getMetadata().then(({ timeCreated, name }) => {
            return { timeCreated, name };
        });
    }

    private convertUploadTypeToBlobFormat(type: UploadDataType): BlobFormat {
        switch (type) {
            case UploadDataType.blob:
                return BlobFormat.blob;

            case UploadDataType.dataUrl:
                return BlobFormat.dataUrl;

            // TODO: convert `base64`, `base64Url` and `raw` formats or remove them
            default:
                return BlobFormat.text;
        }
    }
}
