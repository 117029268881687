import axios from 'axios';

import { addErrorMessageLocalization } from '../../classes/Api';

import {
    BatiChiffrageApiListResponse,
    BatiChiffrageApiQuoteRequest,
    BatiChiffrageApiResetResponse,
    BatiChiffrageConfig,
    BatiChiffrageQuote,
} from './types';

export class BatiChiffrageApi {
    private readonly config;
    private readonly api;
    private readonly logger;
    private readonly routes = {
        quote: 'recup_donnees.php',
    };

    constructor(config: BatiChiffrageConfig, logger: (error: unknown) => void) {
        this.config = config;
        this.api = axios.create({ baseURL: config.apiUrl });
        this.logger = logger;

        this.api.interceptors.response.use(
            (response) => response,
            async (error: Error) => {
                if (!axios.isCancel(error)) {
                    this.logger(error);
                }

                return Promise.reject(error);
            },
        );

        addErrorMessageLocalization(this.api);
    }

    getUiUrl(): string {
        return this.config.uiUrl;
    }

    getOrigin(): string {
        const url = new URL(this.config.uiUrl);

        return url.origin;
    }

    isAddToQuoteMessage(event: MessageEvent): boolean {
        const { origin } = event;
        // eslint-disable-next-line prefer-destructuring
        const data: unknown = event.data;

        return origin === this.getOrigin() && typeof data === 'string' && data.includes('added-to-quote');
    }

    async getQuote(shouldReset = false): Promise<BatiChiffrageQuote> {
        const params: BatiChiffrageApiQuoteRequest = {
            MODE: 'LIST',
            ORIGINE: this.config.origin,
            UID: this.config.userId,
            PRID: this.config.env,
        };

        if (!shouldReset) {
            params.NO_DEL = '';
        }

        const { data } = await this.api.get<BatiChiffrageApiListResponse>(this.routes.quote, { params });

        if (data.res === 'OK') {
            return data.OUVRAGE;
        }

        this.logger(data);

        throw new Error(data.msg);
    }

    async resetQuote(): Promise<void> {
        const params: BatiChiffrageApiQuoteRequest = {
            MODE: 'EMPTY',
            ORIGINE: this.config.origin,
            UID: this.config.userId,
            PRID: this.config.env,
        };

        const { data } = await this.api.get<BatiChiffrageApiResetResponse>(this.routes.quote, { params });

        if (data.res === 'OK') {
            return;
        }

        this.logger(data);

        throw new Error(data.msg);
    }
}
