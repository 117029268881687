import { createOptionsFromMap } from 'dtg-shared/utils/option';

import { DtgExpertise, DtgType } from './types';

export const DTG_EXPERTISE_LABEL_MAP: Record<DtgExpertise, string> = {
    [DtgExpertise.audit]: 'AUDIT',
    [DtgExpertise.dpe]: 'DPE',
};

export const DTG_EXPERTISE_OPTIONS = createOptionsFromMap(DTG_EXPERTISE_LABEL_MAP);

export const DTG_TYPE_LABEL_MAP: Record<DtgType, string> = {
    [DtgType.alreadyCoOwnership]: 'Copropriété existante',
    [DtgType.willBeCoOwnership]: 'Mise en copropriété',
    [DtgType.unsanitaryConditions]: 'Procédure insalubrité',
};

export const DTG_TYPE_OPTIONS = createOptionsFromMap(DTG_TYPE_LABEL_MAP);
