import { BasicItem, Id, ListItemData } from 'dtg-shared/types/data';
import { NonNullableRecord } from 'dtg-shared/types/utils';

import { DocumentStatus } from '../document';
import { DtgType } from '../dtg';

export const legalObligationFormDataKeys = [
    'status',
    'comment',
    'additionalData',
    'visibleInReport',
    'filesVisibleInReport',
] as const;

export type LegalObligationFormData = Pick<LegalObligationData, typeof legalObligationFormDataKeys[number]>;

export type LegalObligationData = ListItemData & {
    status: LegalObligationStatus | null;
    comment: string;
    additionalData: Partial<LegalObligationAdditionalData> | null;
    visibleInReport: boolean;
    filesVisibleInReport: boolean;

    valid: boolean;
    createdTimestamp: number;

    custom: boolean;
    groupId: string | null;
    information: LegalObligationCustomInformation | null;
    files: LegalObligationFile[];
};

export type LegalObligationFile = Id & {
    name: string;
};

export type LegalObligationCustomData = Pick<LegalObligationData, 'status' | 'additionalData'> &
    NonNullableRecord<Pick<LegalObligationData, 'information' | 'groupId'>>;

export type LegalObligationCustomInformation = Omit<
    LegalObligationListItem,
    'situations' | 'fields' | 'id' | 'ownId' | 'kind' | 'hidden' | 'disabledByDtgType'
>;

export type LegalObligationAdditionalData = {
    situation: string;
    reference: string;
    madeBy: string;
    madeByDate: string | null;
};

export type LegalObligationViewData = LegalObligationData &
    Omit<LegalObligationListItem, 'id' | 'ownId' | 'kind'>;

export enum LegalObligationStatus {
    notApplicable = 'notApplicable',
    delivered = 'delivered',
    notDelivered = 'notDelivered',
}

export const LEGAL_OBLIGATION_STATUS_MAP: Record<LegalObligationStatus, string> = {
    [DocumentStatus.notApplicable]: 'Sans objet',
    [DocumentStatus.delivered]: 'Présent',
    [DocumentStatus.notDelivered]: 'Manquant',
};

export type LegalObligationListGroup = Id & {
    title: string;
    description: string;
    order: number;
};

export type LegalObligationListItem = Id & {
    ownId: string; // Required for server-side validation
    title: string;
    reference: string;
    description: string;
    situations: BasicItem[];
    fields: LegalObligationField[];
    type: LegalObligationType;
    kind: 'dpe' | 'audit' | 'buildingRegisterNumber' | null;
    hidden: boolean;
    disabledByDtgType: LegalObligationDisabledByDtgType[];
};

export type LegalObligationDisabledByDtgType = {
    dtgType: DtgType;
    message: string;
};

export enum LegalObligationType {
    mobileApp = 'mobileApp',
    backoffice = 'backoffice',
}

export enum LegalObligationFieldType {
    text = 'text',
    date = 'date',
    situation = 'situation',
}

export type LegalObligationField = {
    key: LegalObligationFieldKey;
    type: LegalObligationFieldType;
    label: string;
    placeholder?: string;
    required: boolean;
};

export type LegalObligationFieldKey = keyof LegalObligationAdditionalData;
