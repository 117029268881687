import { computed } from 'vue';

import { sleep } from 'dtg-shared/utils/promise';

import { useStoreModule } from '@/store';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function usePersistence() {
    const {
        actions: { enablePersistence },
        getters: { getIsPersistenceEnabled },
    } = useStoreModule('app');

    const isPersistenceEnabled = computed(() => getIsPersistenceEnabled());

    async function initPersistence(): Promise<void> {
        try {
            await initPersistenceInternal();
        } catch (error) {
            logError(error);

            throw new Error(
                "L'application est déjà ouverte sur votre navigateur, veuillez fermer l'onglet et rafraîchir la page.",
            );
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        await navigator.storage?.persist();
    }

    async function initPersistenceInternal(): Promise<void> {
        try {
            await enablePersistence();
        } catch (error) {
            if (ENV.NODE_ENV === 'development') {
                await sleep(500);
                window.location.reload();
            }

            throw error;
        }
    }

    return {
        isPersistenceEnabled,

        initPersistence,
    };
}
