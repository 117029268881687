
import { IonApp } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import { openToast, useScrollRestoration } from 'dtg-components';

import { useSidebar } from '@/components/organisms/sidebar';

import { useAuth } from '@/composables/use-auth';
import { useDeviceDiskSpace } from '@/composables/use-device-disk-space';
import { useDtgPrefetch } from '@/composables/use-dtg-prefetch';
import { useNativePlugin } from '@/composables/use-native-plugins';
import { useNetworkStatus } from '@/composables/use-network-status';
import { usePersistence } from '@/composables/use-persistence';

import { ENV } from '@/utils/env';
import { logError } from '@/utils/log-error';
import { ATTENTION } from '@/utils/toast-message';

import { useInitSentryUser } from './init-sentry';
import { useInitDebug } from './use-init-debug';
import { useInitDtgStatusUpdates } from './use-init-dtg-status-updates';
import { useInitDtgStore } from './use-init-dtg-store';
import { useInitInstallPrompts } from './use-init-install-prompts';
import { useInitNativeApp } from './use-init-native-app';
import { useInitResumeUploads } from './use-init-resume-uploads';
import { useInitServiceWorkerEvents } from './use-init-service-worker-events';

export default defineComponent({
    name: 'app',

    components: {
        IonApp,
    },

    setup() {
        const initialized = ref(false);
        const loader = document.getElementById('loader');

        const { initSidebar } = useSidebar();
        const { initNetworkStatus } = useNetworkStatus();
        const { initBackofficeAuth, initFirebaseAuth } = useAuth();
        const { initPersistence } = usePersistence();
        const { initPrefetch } = useDtgPrefetch();
        const { initDeviceDiskSpace } = useDeviceDiskSpace();

        const { initServiceWorkerEvents } = useInitServiceWorkerEvents();
        const { initNativeApp } = useInitNativeApp();
        const { initSentryUser } = useInitSentryUser();
        const { initDtgStore } = useInitDtgStore();
        const { initResumeUploads } = useInitResumeUploads();
        const { initDebug, initAdminFunctions } = useInitDebug();
        const { initPWAPromptHandler, openAppStoreModalIfNeeded } = useInitInstallPrompts();
        const { init: initScrollRestoration } = useScrollRestoration();
        const { init: initDtgStatusUpdates } = useInitDtgStatusUpdates();

        const withSplashScreen = useNativePlugin('SplashScreen');

        void (async (): Promise<void> => {
            try {
                await initApp();

                initialized.value = true;
            } catch (error) {
                logError(error);

                openErrorToast((error as Error).message);
            } finally {
                loader?.remove();

                await withSplashScreen(async (plugin) => {
                    await plugin.SplashScreen.hide();
                });
            }
        })();

        async function initApp(): Promise<void> {
            if (ENV.NODE_ENV === 'development') {
                initDebug();
            }

            initAdminFunctions();

            if (ENV.VUE_APP_SERVICE_WORKER_ENABLED) {
                initServiceWorkerEvents();
            }

            // This must be executed synchronously,
            // otherwise the first navigation won't be processed
            initBackofficeAuth();

            initSentryUser();

            await initPersistence();
            await initNetworkStatus();
            await initFirebaseAuth();
            await initNativeApp();
            await initDtgStore();

            initResumeUploads();
            initSidebar();
            initPWAPromptHandler();
            initPrefetch();
            initDeviceDiskSpace();
            initScrollRestoration();
            initDtgStatusUpdates();

            openAppStoreModalIfNeeded();
        }

        function openErrorToast(message: string): void {
            void openToast(message, {
                header: ATTENTION,
                duration: -1,
                position: 'middle',
                buttons: [
                    {
                        text: 'RAFRAICHIR',
                        handler: (): void => {
                            window.location.reload();
                        },
                    },
                ],
            });
        }

        return {
            initialized,
        };
    },
});
