import { computed, isRef } from 'vue';
import { ObjectSchema, SchemaOf } from 'yup';
import { SchemaDescription } from 'yup/lib/schema';

import { ReadonlyMaybeRef } from '../../types/vue';
import { ValidationConditionsOf, ValidationSchema, ValidationSchemaWithConditions } from '../../validation';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useFormValidationSchema<T extends Record<string, unknown>>({
    schema,
}: {
    schema: ReadonlyMaybeRef<ValidationSchema<T> | ValidationSchemaWithConditions<T>>;
}) {
    const schemaInternal = computed<{
        schema: SchemaOf<T>;
        conditions: ValidationConditionsOf<T>;
    }>(() => {
        const value = isRef(schema) ? schema.value : schema;

        return value instanceof ObjectSchema
            ? { schema: value as SchemaOf<T>, conditions: {} }
            : { schema: value.schema as SchemaOf<T>, conditions: value.conditions };
    });

    const description = computed(() => {
        const fullDescription = schemaInternal.value.schema.describe();

        return fullDescription.fields as Record<keyof T, SchemaDescription>;
    });

    return {
        schema: computed(() => schemaInternal.value.schema),
        conditions: computed(() => schemaInternal.value.conditions),
        description,
    };
}
