import firebase from 'firebase/compat/app';

import { MaybePromise } from 'dtg-shared/types/utils';
import { arrayify } from 'dtg-shared/utils/array';
import { createDeferred, Deferred } from 'dtg-shared/utils/deferred';

import { CollectionSubscriptionCanceled } from './errors';

export class CollectionSubscription<T> {
    private subscribed = false;
    private resolved = false;
    private readonly deferred: Deferred<void> = createDeferred();
    private readonly loadedCollections = new Set<firebase.firestore.Query<T>>();
    private readonly collections: Set<firebase.firestore.Query<T>>;
    private readonly onError;
    private readonly onSnapshot;
    private readonly includeMetadataChanges;
    private readonly unsubscribeHandlers: Array<() => void> = [];

    constructor({
        collection,
        onError,
        onSnapshot,
        includeMetadataChanges = false,
    }: {
        collection: firebase.firestore.Query<T> | Array<firebase.firestore.Query<T>>;
        onError: (error: unknown) => void;
        onSnapshot: (snapshot: firebase.firestore.QuerySnapshot<T>) => MaybePromise<void>;
        includeMetadataChanges?: boolean;
    }) {
        this.collections = new Set(arrayify(collection));
        this.onError = onError;
        this.onSnapshot = onSnapshot;
        this.includeMetadataChanges = includeMetadataChanges;
    }

    async subscribe(): Promise<void> {
        if (!this.subscribed) {
            this.subscribed = true;

            this.collections.forEach((collection) => {
                this.subscribeToCollection(collection);
            });
        }

        await this.deferred.promise;
    }

    unsubscribe(): void {
        this.unsubscribeHandlers.forEach((unsubscribe) => {
            unsubscribe();
        });

        this.unsubscribeHandlers.length = 0;

        this.onUnsubscribe();
    }

    private subscribeToCollection(collection: firebase.firestore.Query<T>): void {
        const unsubscribe = collection.onSnapshot(
            { includeMetadataChanges: this.includeMetadataChanges },
            (snapshot) => {
                void this.onSnapshotInternal(snapshot, collection);
            },
            (error) => {
                this.onErrorInternal(error);
            },
        );

        this.unsubscribeHandlers.push(unsubscribe);
    }

    private onErrorInternal(error: unknown): void {
        if (this.resolved) {
            if (!(error instanceof CollectionSubscriptionCanceled)) {
                this.onError(error);
            }
        } else {
            this.resolved = true;
            this.deferred.reject(error);
        }
    }

    private async onSnapshotInternal(
        snapshot: firebase.firestore.QuerySnapshot<T>,
        collection: firebase.firestore.Query<T>,
    ): Promise<void> {
        try {
            // Support subscribing to additional collections inside the handler
            await this.onSnapshot(snapshot);
        } catch (error) {
            this.onErrorInternal(error);

            return;
        }

        if (this.resolved) {
            return;
        }

        this.loadedCollections.add(collection);

        if (this.loadedCollections.size === this.collections.size) {
            this.resolved = true;
            this.deferred.resolve();
        }
    }

    private onUnsubscribe(): void {
        if (!this.resolved) {
            this.resolved = true;
            this.deferred.reject(new CollectionSubscriptionCanceled('Collection subscription canceled'));
        }
    }
}
