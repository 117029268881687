// eslint-disable-next-line simple-import-sort/imports
import { useAuth } from '@/composables/use-auth';
import { User } from '@/store/auth';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { App, watch } from 'vue';
import { Router } from 'vue-router';

import { ENV } from '@/utils/env';

export function initSentry(app: App, router: Router): void {
    const buildNumber = ENV.VUE_APP_BUILD_ID;
    const appVersion = ENV.VUE_APP_VERSION;

    Sentry.init({
        enabled: ENV.VUE_APP_SENTRY_ENABLED,
        environment: ENV.VUE_APP_SENTRY_ENV,
        debug: ENV.VUE_APP_SENTRY_DEBUG,
        release: appVersion,
        logErrors: true,
        app,
        dsn: ENV.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [...ENV.VUE_APP_SENTRY_TRACING_ORIGINS, /^\//],
            }),
        ],
        tracesSampleRate: ENV.VUE_APP_SENTRY_TRACE_SAMPLE_RATE,
    });

    Sentry.setContext('buildId', { appVersion, buildNumber });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitSentryUser() {
    const { user } = useAuth();

    function initSentryUser(): void {
        watch(user, updateSentryUser, { immediate: true });
    }

    function updateSentryUser(user: User | null): void {
        if (!user) {
            Sentry.setUser(null);

            return;
        }

        const { uid, email } = user;

        Sentry.setUser({
            ...user,
            id: uid,
            username: email ?? uid,
            email: email ?? '',
        });
    }

    return {
        initSentryUser,
    };
}
