import { string } from 'yup';

import { defineSchemaOf, phoneNumber, ValidationSchema } from 'dtg-shared/validation';

import { AttendantFormData } from './types';

export function attendantValidationSchema(): ValidationSchema<AttendantFormData> {
    return defineSchemaOf<AttendantFormData>()({
        name: string().label('Nom').defined(),
        firstname: string().label('Prénom').defined(),
        position: string().label('Fonction').defined(),
        phoneNumber: phoneNumber().label('Téléphone').defined(),
    });
}
