import { Api, ApiResponse } from '../../classes/Api';
import { ApiModule } from '../../classes/ApiModule';
import { ApiError } from '../../classes/Error';
import { TokenManager } from '../../classes/TokenManager';

import {
    API_AUTH_LOGIN_ROUTE,
    API_AUTH_PASSWORD_CODE_INFO,
    API_AUTH_REFRESH_TOKEN_ROUTE,
    API_AUTH_RESTORE_PASSWORD,
    API_AUTH_SET_PASSWORD,
    API_AUTH_UPDATE_PASSWORD,
    API_AUTH_VERIFY_TOKEN,
} from './routes';
import {
    ApiAuthLoginRequest,
    ApiAuthLoginResponse,
    ApiAuthLoginResult,
    ApiAuthPasswordCodeInfoRequest,
    ApiAuthPasswordCodeInfoResponse,
    ApiAuthRefreshTokenRequest,
    ApiAuthRefreshTokenResponse,
    ApiAuthRestorePasswordRequest,
    ApiAuthRestorePasswordResponse,
    ApiAuthSetPasswordRequest,
    ApiAuthSetPasswordResponse,
    ApiAuthUpdatePasswordRequest,
    ApiAuthUpdatePasswordResponse,
    ApiAuthVerifyTokenRequest,
} from './types';

export class ApiAuthModule extends ApiModule {
    private readonly tokens: TokenManager;

    constructor(tokens: TokenManager) {
        super();

        this.tokens = tokens;
    }

    override setApi(api: Api): void {
        super.setApi(api);

        this._initApiInterceptor();
    }

    private async _handleLogin<T extends ApiAuthRefreshTokenResponse>(action: Promise<T>): Promise<T> {
        let response;

        try {
            response = await action;
        } catch (error) {
            this._clearTokens();

            throw error;
        }

        if (response.ok) {
            this._setTokens(response.result);
        } else {
            this._clearTokens();
        }

        return response;
    }

    async login({ requestData }: { requestData: ApiAuthLoginRequest }): Promise<ApiAuthLoginResponse> {
        return this._handleLogin(
            this.api.put<ApiAuthLoginResponse, ApiAuthLoginRequest>(API_AUTH_LOGIN_ROUTE, requestData),
        );
    }

    // TODO: add logout route
    async logout(): Promise<ApiResponse> {
        this._clearTokens();

        return Promise.resolve({ ok: true, result: null });
    }

    async refreshToken(): Promise<ApiAuthRefreshTokenResponse> {
        const { refreshToken } = this.tokens;

        if (!refreshToken) {
            throw new ApiError({ message: 'No refresh token' });
        }

        return this._handleLogin(
            this.api.put<ApiAuthRefreshTokenResponse, ApiAuthRefreshTokenRequest>(
                API_AUTH_REFRESH_TOKEN_ROUTE,
                { refreshToken },
                { isTokenRefresh: true },
            ),
        );
    }

    async getPasswordCodeDetails({
        code,
    }: ApiAuthPasswordCodeInfoRequest): Promise<ApiAuthPasswordCodeInfoResponse> {
        return this.api.get(`${API_AUTH_PASSWORD_CODE_INFO}/${code}`);
    }

    async setPassword({
        password,
        code,
    }: {
        password: string;
        code: string;
    }): Promise<ApiAuthSetPasswordResponse> {
        return this._handleLogin(
            this.api.put<ApiAuthSetPasswordResponse, ApiAuthSetPasswordRequest>(
                `${API_AUTH_SET_PASSWORD}/${code}`,
                {
                    password,
                },
            ),
        );
    }

    async updatePassword({
        password,
        code,
    }: {
        password: string;
        code: string;
    }): Promise<ApiAuthUpdatePasswordResponse> {
        return this._handleLogin(
            this.api.post<ApiAuthUpdatePasswordResponse, ApiAuthUpdatePasswordRequest>(
                API_AUTH_UPDATE_PASSWORD,
                { password, code },
            ),
        );
    }

    async restorePassword({ email }: { email: string }): Promise<ApiAuthRestorePasswordResponse> {
        return this.api.put<ApiAuthRestorePasswordResponse, ApiAuthRestorePasswordRequest>(
            API_AUTH_RESTORE_PASSWORD,
            { email },
        );
    }

    async verifyToken({ accessToken }: ApiAuthVerifyTokenRequest): Promise<ApiResponse> {
        return this.api.post<ApiResponse, ApiAuthVerifyTokenRequest>(API_AUTH_VERIFY_TOKEN, { accessToken });
    }

    private _setTokens(result: ApiAuthLoginResult): void {
        const { accessToken, refreshToken } = result;

        this.tokens.set(accessToken, refreshToken);
    }

    private _clearTokens(): void {
        this.tokens.clear();
    }

    private _initApiInterceptor(): void {
        this.api.addRequestInterceptor((config) => {
            const { accessToken } = this.tokens;

            if (accessToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            }

            return config;
        });
    }
}
