/* eslint-disable @typescript-eslint/no-explicit-any */

export class PromiseAbortError extends Error {
    constructor(message = 'Promise aborted') {
        super(message);
    }
}

export async function abortablePromise<T>(
    signal: AbortSignal,
    executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
): Promise<T> {
    function abort(reject: (reason?: any) => void): void {
        reject(new PromiseAbortError());
    }

    return new Promise((resolve, reject) => {
        executor(resolve, reject);

        if (signal.aborted) {
            abort(reject);

            return;
        }

        signal.addEventListener('abort', () => {
            abort(reject);
        });
    });
}

export async function sleep(timeout: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout);
    });
}
