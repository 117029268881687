import isEqual from 'lodash/isEqual';
import { computed, onBeforeUnmount, reactive, ref, watch } from 'vue';

import { Id, Valid } from '../../types/data';
import { ReadonlyMaybeRef, ReadonlyRef, WritableRef } from '../../types/vue';
import { ValidationErrors, ValidationSchema, ValidationSchemaWithConditions } from '../../validation';

import { FieldDisabled, FormComposableWithBuffer, FormField, FormItem } from './types';
import { useFormShared } from './use-form-shared';
import { useUpdateBuffer } from './use-update-buffer';

export function useFormItemValidation<T extends Record<string, unknown>, U extends T = T>({
    item,
    initialData,
    validationSchema,
    additionalValidation,
    fieldErrors = ref({}),
    disabled,
    fieldsDisabled,
    onUpdate,
    onValidation,
    shouldFocusInvalid,
    debounce = 300,
    immediateFields = [],
}: {
    item: ReadonlyRef<FormItem<T> | null>;
    initialData: T;
    validationSchema: ReadonlyMaybeRef<ValidationSchemaWithConditions<U> | ValidationSchema<U>>;
    additionalValidation?: ReadonlyRef<boolean>;
    fieldErrors?: WritableRef<ValidationErrors<T>>;
    disabled: FieldDisabled<T>;
    fieldsDisabled?: FieldDisabled<T>;
    onUpdate: (payload: Id & { data: Partial<T> }) => void;
    onValidation: (payload: Id & Valid) => void;
    shouldFocusInvalid?: ReadonlyMaybeRef<boolean>;
    debounce?: number;
    immediateFields?: Array<keyof T>;
}): FormComposableWithBuffer<T> {
    const {
        form,
        fields,
        fieldsArray,
        setErrors,
        setTouched,
        validate,
        setInvalidTouched,
        formUuid,
        focusFirstInvalid,
    } = useFormShared({
        fieldErrors,
        validationSchema,
        disabled,
        fieldsDisabled,
        initialData,
        shouldFocusInvalid,
    });

    const { isBufferEmpty, flushBuffer, debounceFlushBuffer, addToBuffer, removeFromBuffer, isInBuffer } =
        useUpdateBuffer({
            item,
            immediateFields,
            debounce,
            onUpdate,
        });

    const valid = computed(() => {
        const { valid } = form.meta.value;

        return valid && (!additionalValidation || additionalValidation.value);
    });

    const validationPending = computed(() => form.meta.value.pending);

    onBeforeUnmount(() => {
        flushBuffer();
    });

    // Hook up fields to store
    fieldsArray.forEach(([key, field]) => {
        if (item.value) {
            const value = item.value.data[key];

            updateFieldValue(field, key, value);
        }

        watch(
            () => item.value?.data[key],
            (value) => {
                updateFieldValue(field, key, value);
            },
        );

        watch(field.value, (value) => {
            // It's important to call disabled without key here,
            // since this is only meant to prevent updates when the entire form is disabled
            const isDisabled = typeof disabled === 'function' ? disabled() : disabled.value;

            if (!isDisabled) {
                updateStoreValue(field, key, value);
            }
        });
    });

    /*
     *  Here we connect the valid status of the form to the valid status of the item.
     *  It is not enough to just watch the valid status itself:
     *  1. When the item changes, the form's valid status might not change, so the update won't trigger,
     *     but the item's valid status might be different, causing inconsistency;
     *  2. When the form's validation is pending, the valid status might be incorrect,
     *     so we need to wait for the form to finish validation;
     *  3. When there's data in the update buffer, the valid flag isn't going to match the data in the store,
     *     so we need to wait for the buffer to be flushed/cleared.
     */
    watch(
        [item, valid, validationPending, isBufferEmpty],
        updateStoreValid,
        // Makes sure that all changes have been applied to fields
        { flush: 'post' },
        // `immediate` isn't necessary here because the form will always update its pending status after initialization.
        // In fact, `immediate` causes incorrect valid status to be passed to store,
        // because a form with values equal to `initialValues` will initially have `valid: true, pending: false`
    );

    watch(
        item,
        async (newValue, oldValue) => {
            if (newValue?.id !== oldValue?.id) {
                await form.validate();

                setInvalidTouched();
            }
        },
        // Makes sure that all changes have been applied to fields
        { immediate: true, flush: 'post' },
    );

    /* Store <-> field data exchange */
    function updateFieldValue(field: FormField, key: keyof T, value?: unknown): void {
        const newValue = typeof value === 'undefined' ? initialData[key] : value;

        if (!isEqual(newValue, field.value.value) && !isInBuffer(key)) {
            field.updateValue(newValue);
        }
    }

    function updateStoreValue(field: FormField, key: keyof T, value: unknown): void {
        if (!item.value) {
            return;
        }

        const { data } = item.value;

        if (isEqual(data[key], value)) {
            if (isInBuffer(key)) {
                removeFromBuffer(key);
            }
        } else {
            addToBuffer(key, value as T[keyof T]);
            debounceFlushBuffer();
        }
    }

    function updateStoreValid(): void {
        const itemValue = item.value;

        if (!itemValue || validationPending.value || !isBufferEmpty.value) {
            return;
        }

        const { id, valid: itemValid } = itemValue;

        if (typeof itemValid === 'undefined' || itemValid !== valid.value) {
            onValidation({ id, valid: valid.value });
        }
    }

    return {
        fields: reactive(fields),

        valid,
        fieldErrors,
        meta: form.meta,

        setErrors,
        setTouched,
        validate,
        focusFirstInvalid,
        formUuid,
        isBufferEmpty,
    };
}
