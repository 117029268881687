import { LocationOption } from 'dtg-shared/types/data';

import { CurativesRecord } from '../control-point';

export type DtgData = {
    idRepresentative: string;

    archived: boolean;
    createdTimestamp: number;
    updatedTimestamp: number;

    location: LocationOption | null;
    addressExtra: string;
    addressFree: string;

    typologyId: string | null;
    registerNumber: string;
    appartmentCount: number | null;
    buildingCount: number | null;
    shob: number | null;
    buildingComplexDescription: string | null;

    name: string;
    dtgType: DtgType | null;

    visitDate: string | null;
    endDate: string | null;

    nameOwner: string;
    phoneOwner: string;
    emailOwner: string;
    firstNameOwner: string;
    locationOwner: LocationOption | null;

    nameContactOwner: string;
    firstNameContactOwner: string;
    locationContactOwner: LocationOption | null;
    societyContactOwner: string;
    functionContactOwner: string;
    phoneContactOwner: string;
    emailContactOwner: string;

    isOwner: boolean;

    visualState: string | null;
    costState: string;
    documentsState: string | null;
    energyState: string | null;
    conclusion: string;

    coverPhotoId: string | null;

    curativeOpinions: CurativesRecord; // currently unused

    expertise: DtgExpertise | null;
    status: DtgStatus;

    technicianId: string | null;
    validatorId: string | null;
    directorId: string | null;
    companyId: string;
    parentCompanyId: string | null;
    packId: string | null;

    valid: boolean;
    validPartially: DtgDataValid;
    comment: string;
};

export enum DtgExpertise {
    audit = 'audit',
    dpe = 'dpe',
}

export enum DtgType {
    alreadyCoOwnership = 'alreadyCoOwnership',
    willBeCoOwnership = 'willBeCoOwnership',
    unsanitaryConditions = 'unsanitaryConditions',
}

export enum DtgDataValidKey {
    location = 'location',
    information = 'information',
    buildingComplex = 'buildingComplex',
    photos = 'photos',
    conclusion = 'conclusion',
    curativeOpinions = 'curativeOpinions',
}

export type DtgDataValid = Record<DtgDataValidKey, boolean>;

export type DtgId = {
    dtgId: string;
};

export enum DtgStatus {
    closed = 'closed',
    onValidation = 'on_validation',
    opened = 'opened',
    planning = 'planning',
    ready = 'ready',
}

export const dtgFormBuildingComplexDataKeys = [
    'appartmentCount',
    'shob',
    'buildingCount',
    'typologyId',
    'registerNumber',
    'buildingComplexDescription',
] as const;

export type DtgFormBuildingComplexData = Pick<DtgData, typeof dtgFormBuildingComplexDataKeys[number]>;

export const dtgFormInformationDataKeys = [
    'dtgType',
    'visitDate',
    'endDate',
    'nameOwner',
    'phoneOwner',
    'emailOwner',
    'firstNameOwner',
    'locationOwner',
    'nameContactOwner',
    'firstNameContactOwner',
    'locationContactOwner',
    'societyContactOwner',
    'functionContactOwner',
    'phoneContactOwner',
    'emailContactOwner',
    'isOwner',
] as const;

export type DtgFormInformationData = Pick<DtgData, typeof dtgFormInformationDataKeys[number]>;

export const dtgConclusionDataKeys = [
    'visualState',
    'costState',
    'documentsState',
    'energyState',
    'conclusion',
] as const;

export type DtgConclusionData = Pick<DtgData, typeof dtgConclusionDataKeys[number]>;

export const dtgFormPhotosDataKeys = ['coverPhotoId'] as const;

export type DtgFormPhotosData = Pick<DtgData, typeof dtgFormPhotosDataKeys[number]>;

export type DtgCurativeOpinionsData = Pick<DtgData, 'curativeOpinions'>;

export const dtgFormLocationDataKeys = ['location', 'addressExtra', 'addressFree', 'visitDate'] as const;

export type DtgFormLocationData = Pick<DtgData, typeof dtgFormLocationDataKeys[number]>;
