import { string } from 'yup';

import { defineSchemaOf, phoneNumber, ValidationSchema } from 'dtg-shared/validation';

import { CourseFormData } from './types';

export function courseValidationSchema(): ValidationSchema<CourseFormData> {
    return defineSchemaOf<CourseFormData>()({
        name: string().label('Nom').defined(),
        firstname: string().label('Prénom').defined(),
        localisation: string().label('Localisation').defined(),
        phoneNumber: phoneNumber().label('Téléphone').defined(),
    });
}
