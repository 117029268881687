
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { DtgLink, getName, useBem } from 'dtg-components';

import { Route } from '@/router';

import styles from './style.module.scss';

export default defineComponent({
    name: getName(styles),

    components: {
        DtgLink,
    },

    setup() {
        const router = useRouter();

        const termsOfServiceUrl = computed(() => router.resolve({ name: Route.termsOfService }));
        const termsOfSalesUrl = computed(() => router.resolve({ name: Route.termsOfSales }));
        const privacyPolicyUrl = computed(() => router.resolve({ name: Route.privacyPolicy }));

        return {
            b: useBem(styles),

            termsOfServiceUrl,
            termsOfSalesUrl,
            privacyPolicyUrl,
        };
    },
});
