import { computed, readonly } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';

import { useModalMessage } from 'dtg-components';
import { wrapPending } from 'dtg-shared/utils/wrap-pending';

import { useNetworkStatus } from '@/composables/use-network-status';

import { getStorage } from '@/services/firebase';

import { useStoreModule } from '@/store';

import { useAuthRouting } from './use-auth-routing';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuthLogout() {
    const {
        actions: { logout },
        getters: { getIsLoggingOut },
        mutations: { setIsLoggingOut },
    } = useStoreModule('auth');

    const { getAuthRoute } = useAuthRouting();

    const { online } = useNetworkStatus();
    const modalMessage = useModalMessage();

    const router = useRouter();
    const route = useRoute();

    const loggingOut = computed({
        get: getIsLoggingOut,
        set: (value) => {
            setIsLoggingOut({ value });
        },
    });

    async function logoutWithRedirect(
        redirectTo: RouteLocationRaw | null = getAuthRoute(route),
    ): Promise<void> {
        if (!loggingOut.value) {
            await wrapPending(logoutWithRedirectInternal(redirectTo), loggingOut);
        }
    }

    async function logoutWithRedirectInternal(redirectTo?: RouteLocationRaw | null): Promise<void> {
        if (redirectTo) {
            await router.push(redirectTo);
        }

        await logout();
    }

    async function logoutWithConfirmation(redirectTo?: RouteLocationRaw | null): Promise<boolean> {
        const confirmed = confirmLogout();

        if (confirmed) {
            await logoutWithRedirect(redirectTo);
        }

        return confirmed;
    }

    function confirmLogout(): boolean {
        const uploadsMayBeLost = !online.value || getStorage().hasPendingUploads;

        if (uploadsMayBeLost) {
            modalMessage.open({
                title: 'Sauvegarde des données',
                message:
                    'Veuillez reconnecter la tablette à un réseau internet avant de quitter votre DTG s’il vous plait, ou vos données seront perdues.',
                confirmText: 'Oui, j’ai compris',
            });

            return false;
        }

        return true;
    }

    return {
        loggingOut: readonly(loggingOut),

        logout,
        logoutWithConfirmation,
        logoutWithRedirect,
    };
}
