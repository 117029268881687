import { DtgData } from 'dtg-firebase/modules/dtg';
import { Id } from 'dtg-shared/types/data';

import { ApiResponse } from '../../classes/Api';
import { ApiCompanyEngagementPackStatus } from '../company';
import { ApiUserShallow } from '../user';

export type ApiDtg = {
    id: string;
    data: DtgData;
    technician?: ApiUserShallow;
    validator?: ApiUserShallow;
    pack?: ApiDtgPack;
};

export type ApiDtgPack = Id & {
    status: ApiCompanyEngagementPackStatus;
    startDate: string;
    endDate: string;
};

/**
 * A {@link TransformShape } of the type above.
 *
 * The response transformations/normalizations of {@link Api} only need to be applied to these fields,
 * because omitted keys are already in a stable format.
 */
export const apiDtgTransformShape: Record<Exclude<keyof ApiDtg, 'data'>, true> = {
    id: true,
    technician: true,
    validator: true,
    pack: true,
};

export type ApiDtgCreateRequest = DtgData;
export type ApiDtgCreateResponse = ApiResponse<{ id: string }>;

export type ApiDtgUpdateRequest = Partial<DtgData>;
export type ApiDtgUpdateResponse = ApiResponse<{ id: string }>;

export type ApiDtgListRequest = { archived?: boolean };
export type ApiDtgListResponse = ApiResponse<{ items: ApiDtg[] }>;

export type ApiDtgInfoResponse = ApiResponse<ApiDtg>;

export enum ApiDtgCreateUpdateErrorCode {
    technicianCompanyMismatch = 'dtg/technician-company-mismatch',
    validatorCompanyMismatch = 'dtg/validator-company-mismatch',
    companyNotFound = 'dtg/company-not-found',
    companyEngagementMismatch = 'dtg/company-engagement-mismatch',
}

export type ApiDtgDuplicateResponse = ApiResponse<ApiDtg>;
