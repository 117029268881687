import axios from 'axios';
import { computed, ref } from 'vue';

import { ReportStatus } from 'dtg-firebase/modules/report';
import { useProgress } from 'dtg-shared/composables/use-progress';
import { ReadonlyRef } from 'dtg-shared/types/vue';
import { noop } from 'dtg-shared/utils/noop';
import { withCachedPromise } from 'dtg-shared/utils/with-cached-promise';
import { PdfPollResultFile, REPORT_GENERATION_TOTAL_STEPS } from 'dtg-shared-report';

import { ReportApiGenerateResponse } from '../classes/ReportApi';

export type ReportDownloadStartPollingParams = {
    docPath: string;
    setStatus: (value: ReportStatus) => void;
    setProgress: (value: number) => void;
    resolve: (file: PdfPollResultFile) => void;
    reject: (error: unknown) => void;
};

export type ReportDownloadOpenModalParams = {
    status: ReadonlyRef<ReportStatus>;
    progress: ReadonlyRef<number>;
    stalling: ReadonlyRef<boolean>;
    filename: ReadonlyRef<string | null>;
    downloadUrl: ReadonlyRef<string | null>;
    errorMessage: ReadonlyRef<string | null>;
    cancel: () => void;
    retry: () => void;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useReportDownload({
    openModal,
    startGenerating,
    startPolling,
    stopPolling,
    onReset = noop,
    computeStatus,
    logError,
}: {
    openModal: (params: ReportDownloadOpenModalParams) => void;
    startGenerating: () => Promise<ReportApiGenerateResponse>;
    startPolling: (params: ReportDownloadStartPollingParams) => void;
    stopPolling: () => void;
    onReset?: () => void;
    computeStatus: (status: ReportStatus) => ReportStatus;
    logError: (error: unknown) => void;
}) {
    const status = ref<ReportStatus>(ReportStatus.pending);
    const errorMessage = ref<string | null>(null);
    const { progress, stalling, setProgress, resetProgress, enableProgressUpdate } = useProgress({
        totalSteps: REPORT_GENERATION_TOTAL_STEPS,
    });
    const downloadUrl = ref<string | null>(null);
    const filename = ref<string | null>(null);
    const computedStatus = computed(() => computeStatus(status.value));

    const downloadCached = withCachedPromise(download, true);
    const openModalCached = withCachedPromise(openModalInternal, true);

    async function download(): Promise<void> {
        resetState();
        enableProgressUpdate();

        try {
            // User can close the modal before report is generated,
            // in which case openModalCached wins the race
            const file = await Promise.race([openModalCached(), generateReport()]);

            if (file) {
                const { url, name } = file;

                downloadUrl.value = url;
                filename.value = name;
            }
        } catch (error) {
            if (error instanceof Error && !axios.isCancel(error)) {
                logError(error);

                errorMessage.value = 'Échec de la génération du rapport';
                status.value = ReportStatus.error;
                resetProgress();
            }
        }

        // Cleanup after generateReport
        stopPolling();
    }

    function resetState(): void {
        status.value = ReportStatus.pending;
        errorMessage.value = null;
        downloadUrl.value = null;
        filename.value = null;
        resetProgress();
        onReset();
    }

    async function generateReport(): Promise<PdfPollResultFile> {
        const { docPath } = await startGenerating();

        return waitForReport(docPath);
    }

    async function openModalInternal(): Promise<null> {
        return new Promise<null>((resolve) => {
            openModal({
                status: computedStatus,
                progress,
                stalling,
                filename,
                downloadUrl,
                errorMessage,
                cancel: () => {
                    resolve(null);
                },
                retry: () => {
                    void downloadCached();
                },
            });
        });
    }

    async function waitForReport(docPath: string): Promise<PdfPollResultFile> {
        return new Promise<PdfPollResultFile>((resolve, reject) => {
            startPolling({ docPath, setStatus, setProgress, resolve, reject });
        });
    }

    function setStatus(value: ReportStatus): void {
        status.value = value;
    }

    return {
        download: downloadCached,
    };
}
