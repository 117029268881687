import { Id } from 'dtg-shared/types/data';

import { DocumentSnapshotBase, QueryDocumentSnapshotBase } from './types';

export function buildWithId<T>(doc: QueryDocumentSnapshotBase<T>): T & Id;
export function buildWithId<T>(doc: DocumentSnapshotBase<T>): (T & Id) | null;

export function buildWithId<T>(doc: QueryDocumentSnapshotBase<T> | DocumentSnapshotBase<T>): (T & Id) | null {
    const data = doc.data();

    if (!data) {
        return null;
    }

    return {
        ...data,
        id: doc.id,
    };
}
