import { scrollBy as scrollByBase, scrollTo as scrollToBase } from 'seamless-scroll-polyfill';
import { IScrollConfig } from 'seamless-scroll-polyfill/lib/scroll-step';
import scrollIntoViewBase, { SmoothBehaviorOptions } from 'smooth-scroll-into-view-if-needed';

import { TemplateRef } from '../types/vue';

import { getElementFromRef } from './get-element-from-ref';
import { objPick } from './object';

export type ScrollOptions = ScrollToOptions & Pick<IScrollConfig, 'duration'>;
export type ScrollPosition = Pick<ScrollOptions, 'top' | 'left'>;

const DEFAULT_DURATION = 300;

const defaultScrollIntoViewOptions: SmoothBehaviorOptions = {
    behavior: 'smooth',
    scrollMode: 'if-needed',
    block: 'center',
    inline: 'center',
    duration: DEFAULT_DURATION,
};

const defaultScrollOptions: ScrollToOptions = {
    behavior: 'smooth',
};

const defaultScrollConfig: IScrollConfig = {
    forcePolyfill: true,
    duration: DEFAULT_DURATION,
};

export async function scrollIntoView(
    templateRef: TemplateRef,
    options: SmoothBehaviorOptions = {},
): Promise<void> {
    const el = getElementFromRef(templateRef);

    if (el) {
        await scrollIntoViewBase(el, { ...defaultScrollIntoViewOptions, ...options });
    }
}

export function scrollTo(templateRef: TemplateRef, options: ScrollToOptions): void {
    const el = getElementFromRef(templateRef);

    if (el) {
        scrollToBase(el, ...convertScrollOptions(options));
    }
}

export function scrollBy(templateRef: TemplateRef, options: ScrollOptions): void {
    const el = getElementFromRef(templateRef);

    if (el) {
        scrollByBase(el, ...convertScrollOptions(options));
    }
}

export function setScroll(templateRef: TemplateRef, options: ScrollPosition): void {
    const el = getElementFromRef(templateRef);

    if (el) {
        if (typeof options.top !== 'undefined') {
            el.scrollTop = options.top;
        }

        if (typeof options.left !== 'undefined') {
            el.scrollLeft = options.left;
        }
    }
}

function convertScrollOptions(options: ScrollOptions): [ScrollToOptions, IScrollConfig] {
    return [
        { ...defaultScrollOptions, ...options },
        { ...defaultScrollConfig, ...objPick(options, ['duration']) },
    ];
}
