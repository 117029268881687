
import { defineComponent } from 'vue';

import { DtgTermsOfSales } from 'dtg-components';

import { DtgLayoutAbout } from '@/components/layouts/layout-about';

export default defineComponent({
    name: 'dtg-page-terms-of-sales',

    components: {
        DtgLayoutAbout,
        DtgTermsOfSales,
    },
});
