import dayjs, { Dayjs, ManipulateType, UnitType } from 'dayjs';
import localeFr from 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import zip from 'lodash/zip';

import { FreeDate } from '../types/data';

import { normalizeString } from './string';

export function initializeDates(): void {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isSameOrAfterPlugin);
    dayjs.extend(isSameOrBeforePlugin);
    dayjs.extend(customParseFormat);

    // Locale without accents for parsing user input
    const localeFrNormalized = {
        ...localeFr,
        name: 'fr-normalized',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        months: localeFr.months!.map((month) => normalizeString(month)),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        monthsShort: localeFr.monthsShort!.map((month) => normalizeString(month).replace('.', '')),
    };

    dayjs.locale(localeFr);
    dayjs.locale(localeFrNormalized);
}

// Formats a date without taking the user's timezone into account
// E.g. 2021-11-24T23:00:00.000Z in GMT+0300 => 24/nov./2021
export function formatDate(date: string | number | Date | Dayjs, template = 'DD/MM/YYYY'): string {
    return dayjs(date).utc().format(template);
}

// Formats a date, taking the user's or the specified timezone into account
// E.g. 2021-11-24T23:00:00.000Z in GMT+0300 => 25/nov./2021 02:00
export function formatLocalDate(
    date: string | number | Date | Dayjs,
    template = 'DD/MM/YYYY HH:mm',
    timezone?: string,
): string {
    const localDate = dayjs(date).local();
    const tzDate = timezone ? localDate.tz(timezone) : localDate;

    return tzDate.format(template);
}

/**
 * Returns a date at the midnight of the input date without taking the user's timezone into account.
 * In case no date is provided, returns a date at the midnight of the user's current date, timezone taken into account.
 *
 * For cases when time and timezones are not relevant (e.g. someone's birthday)
 * If you want to create a specific date at the UTC midnight, use `new Date('2021-11-24')`
 *
 * When formatted directly, the formatting will also happen without taking the timezone into account
 * (same as with formatDate above)
 *
 * @example
 * const date = new Date(); // current date is 2021-11-24T23:00:00.000Z which is 2021-11-25T2:00 in GMT+0300
 * createUtcDate(date); // 2021-11-24T00:00:00.000Z
 * createUtcDate(); // 2021-11-25T00:00:00.000Z
 */
export function createUtcDate(
    sourceDate: string | number | Date | Dayjs = dayjs().utc(true),
    parseFormat?: string,
    strict?: boolean,
): Dayjs {
    const date = dayjs.utc(sourceDate, parseFormat, strict).toDate();

    return dayjs.utc(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function eachDateOfInterval(
    { start, end }: { start: Dayjs; end: Dayjs },
    unit: ManipulateType,
): Dayjs[] {
    const dates: Dayjs[] = [];
    let curDate = start;

    while (curDate.isBefore(end, unit) || curDate.isSame(end, unit)) {
        dates.push(curDate);
        curDate = curDate.add(1, unit);
    }

    return dates;
}

export function startOfDecade(date: Dayjs): Dayjs {
    return date.subtract(date.year() % 10, 'year');
}

export function endOfDecade(date: Dayjs): Dayjs {
    return date.add(10 - (date.year() % 10), 'year');
}

export function isSameDecade(date1: Dayjs, date2: Dayjs): boolean {
    return (
        date1.isSameOrAfter(startOfDecade(date2), 'year') && date1.isSameOrBefore(endOfDecade(date2), 'year')
    );
}

export function diff(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): number {
    return dayjs(date1).diff(date2, unit);
}

export function isSame(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): boolean {
    return dayjs(date1).isSame(date2, unit);
}

export function isAfter(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): boolean {
    return dayjs(date1).isAfter(date2, unit);
}

export function isSameOrAfter(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): boolean {
    return dayjs(date1).isSameOrAfter(date2, unit);
}

export function isBefore(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): boolean {
    return dayjs(date1).isBefore(date2, unit);
}

export function isSameOrBefore(
    date1: string | number | Date | Dayjs,
    date2: string | number | Date | Dayjs,
    unit: UnitType = 'd',
): boolean {
    return dayjs(date1).isSameOrBefore(date2, unit);
}

export function parseFreeDate(freeDate: FreeDate): Dayjs {
    const { day, month, year } = freeDate;

    return dayjs.utc(Date.UTC(year, month ?? 0, day ?? 1));
}

export function formatFreeDate(
    freeDate: FreeDate | null,
    format: [string, string, string] = ['DD', 'MMMM', 'YYYY'],
    separator = ' ',
): string {
    if (!freeDate) {
        return '';
    }

    const { day, month, year } = freeDate;
    const compiledFormat = zip([day, month, year], format)
        .filter(([value]) => value !== null)
        .map(([, format]) => format)
        .join(separator);

    return parseFreeDate(freeDate).format(compiledFormat).trim();
}
