import { DtgId } from '../dtg';
import { buildPath, CollectionType, pathFromType } from '../firestore';
import { buildUserDataCollectionPath } from '../user-data';

import { ReportData, ReportStatus } from './types';

export function buildReportData({
    dtgId,
    status = ReportStatus.pending,
    file = null,
    error = null,
    progress = 0,
    createdTimestamp = Date.now(),
    updatedTimestamp = Date.now(),
}: Partial<ReportData> & DtgId): ReportData {
    return {
        dtgId,
        status,
        file,
        error,
        progress,
        createdTimestamp,
        updatedTimestamp,
    };
}

export function buildReportCollectionPath({
    userId,
    docId,
}: {
    userId: string;
    docId: string | null;
}): string {
    const userDataPath = buildUserDataCollectionPath({ userId });

    return buildPath(userDataPath, pathFromType(CollectionType.report), docId);
}
