import { StoreErrors } from '../../classes/StoreResponseBuilder';
import { WritableRef } from '../../types/vue';

import { FormErrorHandler } from './types';

export function useFormErrorHandler<T extends StoreErrors = StoreErrors>({
    formError,
    fieldErrors,
    openToast,
}: {
    formError?: WritableRef<string | null>;
    fieldErrors?: WritableRef<T['fields']>;
    openToast: (message: string) => void;
}): FormErrorHandler<T> {
    function handleErrors(errors: T): void {
        const { fields, form, global } = errors;

        if (fields && fieldErrors) {
            fieldErrors.value = fields;
        }

        if (form && formError) {
            formError.value = form;
        }

        if (global) {
            openToast(global);
        }
    }

    function clearErrors(includingFields = true): void {
        if (includingFields && fieldErrors) {
            fieldErrors.value = {};
        }

        if (formError) {
            formError.value = null;
        }
    }

    return {
        handleErrors,
        clearErrors,
    };
}
