import { BlobData, BlobFormat } from './types';

export * from './types';

export async function readBlob<T extends BlobFormat>(blob: Blob, format: T): Promise<BlobData<T>> {
    return new Promise((resolve, reject) => {
        if (format === BlobFormat.blob) {
            resolve(blob as BlobData<T>);

            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', (event) => {
            const data = event.target?.result;

            if (!data) {
                reject();

                return;
            }

            resolve(data as BlobData<T>);
        });

        reader.addEventListener('error', () => {
            reject();
        });

        readBlobAs(reader, blob, format);
    });
}

function readBlobAs(reader: FileReader, blob: Blob, as: BlobFormat): void {
    switch (as) {
        case BlobFormat.arrayBuffer:
            reader.readAsArrayBuffer(blob);
            break;

        case BlobFormat.binaryString:
            reader.readAsBinaryString(blob);
            break;

        case BlobFormat.dataUrl:
            reader.readAsDataURL(blob);
            break;

        case BlobFormat.text:
            reader.readAsText(blob);
            break;
    }
}
