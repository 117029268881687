import { ref } from 'vue';

import { StoreErrors, StoreResponse } from '../../classes/StoreResponseBuilder';
import { ReadonlyRef, WritableRef } from '../../types/vue';
import { generateId } from '../../utils/generate-id';

import { FormSubmitHandler, FormValidationResult } from './types';
import { useFormErrorHandler } from './use-form-error-handler';

export function useFormSubmitHandler<TResult = undefined, TErrors extends StoreErrors = StoreErrors>({
    onSubmit,
    onSuccess,
    onError,

    formId = generateId(),
    submitting = ref(false),

    formError = ref<string | null>(null),
    fieldErrors = ref({}),
    openToast,
}: {
    onSubmit: () => Promise<StoreResponse<TResult, TErrors>>;
    onSuccess?: (result: TResult) => Promise<void> | void;
    onError?: (errors: TErrors) => Promise<void> | void;

    formId?: string;

    formError?: WritableRef<string | null>;
    fieldErrors?: ReadonlyRef<TErrors['fields']>;
    openToast: (message: string) => void;

    submitting?: WritableRef<boolean>;
}): FormSubmitHandler<TResult, TErrors> {
    const { handleErrors, clearErrors } = useFormErrorHandler<TErrors>({
        formError,
        fieldErrors,
        openToast,
    });

    async function submit(
        validate: (() => Promise<FormValidationResult>) | FormValidationResult = { valid: true, errors: {} },
    ): Promise<StoreResponse<TResult, TErrors> | null> {
        if (submitting.value) {
            return null;
        }

        submitting.value = true;

        const valid = typeof validate === 'function' ? await validate() : validate;

        return submitForm(valid).finally(() => {
            submitting.value = false;
        });
    }

    async function submitForm(
        validationResult: FormValidationResult,
    ): Promise<StoreResponse<TResult, TErrors> | null> {
        clearErrors(false);

        const { valid } = validationResult;

        if (!valid) {
            return null;
        }

        clearErrors();

        const response = await onSubmit();

        if (response.ok) {
            if (onSuccess) {
                await onSuccess(response.result);
            }

            return response;
        }

        handleErrors(response.error);

        if (onError) {
            await onError(response.error);
        }

        return response;
    }

    return {
        formId,
        formError,
        fieldErrors,
        submitting,

        submit,
        handleErrors,
        clearErrors,
    };
}
