import { createStore } from 'vuex-smart-module';

import { ModuleOptionsOf } from 'dtg-shared/store/types';
import { createAsyncService } from 'dtg-shared/utils/create-service';
import { truthy } from 'dtg-shared/utils/truthy';

import { ENV } from '@/utils/env';

import { CollectionsModule, createRootModule, FileModule, RootModule } from './create';
import { createLogger } from './logger';

const storeService = createAsyncService('store', async () => {
    const root = await createRootModule();
    const plugins = [createLogger()].filter(truthy);

    const store = createStore(root, {
        plugins,
        strict: false,
    });

    if (ENV.NODE_ENV === 'development') {
        // @ts-expect-error global export for debugging
        window.vuex = store;
    }

    return { store, root };
});

const { get: getStore, init: initStore } = storeService;

export { storeService, getStore, initStore };

export function getModule<K extends keyof ModuleOptionsOf<RootModule>>(
    key: K,
): ModuleOptionsOf<RootModule>[K] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getStore().root.options.modules![key];
}

export function getCollection<K extends keyof ModuleOptionsOf<CollectionsModule>>(
    key: K,
): ModuleOptionsOf<CollectionsModule>[K] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getModule('collections').options.modules![key];
}

export function getFileModule<K extends keyof ModuleOptionsOf<FileModule>>(
    key: K,
): ModuleOptionsOf<FileModule>[K] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getModule('file').options.modules![key];
}
