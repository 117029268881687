import { ApiModule } from '../../classes/ApiModule';

import { API_GEOCODER_ADDRESS_SUGGESTIONS_ROUTE } from './routes';
import { ApiGeocoderFetchAddressSuggestionRequest, ApiGeocoderFetchAddressSuggestionResponse } from './types';

export class ApiGeocoderModule extends ApiModule {
    async fetchAddressSuggestions(
        params: ApiGeocoderFetchAddressSuggestionRequest,
    ): Promise<ApiGeocoderFetchAddressSuggestionResponse> {
        return this.api.get(`${API_GEOCODER_ADDRESS_SUGGESTIONS_ROUTE}`, { params });
    }
}
