import uniqBy from 'lodash/uniqBy';

export function arrayIncludes<T extends U, U>(array: Readonly<T[]>, value: U): value is T;
export function arrayIncludes<T, U>(array: Readonly<T[]>, value: U): U extends T ? boolean : false;

export function arrayIncludes<T, U>(array: Readonly<T[]>, value: U): boolean {
    // @ts-expect-error includes is too narrow
    return array.includes(value);
}

export function arrayify<T>(value: T | T[] | null | undefined): T[] {
    if (Array.isArray(value)) {
        return value;
    }

    return value ? [value] : [];
}

export function deArrayify<T>(value: T | T[]): T | null {
    if (!Array.isArray(value)) {
        return value;
    }

    return value[0] ?? null;
}

export function unique<T>(array: T[]): T[] {
    return [...new Set<T>(array)];
}

export function uniqueBy<T>(array: T[], predicate: ((item: T) => unknown) | keyof T): T[] {
    return uniqBy(array, predicate);
}

export function range(end: number): number[];
export function range(start: number, end: number): number[];

export function range(startOrEnd: number, end?: number): number[] {
    const actualEnd = typeof end === 'undefined' ? startOrEnd : end - startOrEnd;
    const start = typeof end === 'undefined' ? 0 : startOrEnd;

    return Array(actualEnd)
        .fill(null)
        .map((_, index) => start + index);
}
