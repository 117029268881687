import { objEntries, objFromEntries, objPick } from 'dtg-shared/utils/object';

import {
    LegalObligationAdditionalData,
    LegalObligationData,
    LegalObligationField,
    LegalObligationFieldType,
    LegalObligationFormData,
    legalObligationFormDataKeys,
    LegalObligationStatus,
} from './types';

export function buildLegalObligationData({
    listItemId = null,
    status = null,
    comment = '',
    createdTimestamp = Date.now(),
    valid = false,
    additionalData = null,
    custom = false,
    groupId = null,
    information = null,
    visibleInReport = true,
    files = [],
    filesVisibleInReport = false,
}: Partial<LegalObligationData> = {}): LegalObligationData {
    return {
        listItemId,
        status,
        comment,
        createdTimestamp,
        valid,
        additionalData,
        custom,
        groupId,
        information,
        visibleInReport,
        files,
        filesVisibleInReport,
    };
}

export function buildLegalObligationAdditionalData({
    situation = '',
    reference = '',
    madeBy = '',
    madeByDate = null,
}: Partial<LegalObligationAdditionalData> = {}): LegalObligationAdditionalData {
    return {
        situation,
        reference,
        madeBy,
        madeByDate,
    };
}

export function buildLegalObligationAdditionalDataForm({
    data,
    fields,
}: {
    data: Partial<LegalObligationAdditionalData> | null;
    fields: LegalObligationField[];
}): Partial<LegalObligationAdditionalData> {
    const dataWithFallback = buildLegalObligationAdditionalData(data ?? {});
    const entries = objEntries(dataWithFallback).filter(([key]) => {
        return fields.some((field) => field.key === key);
    });

    return objFromEntries(entries) as Partial<LegalObligationAdditionalData>;
}

export function buildLegalObligationFormData(): LegalObligationFormData {
    return objPick<LegalObligationData, keyof LegalObligationFormData>(
        buildLegalObligationData(),
        legalObligationFormDataKeys,
    );
}

export function buildLegalObligationCustomFields(): LegalObligationField[] {
    return [
        {
            key: 'situation',
            type: LegalObligationFieldType.situation,
            label: 'Situation',
            placeholder: 'Situation libre',
            required: true,
        },
    ];
}

const registerNumberRegEx = /^[a-zA-Z]{2}\d{7}$/;

export function buildLegalObligationDataFromBuildingRegisterNumber(
    registerNumber: string,
): Pick<LegalObligationData, 'status' | 'additionalData'> {
    if (registerNumber && registerNumberRegEx.test(registerNumber)) {
        return {
            status: LegalObligationStatus.delivered,
            additionalData: {
                reference: registerNumber,
                situation: 'Doc. exploitable',
            },
        };
    }

    return {
        status: null,
        additionalData: null,
    };
}
