import { Id } from 'dtg-shared/types/data';
import { PdfOrientation } from 'dtg-shared-report/types';

export type BuildingPlanData = {
    name: string;
    buildingId: string;
    file: BuildingPlanFile | null;

    visibleInReport: boolean;
    orientation: PdfOrientation;

    createdTimestamp: number;
    valid: boolean;
};

export type BuildingPlanForm = Pick<BuildingPlanData, 'name' | 'file' | 'visibleInReport' | 'orientation'>;

export type BuildingPlanFile = Id & {
    type: BuildingPlanFileType;
    name: string;
};

export enum BuildingPlanFileType {
    file = 'file',
    photo = 'photo',
}
