import { boolean, mixed, number, string } from 'yup';

import { FreeDate } from 'dtg-shared/types/data';
import { arrayIncludes, unique } from 'dtg-shared/utils/array';
import { objFromEntries, objValues } from 'dtg-shared/utils/object';
import { truthy } from 'dtg-shared/utils/truthy';
import {
    combineConditionsOf,
    defineSchemaWithConditions,
    makeConditionsOf,
    objectSchema,
    ValidationSchemaWithConditions,
} from 'dtg-shared/validation';

import {
    BuildingAuditFile,
    BuildingAuditStatus,
    BuildingAuditType,
    BuildingData,
    BuildingFormData,
    BuildingFormEnergyBalanceData,
    BuildingType,
} from './types';

export function buildingValidationSchema(): ValidationSchemaWithConditions<BuildingFormData> {
    const sharedSchema = buildSharedSchema();

    return defineSchemaWithConditions<BuildingFormData>()(
        {
            type: sharedSchema.schema.type.required(),
            name: string().label('Nom composant de l’ensemble').defined(),
            heatingMode: string().label('Mode de chauffage').nullable().defined(),
            level: string().label('Nbre de niveaux').defined(),
            undergroundLevel: string().label('Nbre de niveaux SSOL').defined(),
            hasInteriorElevator: boolean().label('Ascenseur').nullable().defined(),
            hasBasementElevator: boolean().label('Ascenseur').nullable().defined(),
            roofType: string().label('Toiture').nullable().defined(),
            entriesNumber: number().label("Nombre d'entrées").nullable().defined(),
            fireSafetyType: string().label('Famille d’immeuble').nullable().defined(),
            hasAttic: boolean().label('Combles').nullable().defined(),
            hasLocalTrash: boolean().label('Local poubelles').nullable().defined(),
            hasGarage: boolean().label('Garage').nullable().defined(),
            hasCellar: boolean().label('Cave').nullable().defined(),
            hasLocalServices: boolean().label('Locaux techniques').nullable().defined(),
            hasTransformationStation: boolean().label('Poste transformateur').nullable().defined(),
            yearConstruction: sharedSchema.schema.yearConstruction,
            dateConstruction: sharedSchema.schema.dateConstruction,
            hasCirculation: boolean().label('Circulation').nullable().defined(),
            hasExteriorParking: boolean().label('Parking').nullable().defined(),
            hasBasementParking: boolean().label('Parking').nullable().defined(),
            hasOutbuilding: boolean().label('Dépendances').nullable().defined(),
            hasGate: boolean().label('Portail').nullable().defined(),
            hasGarden: boolean().label('Espace vert').nullable().defined(),
            hasPool: boolean().label('Local piscine').nullable().defined(),
        },
        {
            level: sharedSchema.isInteriorCondition.extend({
                then: string().required(),
            }),
            undergroundLevel: sharedSchema.isBasementCondition.extend({
                then: string().required(),
            }),
            entriesNumber: sharedSchema.isInteriorCondition.extend({
                then: number().nullable().required(),
            }),
            ...sharedSchema.conditions,
        },
    );
}

export function buildingValidationSchemaEnergyBalance(): ValidationSchemaWithConditions<BuildingFormEnergyBalanceData> {
    const when = makeConditionsOf<BuildingData>();
    const combineConditions = combineConditionsOf<BuildingFormEnergyBalanceData>();
    const sharedSchema = buildSharedSchema();

    const baseSchema = {
        type: sharedSchema.schema.type,
        yearConstruction: sharedSchema.schema.yearConstruction,
        dateConstruction: sharedSchema.schema.dateConstruction,
        auditType: mixed()
            .label('Type de diagnostic')
            .oneOf([BuildingAuditType.audit, BuildingAuditType.dpe, null]),
        auditFile: objectSchema<BuildingAuditFile>().label('Document du diagnostic').nullable(),
        auditProvidedBy: string().label('Information communiquée par'),
        wasAuditPerformed: mixed()
            .oneOf([...objValues(BuildingAuditStatus), null])
            .label('Est-ce qu’un bilan énergétique a été réalisé?')
            .nullable(),
        auditAttendant: string().label('Réalisé par'),
        auditDate: string().label('Date du document').nullable(),
        energyConsumption: number().label('Consommations énergétiques').nullable().moreThan(0),
        energyConsumptionClass: string().label('Note classement').nullable(),
        gasEmission: number().label('Gaz à effet de serre').nullable().moreThan(0),
        gasEmissionClass: string().label('Note classement').nullable(),
    };

    const wasAuditPerformedCondition = when({
        key: 'wasAuditPerformed',
        is: (value) => value === BuildingAuditStatus.performed,
    });

    const isAuditInProgress = when({
        key: 'wasAuditPerformed',
        is: (value) => value === BuildingAuditStatus.inProgress,
    });

    const needsAuditPerformedInfoCondition = combineConditions(
        sharedSchema.isInteriorCondition,
        wasAuditPerformedCondition,
    );

    const needsAuditInProgressInfoCondition = combineConditions(
        sharedSchema.isInteriorCondition,
        isAuditInProgress,
    );

    const auditPerformedFields = [
        'auditType',
        'auditFile',
        'auditDate',
        'auditProvidedBy',
        'auditAttendant',
        'energyConsumption',
        'energyConsumptionClass',
        'gasEmission',
        'gasEmissionClass',
    ] as const;
    const auditInProgressFields = ['auditType', 'auditProvidedBy', 'auditAttendant'] as const;
    const auditFields = unique([...auditPerformedFields, ...auditInProgressFields]);

    const conditionEntries = auditFields.map((key) => {
        const conditions = [
            arrayIncludes(auditPerformedFields, key) ? needsAuditPerformedInfoCondition : null,
            arrayIncludes(auditInProgressFields, key) ? needsAuditInProgressInfoCondition : null,
        ]
            .filter(truthy)
            .map((condition) =>
                condition.extend({
                    then: baseSchema[key].required(),
                }),
            );

        return [key, conditions] as const;
    });

    return defineSchemaWithConditions<BuildingFormEnergyBalanceData>()(
        {
            type: baseSchema.type.defined(),
            yearConstruction: baseSchema.yearConstruction.defined(),
            dateConstruction: baseSchema.dateConstruction.defined(),
            auditType: baseSchema.auditType.defined(),
            auditFile: baseSchema.auditFile.defined(),
            auditProvidedBy: baseSchema.auditProvidedBy.defined(),
            wasAuditPerformed: baseSchema.wasAuditPerformed.defined(),
            auditAttendant: baseSchema.auditAttendant.defined(),
            auditDate: baseSchema.auditDate.defined(),
            energyConsumption: baseSchema.energyConsumption.defined(),
            energyConsumptionClass: baseSchema.energyConsumptionClass.defined(),
            gasEmission: baseSchema.gasEmission.defined(),
            gasEmissionClass: baseSchema.gasEmissionClass.defined(),
        },
        {
            ...sharedSchema.conditions,
            ...objFromEntries(conditionEntries),
            wasAuditPerformed: sharedSchema.isInteriorCondition.extend({
                then: baseSchema.wasAuditPerformed.required(),
            }),
        },
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function buildSharedSchema() {
    const when = makeConditionsOf<Pick<BuildingData, 'type'>>();

    const isInteriorCondition = when({
        key: 'type',
        is: (value): boolean => {
            return value === BuildingType.interior;
        },
    });

    const isBasementCondition = when({
        key: 'type',
        is: (value): boolean => {
            return value === BuildingType.basement;
        },
    });

    const dateConstruction = objectSchema<FreeDate>()
        .label('Date exacte du permis de construire')
        .nullable()
        .test('dateConstructionFull', 'La date complète doit être saisie', (value) => {
            const date = value as FreeDate | null;

            return !date || (['day', 'month', 'year'] as const).every((field) => date[field] !== null);
        });

    return {
        schema: {
            type: mixed()
                .oneOf([...Object.values(BuildingType), null])
                .label('Type'),
            yearConstruction: string().label('Période de construction').nullable().defined(),
            dateConstruction: dateConstruction.defined(),
        },
        conditions: {
            yearConstruction: isInteriorCondition.extend({
                then: string().nullable().required(),
            }),
        },
        isInteriorCondition,
        isBasementCondition,
    } as const;
}
