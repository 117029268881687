import { createRouter, createWebHistory, Router } from 'vue-router';

import { ENV } from '@/utils/env';

import { routes } from './routes';

export function initRouter(): Router {
    return createRouter({
        history: createWebHistory(ENV.BASE_URL),
        routes,
    });
}
