// When type is capacitor, the key should match the plugin's name in PascalCase,
// so it can be passed to Capacitor.isPluginAvailable
export const plugins = {
    SplashScreen: { plugin: async () => import('@capacitor/splash-screen'), type: 'capacitor' },
    StatusBar: { plugin: async () => import('@capacitor/status-bar'), type: 'capacitor' },
    Browser: { plugin: async () => import('@capacitor/browser'), type: 'capacitor' },
    InAppBrowser: { plugin: async () => import('@awesome-cordova-plugins/in-app-browser'), type: 'cordova' },
    Camera: { plugin: async () => import('@capacitor/camera'), type: 'capacitor' },
    App: { plugin: async () => import('@capacitor/app'), type: 'capacitor' },
    Network: { plugin: async () => import('@capacitor/network'), type: 'capacitor' },
    Device: { plugin: async () => import('@capacitor/device'), type: 'capacitor' },
} as const;
