import { useRetryRequest } from 'dtg-api/composables/use-retry-request';
import { openToast } from 'dtg-components';
import { withCachedPromise } from 'dtg-shared/utils/with-cached-promise';

import { getTokens } from '@/services/api';

import { useStoreModule } from '@/store';

import { logError } from '@/utils/log-error';
import { ERROR } from '@/utils/toast-message';

import { useAuthLogout } from './use-auth-logout';

let refreshTokensCached: () => Promise<boolean>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuthRefreshTokens() {
    const {
        actions: { refreshTokens },
    } = useStoreModule('auth');

    const { logoutWithRedirect } = useAuthLogout();

    const { refreshTokensAndRetryRequest } = useRetryRequest({ refreshTokens });

    const tokens = getTokens();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    refreshTokensCached = refreshTokensCached ?? withCachedPromise(refreshTokensInternal, true);

    // Used as part of error handling of calls to firebase API
    // For cases where it doesn't make sense to retry the call
    async function refreshTokensInternal(): Promise<boolean> {
        if (!tokens.refreshToken) {
            return false;
        }

        try {
            await refreshTokens();

            return true;
        } catch (error) {
            await logoutWithRedirect();

            void openToast('Autorisation expirée');

            return false;
        }
    }

    // Used to wrap calls to firebase API to handle errors
    // For cases where it makes sense to retry the call
    async function wrapWithRefreshRetry(action: () => Promise<void>, errorMessage = ERROR): Promise<boolean> {
        try {
            await action();
        } catch (error) {
            logError(error);

            if (!tokens.refreshToken) {
                return false;
            }

            if (!(await refreshTokensCached())) {
                return false;
            }

            try {
                await action();
            } catch (error) {
                logError(error);

                void openToast(errorMessage);

                return false;
            }
        }

        return true;
    }

    return {
        refreshTokensAndRetryRequest,
        refreshTokens: refreshTokensCached,
        wrapWithRefreshRetry,
    };
}
