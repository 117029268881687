/* eslint-disable no-template-curly-in-string */

import { setLocale } from 'yup';
import { fr } from 'yup-locales';

export * from './consts';
export * from './types';
export * from './conditions';
export * from './rules';
export * from './schema';
export * from './utils';

export function initializeValidation(): void {
    setLocale(fr);

    setLocale({
        mixed: {
            default: 'champ invalide',
            required: 'champ obligatoire',
            oneOf: "${path} doit être l'une des valeurs suivantes: ${values}",
            notOneOf: "${path} ne doit pas être l'une des valeurs suivantes\xa0: ${values}",
        },
        string: {
            length: '${path} doit être exactement ${length} caractères',
            min: '${path} doit être au moins ${min} caractères',
            max: '${path} doit être au plus ${max} caractères',
            matches: '${path} doit correspondre à ce qui suit\xa0: "${regex}"',
            email: '${path} doit être un email valide',
            url: '${path} doit être une URL valide',
            trim: '${path} doit être une chaîne garnie',
            lowercase: '${path} doit être une chaîne en minuscule',
            uppercase: '${path} doit être une chaîne de majuscules',
        },
        number: {
            min: '${path} doit être supérieure ou égale à ${min}',
            max: '${path} doit être inférieur ou égal à ${max}',
            lessThan: '${path} doit être inférieure à ${less}',
            moreThan: '${path} doit être supérieure à ${more}',
            positive: '${path} doit être un nombre positif',
            negative: '${path} doit être un nombre négatif',
            integer: '${path} doit être un entier',
        },
        date: {
            min: '${path} champ doit être au plus tard ${min}',
            max: '${path} doit être au plus tôt ${max}',
        },
        object: {
            noUnknown: "${path} ne peut pas avoir des clés non spécifiées dans la forme de l'objet",
        },
        array: {
            min: 'champ obligatoire, ${min} minimum',
            max: 'champ doit avoir inférieur ou égal à ${max} articles',
        },
    });
}
