import { roundToPrecision } from 'dtg-shared/utils/number';

import { MATERIAL_NOT_APPLICABLE_VALUE } from './consts';
import { ControlPointData, CurativeItem } from './types';

export function getControlPointNeedsRepair(data: ControlPointData, curatives: CurativeItem[]): boolean {
    const { curative } = data;

    return curatives.some(({ value, isSevere }) => value === curative && isSevere);
}

// eslint-disable-next-line complexity
export function calculateModifieControlPointData({
    newData,
    oldData,
}: {
    newData: Readonly<Partial<ControlPointData>>;
    oldData: Readonly<ControlPointData>;
}): Partial<ControlPointData> {
    const modifiedData = { ...newData };

    const { costs, qty, totalCost } = modifiedData;
    const precision = 2;
    const hasUpdatedCost = typeof costs !== 'undefined';
    const hasUpdatedQty = typeof qty !== 'undefined';
    const hasUpdatedTotalCost = typeof totalCost !== 'undefined';
    const currencyValue = newData.currency ?? oldData.currency;

    if (hasUpdatedCost || hasUpdatedQty) {
        const costsValue = hasUpdatedCost ? costs : oldData.costs;
        const qtyValue = hasUpdatedQty ? qty : oldData.qty;

        if (hasUpdatedCost && costsValue === null) {
            modifiedData.totalCost = null;
        } else if (costsValue !== null) {
            modifiedData.totalCost = roundToPrecision(costsValue * (qtyValue ?? 1), precision);
        }
    } else if (hasUpdatedTotalCost) {
        modifiedData.qty = null;
    }

    // In case the user resets the cost, we don't update the currency
    if ((typeof costs === 'number' || typeof totalCost === 'number') && !currencyValue) {
        modifiedData.currency = '€';
    }

    if (newData.family === '') {
        modifiedData.element = '';
    }

    if (newData.comment === '') {
        modifiedData.commentResolved = false;
    }

    if (
        typeof newData.material !== 'undefined' &&
        newData.material.includes(MATERIAL_NOT_APPLICABLE_VALUE) &&
        newData.material.length > 1
    ) {
        modifiedData.material = [MATERIAL_NOT_APPLICABLE_VALUE];
    }

    return modifiedData;
}
