
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import { DtgDropdown, DtgDropdownItem, events, getName, useBem } from 'dtg-components';
import { truthy } from 'dtg-shared/utils/truthy';

import { useAuth } from '@/composables/use-auth';

import { Route } from '@/router';

import { DtgHeaderDropdownEvents } from './types';

import styles from './style.module.scss';

export const props = {
    backofficeLinkProps: {
        type: Object,
        default: () => ({}),
    },

    profileLinkProps: {
        type: Object,
        default: () => ({}),
    },
} as const;

export default defineComponent({
    name: getName(styles),

    components: {
        DtgDropdown,
        DtgDropdownItem,
    },

    props,

    emits: events(['close', 'logout'])<DtgHeaderDropdownEvents>(),

    setup(props, { emit }) {
        const router = useRouter();
        const { user } = useAuth();

        const fullName = computed(() => {
            return [user.value?.firstName, user.value?.lastName].filter(truthy).join(' ');
        });

        const rolesLabel = computed(() => {
            return user.value?.rolesLabel ?? '';
        });

        const aboutUrl = computed(() => router.resolve({ name: Route.about }));
        const supportUrl = router.resolve({ name: Route.support });

        function onLogoutClick(): void {
            emit('logout');
        }

        return {
            b: useBem(styles),

            aboutUrl,
            supportUrl,
            rolesLabel,
            fullName,

            onLogoutClick,
        };
    },
});
