/* eslint-disable no-restricted-imports */

import { RouteRecordRaw } from 'vue-router';

import { arrayify } from 'dtg-shared/utils/array';
import { truthy } from 'dtg-shared/utils/truthy';

import DtgPageList from '@/components/pages/page-list/component.vue';

import {
    PAGE_CONTROL_POINTS_BUILDING_ID,
    PAGE_CONTROL_POINTS_QUERY_ID,
    PAGE_CONTROL_POINTS_QUERY_LIST,
} from '../consts';
import { Route, RouteCalculationsQuery, RouteControlPointQuery } from '../types';

export const dtgRoutes: RouteRecordRaw[] = [
    {
        path: '/list',
        name: Route.list,
        component: DtgPageList,
        meta: { keepAlive: true },
    },
    {
        path: '/dashboard/:id',
        name: Route.dashboard,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg-dashboard" */ '../../components/pages/page-dashboard/component.vue'
            ),
    },
    {
        path: '/information/:id',
        name: Route.information,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-information/component.vue'
            ),
    },
    {
        path: '/building-complex/:id',
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-building-complex/component.vue'
            ),
        children: [
            {
                path: '',
                name: Route.buildingComplex,
                component: async () =>
                    import(
                        /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-building-complex-fields/component.vue'
                    ),
            },
            {
                path: 'list',
                name: Route.buildingComplexList,
                component: async () =>
                    import(
                        /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-building-complex-list/component.vue'
                    ),
            },
        ],
    },
    {
        path: '/energy-balance/:id',
        name: Route.energyBalance,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-energy-balance/component.vue'
            ),
    },
    {
        path: '/location/:id',
        name: Route.location,
        component: async () =>
            import(/* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-location/component.vue'),
    },
    {
        path: '/document/:id',
        name: Route.documents,
        component: async () =>
            import(/* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-documents/component.vue'),
        meta: { keepAlive: true },
    },
    {
        path: '/photo/:id',
        name: Route.photos,
        component: async () =>
            import(/* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-photos/component.vue'),
    },
    {
        path: '/control-points/:id',
        name: Route.controlPoints,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-control-points/component.vue'
            ),
        props: (to): { query: RouteControlPointQuery } => {
            const controlPointId = to.query[PAGE_CONTROL_POINTS_QUERY_ID];
            const isListMode = truthy(to.query[PAGE_CONTROL_POINTS_QUERY_LIST]);
            const buildingIds = arrayify(to.query[PAGE_CONTROL_POINTS_BUILDING_ID]).filter(truthy);

            return {
                query: {
                    controlPointId: controlPointId ? String(controlPointId) : null,
                    isListMode,
                    buildingIds,
                },
            };
        },
        meta: { keepAlive: true },
    },
    {
        path: '/amelioration/:id',
        name: Route.improvementWorks,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-improvement-works/component.vue'
            ),
    },
    {
        path: '/unvisited-rooms/:id',
        name: Route.unvisitedRooms,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-unvisited-rooms/component.vue'
            ),
    },
    {
        path: '/legal-obligations/:id',
        name: Route.legalObligations,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg" */ '../../components/pages/page-legal-obligations/component.vue'
            ),
        meta: { keepAlive: true },
    },
    {
        path: '/overview/:id',
        name: Route.overview,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg-secondary" */ '../../components/pages/page-overview/component.vue'
            ),
    },
    {
        path: '/conclusion/:id',
        name: Route.conclusion,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg-secondary" */ '../../components/pages/page-conclusion/component.vue'
            ),
    },
    {
        path: '/calculation/:id',
        name: Route.calculation,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-dtg-secondary" */ '@/components/pages/page-calculation/component.vue'
            ),
        props: (to): { query: RouteCalculationsQuery } => {
            const controlPointId = to.query[PAGE_CONTROL_POINTS_QUERY_ID];

            return {
                query: {
                    controlPointId: controlPointId ? String(controlPointId) : null,
                },
            };
        },
        meta: { keepAlive: true },
    },
];
