import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_sidebar_item = _resolveComponent("dtg-sidebar-item")!

  return (_openBlock(), _createElementBlock("aside", {
    ref: "root",
    class: _normalizeClass(_ctx.b({ view: _ctx.view, open: _ctx.open })),
    onScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onScroll()))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.b('inner'))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (item, index) => {
        return (_openBlock(), _createBlock(_component_dtg_sidebar_item, _mergeProps({
          key: item.name,
          ref_for: true,
          ref: _ctx.setRefByIndex(index),
          class: _ctx.b('item')
        }, item, { view: _ctx.view }), null, 16, ["class", "view"]))
      }), 128))
    ], 2)
  ], 34))
}