import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_layout_base = _resolveComponent("dtg-layout-base")!

  return (_openBlock(), _createBlock(_component_dtg_layout_base, {
    class: _normalizeClass(_ctx.b()),
    loading: _ctx.loading
  }, {
    "before-inner-content": _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-content")
    ]),
    content: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    "after-inner-content": _withCtx(() => [
      _renderSlot(_ctx.$slots, "after-content")
    ]),
    _: 3
  }, 8, ["class", "loading"]))
}