import { ApiResponseSuccess } from 'dtg-api/classes/Api';
import { ReportData } from 'dtg-firebase/modules/report';
import { Id } from 'dtg-shared/types/data';

export type ExposedFunctions = {
    logToNodeConsole: (message: string, context?: object) => Promise<void>;
    logProgress: () => Promise<void>;
};

export enum PdfOrientation {
    landscape = 'landscape',
    portrait = 'portrait',
}

export type PdfQueryParams = {
    dtgId: string;
    type: PdfType;
};

export type PdfGenerateResponse = ApiResponseSuccess<PdfGenerateResult>;
export type PdfGenerateResult = {
    docPath: string;
};

export type PdfPollQueryParams = {
    docPath: string;
};
export type PdfPollResponse = ApiResponseSuccess<PdfPollResult>;
export type PdfPollResult = Id & Omit<ReportData, 'file'> & { file: PdfPollResultFile | null };
export type PdfPollResultFile = { url: string; name: string };

export enum PdfType {
    trame = 'trame',
    test = 'test',
    technique = 'technique',
    pppt = 'pppt',
}

export type PdfToEmbed = {
    id: string;
    url: string;
    pageDefinitions: PdfToEmbedPageDefinition[];
};

export type PdfToEmbedPageDefinition = {
    x: number;
    y: number;
    width: number;
    height: number;
};
