/* eslint-disable max-classes-per-file */

import { truthy } from '../truthy';

export class EnvValidationError extends Error {
    constructor(key: string, value: string, message: string, keys: string[]) {
        const messageParts = [[`Invalid ENV.${key} value "${value}"`, message].filter(truthy).join(': ')];

        if (keys.length > 1) {
            messageParts.push(`(${formatReferencedKeys(keys)})`);
        }

        super(messageParts.join(' '));
    }
}

export class EnvReferenceLoopError extends Error {
    constructor(keys: string[]) {
        super(`Found a reference loop in env variables: ${formatReferencedKeys(keys)}`);
    }
}

function formatReferencedKeys(keys: string[]): string {
    return keys.map((key) => `ENV.${key}`).join(' -> ');
}
