import firebase from 'firebase/compat/app';

import { KeyValueStorage } from '@/services/key-value-storage';

import { logError } from '@/utils/log-error';

import {
    Upload,
    UploadData,
    UploadDataWithMeta,
    UploadEventHandler,
    UploadMeta,
    UploadTaskItem,
} from './types';
import { addEventHandler } from './utils';

export class FirebaseUploadTasks {
    private uploadData: Map<string, UploadDataWithMeta> = new Map();
    private readonly uploadTasks: Map<string, UploadTaskItem> = new Map();

    private readonly persistentStorage: KeyValueStorage;

    constructor(persistentStorage: KeyValueStorage) {
        this.persistentStorage = persistentStorage;
    }

    async initialize(): Promise<void> {
        await this.persistentStorage.initialize();

        try {
            this.uploadData = new Map(await this.persistentStorage.entries());
        } catch (error) {
            logError(error);
        }
    }

    async addUpload({
        path,
        upload,
        task,
        eventHandler,
    }: {
        path: string;
        upload: UploadData;
        task: firebase.storage.UploadTask;
        eventHandler?: UploadEventHandler;
    }): Promise<void> {
        const pendingUpload = this.getUpload({ path });

        if (pendingUpload?.task) {
            pendingUpload.task.cancel();
        }

        const meta = this.buildMetaData(path);

        await this.storeUpload({ path, upload: { ...upload, meta }, task, eventHandler });

        if (eventHandler) {
            addEventHandler(task, eventHandler);
        }

        await task.then(
            async () => {
                await this.removeUpload({ path });
            },
            (error) => {
                try {
                    // @ts-expect-error for easier debug
                    // eslint-disable-next-line no-underscore-dangle
                    error.__CUSTOM_DATA__ = JSON.stringify(error.customData);
                } catch {
                    // ignore error
                }

                logError(error);

                // eslint-disable-next-line @typescript-eslint/no-throw-literal
                throw error;
            },
        );
    }

    async removeUpload({ path }: { path: string }): Promise<void> {
        this.uploadData.delete(path);
        this.uploadTasks.delete(path);

        await this.persistentStorage.delete(path).catch(logError);
    }

    pauseUpload({ path }: { path: string }): void {
        const pendingUpload = this.getUpload({ path });

        if (pendingUpload?.task) {
            pendingUpload.task.cancel();
        }
    }

    async cancelUpload({ path }: { path: string }): Promise<void> {
        const pendingUpload = this.getUpload({ path });

        if (pendingUpload?.task) {
            pendingUpload.task.cancel();
        }

        await this.removeUpload({ path });
    }

    pauseUploads(): void {
        this.uploadTasks.forEach(({ path }) => {
            this.pauseUpload({ path });
        });
    }

    getUpload({ path }: { path: string }): Upload | null {
        const uploadData = this.uploadData.get(path);

        return uploadData ? this.buildUpload(uploadData) : null;
    }

    getPendingUploads(): Upload[] {
        const stoppedTaskStates = [
            firebase.storage.TaskState.ERROR,
            firebase.storage.TaskState.CANCELED, // we cancel tasks to pause uploads
            firebase.storage.TaskState.PAUSED, // we don't use this state but might as well include it
        ];

        return Array.from(this.uploadData.values())
            .map((uploadData) => this.buildUpload(uploadData))
            .filter(({ task }) => !task || stoppedTaskStates.includes(task.snapshot.state));
    }

    private async storeUpload({
        path,
        upload,
        task,
        eventHandler,
    }: {
        path: string;
        upload: UploadDataWithMeta;
        task: firebase.storage.UploadTask;
        eventHandler?: UploadEventHandler;
    }): Promise<void> {
        this.uploadData.set(path, upload);
        this.uploadTasks.set(path, { path, task, eventHandler });

        await this.persistentStorage.set(upload.path, upload).catch(logError);
    }

    private buildUpload(data: UploadDataWithMeta): Upload {
        const uploadTask = this.uploadTasks.get(data.path);

        return {
            ...data,
            task: uploadTask?.task,
            eventHandler: uploadTask?.eventHandler,
        };
    }

    private buildMetaData(path: string): UploadMeta {
        const pathParts = path.split('/');

        return {
            timeCreated: new Date().toISOString(),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            name: pathParts[pathParts.length - 1]!,
        };
    }
}
