/*
 * Checks structural equality ignoring order of items in arrays.
 */

import isEqual from 'lodash/isEqual';

export function isEqualIgnoringOrder(a: unknown, b: unknown): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
        return (
            a.length === b.length &&
            a.every((itemA) => {
                return b.some((itemB) => isEqualIgnoringOrder(itemA, itemB));
            })
        );
    }

    return isEqual(a, b);
}
