import { ApiRequestConfig, ApiResponseError } from './Api';

export class ApiError extends Error {
    status: number | null;
    errors: ApiResponseError[] | null;
    config: ApiRequestConfig | null;

    constructor({
        message,
        status = null,
        errors = null,
        config = null,
    }: {
        message: string;
        status?: number | null;
        errors?: ApiResponseError[] | null;
        config?: ApiRequestConfig | null;
    }) {
        super(message);

        this.status = status;
        this.errors = errors;
        this.config = config;
    }

    get code(): string | number | null {
        return this.errors?.[0]?.code ?? null;
    }
}
