import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';

import { ApiUserRole } from 'dtg-api/modules/user';
import { useModalMessage } from 'dtg-components';
import { DtgStatus } from 'dtg-firebase/modules/dtg';

import { useAuth } from '@/composables/use-auth';
import { useDtgData } from '@/composables/use-dtg-data';

import { Route } from '@/router';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useInitDtgStatusUpdates() {
    const router = useRouter();
    const { user } = useAuth();
    const { dtg } = useDtgData();
    const modalMessage = useModalMessage();

    const status = computed(() => dtg.value?.data.status);
    const isValidator = computed(() => {
        return (
            user.value?.roles.includes(ApiUserRole.validator) &&
            dtg.value?.data.validatorId === user.value.uid
        );
    });

    function init(): void {
        watch(status, (status, prevStatus) => {
            if (status === DtgStatus.opened && prevStatus === DtgStatus.onValidation && isValidator.value) {
                modalMessage.open({
                    title: 'Attention\xa0!',
                    message:
                        'Le technicien vient d’annuler la mise en validation du DTG, vous allez perdre la visualisation mais vos commentaires sur le DTG seront conservées dans le presse-papier.',
                    onClose: async () => {
                        await router.push({ name: Route.list });
                    },
                });
            }
        });
    }

    return { init };
}
