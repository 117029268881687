import { Id } from 'dtg-shared/types/data';

import { ApiResponse, ApiResponseError } from '../../classes/Api';

export type ApiFileUploadProgress = {
    progress: number;
};

export type ApiFile = Id & {
    filename: string;
    size: number;
    mimeType: string;
    etag: string;
    createdAt: number;
    updatedAt: number;
};

export type ApiFileWithDownloadUrl = ApiFile & {
    downloadUrl: string;
};

export enum ApiFileErrorCode {
    notFound = 'file/not-found',
    uploadFailed = 'file/upload-failed',
}

/* Request types */

export type ApiFileCreateRequest = Id & {
    filename: string;
    mimeType: string;
    size: number;
    data: Blob;
};
export type ApiFileUploadResponse = ApiResponse<ApiFile, ApiResponseError<ApiFileErrorCode>>;

export type ApiFileDownloadUrlResponse = ApiResponse<string>;

export type ApiFileDeleteMultipleRequest = { ids: string[] };

export type ApiFileFetchMultipleRequest = { ids: string[] };
export type ApiFileFetchMultipleResponse = ApiResponse<
    ApiFileWithDownloadUrl[],
    ApiResponseError<ApiFileErrorCode>
>;
