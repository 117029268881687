import { watch } from 'vue';

import { useAuth } from '@/composables/use-auth';
import { useDtgData } from '@/composables/use-dtg-data';
import { useDtgListData } from '@/composables/use-dtg-list-data';
import { useNetworkStatus } from '@/composables/use-network-status';

import { useStoreCollection, useStoreModule } from '@/store';
import { DtgItem } from '@/store/collections/dtg';

import { PREFETCH_ERROR } from '@/utils/toast-message';

let isPrefetching = false;

// Bail on prefetching after some errors to prevent infinite attempts to fetch
let prefetchRetryFailsafe = 0;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDtgPrefetch() {
    const { fetchDtgs } = useDtgListData();
    const { fetchDtg } = useDtgData({ errorMessage: PREFETCH_ERROR });

    const {
        getters: { getItems, getIsFetching },
    } = useStoreCollection('dtg');

    const { getters: rules } = useStoreModule('rules');

    const { isLoggedIn } = useAuth();
    const { online } = useNetworkStatus();

    function initPrefetch(): void {
        prefetchRetryFailsafe = 5;

        watch(
            [isLoggedIn, online],
            ([isLoggedIn, online]) => {
                if (isLoggedIn && online) {
                    void startPrefetch();
                } else {
                    stopPrefetch();
                }
            },
            { immediate: true },
        );
    }

    async function startPrefetch(shouldFetchDtgs = true): Promise<void> {
        if (isPrefetching || prefetchRetryFailsafe <= 0) {
            return;
        }

        isPrefetching = true;

        if (shouldFetchDtgs) {
            await fetchDtgs();
        }

        await prefetchNextDtg();
    }

    function stopPrefetch(): void {
        isPrefetching = false;
    }

    async function prefetchNextDtg(): Promise<void> {
        if (!isPrefetching) {
            return;
        }

        const dtg = getItems().find(shouldPrefetchDtg);

        if (!dtg) {
            isPrefetching = false;

            return;
        }

        const success = await fetchDtg(dtg.id, false);

        if (!success) {
            // eslint-disable-next-line require-atomic-updates
            isPrefetching = false;
            prefetchRetryFailsafe -= 1;

            return;
        }

        await prefetchNextDtg();
    }

    function restartPrefetch(): void {
        void startPrefetch(false);
    }

    function shouldPrefetchDtg(dtg: DtgItem): boolean {
        return rules.canEditDtg(dtg) && !dtg.loaded && !getIsFetching(dtg.id);
    }

    return {
        initPrefetch,
        restartPrefetch,
    };
}
