import { AxiosInstance } from 'axios';

import { ApiModule } from '../ApiModule';

import { ApiBase } from './ApiBase';
import { addApiInterceptors } from './interceptors';
import { ApiRequestConfig } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
export class Api<T extends Record<string, ApiModule> = {}> extends ApiBase {
    readonly modules: T;

    constructor(
        {
            modules,
            logger,
            axios,
        }: {
            modules: T;
            logger: (error: unknown) => void;
            axios?: AxiosInstance;
        },
        config?: ApiRequestConfig,
    ) {
        super({ logger, axios }, config);
        this.modules = modules;

        addApiInterceptors(this, logger);

        this._initModules();
    }

    private _initModules(): void {
        Object.values(this.modules).forEach((module) => {
            module.setApi(this);
            module.setLogger(this.logger);
        });
    }
}
