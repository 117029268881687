import firebase from 'firebase/compat/app';

import { StorageErrorCode } from 'dtg-firebase/modules/storage';

import { UploadEventHandler, UploadEventStatus } from './types';

// TODO: unsubscribe when download is removed
export function addEventHandler(task: firebase.storage.UploadTask, eventHandler: UploadEventHandler): void {
    task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
            eventHandler({
                status: UploadEventStatus.progress,
                progress: snapshot.bytesTransferred / snapshot.totalBytes,
            });
        },
        (error) => {
            switch (error.code) {
                case StorageErrorCode.retryLimitExceeded:
                    eventHandler({
                        status: UploadEventStatus.progress,
                        progress: 0,
                    });

                    return;

                case StorageErrorCode.canceled:
                    eventHandler({
                        status: UploadEventStatus.canceled,
                    });

                    return;

                default:
                    eventHandler({
                        status: UploadEventStatus.error,
                        error,
                    });
            }
        },
        () => {
            eventHandler({
                status: UploadEventStatus.complete,
            });
        },
    );
}
