import { ListItemData, Valid } from 'dtg-shared/types/data';
import { KeysOfType } from 'dtg-shared/types/utils';
import { arrayIncludes } from 'dtg-shared/utils/array';
import { objValues } from 'dtg-shared/utils/object';

import { AttendantData, AttendantFormData } from '../attendant';
import { BuildingData, BuildingFormData } from '../building';
import { BuildingPlanData, BuildingPlanForm } from '../building-plan';
import { CheckLoadedData } from '../check-loaded';
import { ContactData, ContactFormData } from '../contact';
import { ControlPointData, ControlPointFormData } from '../control-point';
import { CourseData, CourseFormData } from '../course';
import { CustomElementData } from '../custom-elements';
import { DocumentData, DocumentFormData } from '../document';
import { DtgData } from '../dtg';
import { EnergyWorkData } from '../energy-work';
import { ImprovementWorkData } from '../improvement-work';
import { LegalObligationData, LegalObligationFormData } from '../legal-obligation';
import { ParcelData, ParcelFormData } from '../parcel';
import { PhotoData } from '../photo';
import { ReportData } from '../report';
import { UnvisitedRoomData, UnvisitedRoomFormData } from '../unvisited-room';
import { InvalidityRecordData } from '../validation';

export enum CollectionType {
    attendant = 'DtgAttendant',
    building = 'DtgBuilding',
    buildingPlan = 'buildingPlan',
    contact = 'DtgContact',
    controlPoint = 'DtgControlPoint',
    course = 'DtgCourse',
    document = 'DtgDocument',
    dtg = 'Dtg',
    energyWork = 'DtgEnergyWork',
    improvementWork = 'DtgImprovementWork',
    legalObligation = 'DtgLegalObligation',
    parcel = 'DtgParcel',
    photo = 'DtgPhoto',
    unvisitedRoom = 'DtgUnvisitedRoom',
    validation = 'DtgValidation',
    userData = 'UserData',
    customElement = 'UserData-ControlPoint-Family-CustomElement',
    checkDtgLoaded = 'CheckDtgLoaded',
    report = 'DtgReport',
    // Add additional types here (see wiki/firestore/adding-collections.md)
}

export const COLLECTION_PATH: { [K in CollectionType]: string } = {
    [CollectionType.attendant]: 'attendant',
    [CollectionType.building]: 'buildings',
    [CollectionType.buildingPlan]: 'buildingPlans',
    [CollectionType.contact]: 'contacts',
    [CollectionType.controlPoint]: 'controlPoints',
    [CollectionType.course]: 'course',
    [CollectionType.document]: 'documents',
    [CollectionType.dtg]: 'dtg',
    [CollectionType.energyWork]: 'energyWorks',
    [CollectionType.improvementWork]: 'improvementWorks',
    [CollectionType.legalObligation]: 'legalObligations',
    [CollectionType.parcel]: 'parcels',
    [CollectionType.photo]: 'photos',
    [CollectionType.unvisitedRoom]: 'unvisitedRooms',
    [CollectionType.validation]: 'validation',
    [CollectionType.userData]: 'userData',
    [CollectionType.customElement]: 'customElements',
    [CollectionType.checkDtgLoaded]: 'checkLoaded',
    [CollectionType.report]: 'report',
    // Add additional paths here (see wiki/firestore/adding-collections.md)
};

export type CollectionTypeToDataMap = {
    [CollectionType.attendant]: AttendantData;
    [CollectionType.building]: BuildingData;
    [CollectionType.buildingPlan]: BuildingPlanData;
    [CollectionType.contact]: ContactData;
    [CollectionType.controlPoint]: ControlPointData;
    [CollectionType.course]: CourseData;
    [CollectionType.document]: DocumentData;
    [CollectionType.dtg]: DtgData;
    [CollectionType.energyWork]: EnergyWorkData;
    [CollectionType.improvementWork]: ImprovementWorkData;
    [CollectionType.legalObligation]: LegalObligationData;
    [CollectionType.parcel]: ParcelData;
    [CollectionType.photo]: PhotoData;
    [CollectionType.unvisitedRoom]: UnvisitedRoomData;
    [CollectionType.validation]: InvalidityRecordData;
    [CollectionType.userData]: Record<string, never>;
    [CollectionType.customElement]: CustomElementData;
    [CollectionType.checkDtgLoaded]: CheckLoadedData;
    [CollectionType.report]: ReportData;
    // Add additional data types here (see wiki/firestore/adding-collections.md)
};

export type CollectionValidatable = KeysOfType<
    {
        [K in keyof CollectionTypeToDataMap]: CollectionTypeToDataMap[K] extends Valid ? true : false;
    },
    true
>;

export type CollectionListLike = KeysOfType<
    {
        [K in keyof CollectionTypeToDataMap]: CollectionTypeToDataMap[K] extends ListItemData ? true : false;
    },
    true
>;

export type CollectionTypeToFormDataMap = {
    [CollectionType.building]: BuildingFormData;
    [CollectionType.buildingPlan]: BuildingPlanForm;
    [CollectionType.contact]: ContactFormData;
    [CollectionType.attendant]: AttendantFormData;
    [CollectionType.course]: CourseFormData;
    [CollectionType.controlPoint]: ControlPointFormData;
    [CollectionType.document]: DocumentFormData;
    [CollectionType.legalObligation]: LegalObligationFormData;
    [CollectionType.parcel]: ParcelFormData;
    [CollectionType.unvisitedRoom]: UnvisitedRoomFormData;
    // Add additional form types here for usage in firebase-functions's TrivialValidation (see wiki/firestore/adding-collections.md)
};

const nonBuildableCollectionTypes = [
    CollectionType.userData,
    CollectionType.validation,
    CollectionType.report,
] as const;

export const buildableCollectionTypes = objValues(CollectionType).filter(
    (type): type is BuildableCollectionTypes => !arrayIncludes(nonBuildableCollectionTypes, type),
);

export type NonBuildableCollectionType = typeof nonBuildableCollectionTypes[number];

export type BuildableCollectionTypes = Exclude<keyof CollectionTypeToDataMap, NonBuildableCollectionType>;

export const photoParentCollections = [
    CollectionType.dtg,
    CollectionType.controlPoint,
    CollectionType.unvisitedRoom,
    CollectionType.buildingPlan,
] as const;

export type PhotoParentCollection = typeof photoParentCollections[number];

export enum FirestoreErrorCode {
    cancelled = 'cancelled',
    unknown = 'unknown',
    invalidArgument = 'invalid-argument',
    deadlineExceeded = 'deadline-exceeded',
    notFound = 'not-found',
    alreadyExists = 'already-exists',
    permissionDenied = 'permission-denied',
    resourceExhausted = 'resource-exhausted',
    failedPrecondition = 'failed-precondition',
    aborted = 'aborted',
    outOfRange = 'out-of-range',
    unimplemented = 'unimplemented',
    internal = 'internal',
    unavailable = 'unavailable',
    dataLoss = 'data-loss',
    unauthenticated = 'unauthenticated',
}

export type ParentId<T extends CollectionType = CollectionType> = {
    parentId: string;
    parentType: T;
};
