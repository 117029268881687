import { IonicVue } from '@ionic/vue';
import { createApp } from 'vue';

import { initStore } from '@/store';

import { initRouter } from '@/router/init';

import App from './App.vue';
import { initSentry } from './init-sentry';

export async function initApp(): Promise<void> {
    const app = createApp(App);
    const router = initRouter();

    initSentry(app, router);

    const { store } = await initStore();

    app.use(IonicVue, { mode: 'md' }).use(store).use(router).mount('#app');
}
