import { RouteLocationNormalized, RouteLocationRaw, useRoute, useRouter } from 'vue-router';

import { ApiAuthAccessErrorCode } from 'dtg-api/modules/auth';

import { Route } from '@/router';

const AUTH_ACCESS_ERROR_ROUTE: Record<ApiAuthAccessErrorCode, Route> = {
    [ApiAuthAccessErrorCode.unauthorized]: Route.authLogin,
    [ApiAuthAccessErrorCode.forbidden]: Route.list,
    [ApiAuthAccessErrorCode.subscriptionNotFound]: Route.authLogin,
    [ApiAuthAccessErrorCode.subscriptionNotActive]: Route.authLogin,
    [ApiAuthAccessErrorCode.tokenInvalid]: Route.authLogin,
    [ApiAuthAccessErrorCode.refreshTokenInvalid]: Route.authLogin,
    [ApiAuthAccessErrorCode.userArchived]: Route.authLogin,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAuthRouting() {
    const router = useRouter();
    const route = useRoute();

    async function redirectToAuth(): Promise<void> {
        const authRoute = getAuthRoute(route);

        if (authRoute) {
            await router.push(authRoute);
        }
    }

    function getAuthRoute(currentRoute: RouteLocationNormalized): RouteLocationRaw | null {
        return currentRoute.name === Route.authLogin
            ? null
            : { name: Route.authLogin, params: { redirect: currentRoute.fullPath } };
    }

    function getAccessErrorRedirectRoute(code: string | number | null): Route {
        if (!code) {
            return Route.authLogin;
        }

        const routeMap = AUTH_ACCESS_ERROR_ROUTE as Record<string, Route>;

        return routeMap[code] ?? Route.authLogin;
    }

    return {
        redirectToAuth,
        getAuthRoute,
        getAccessErrorRedirectRoute,
    };
}
