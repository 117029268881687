export type ReportData = {
    dtgId: string;
    status: ReportStatus;
    file: ReportDataFile | null;
    error: ReportDataError | null;
    progress: number; // 0 to 1
    createdTimestamp: number;
    updatedTimestamp: number;
};

export type ReportDataFile = {
    storagePath: string;
    name: string;
};

export type ReportDataError = {
    code: ReportErrorCode;
    message: string;
    context: string;
};

export enum ReportStatus {
    pending = 'pending',
    generating = 'generating',
    error = 'error',
    ready = 'ready',
}

export enum ReportErrorCode {
    internal = 'internal',
    generator = 'generator',
    browser = 'browser',
    storage = 'storage',
}
