import { ApiModule } from '../../classes/ApiModule';

import { buildParcelDataFromCadastralEntry } from './builders';
import { API_PARCEL_FIND_ROUTE } from './routes';
import { ApiParcelFindRequest, ApiParcelFindResponse, ApiParcelFindResponseRaw } from './types';

export class ApiParcelModule extends ApiModule {
    private readonly gmaps;

    constructor(gmaps: Promise<typeof window.google.maps>) {
        super();

        this.gmaps = gmaps;
    }

    async findParcel(params: ApiParcelFindRequest): Promise<ApiParcelFindResponse> {
        const response = await this.api.get<ApiParcelFindResponseRaw>(API_PARCEL_FIND_ROUTE, {
            params,
            raw: { response: true },
        });

        if (response.ok) {
            return {
                ok: true,
                result: buildParcelDataFromCadastralEntry({
                    entry: response.result,
                    gmaps: await this.gmaps,
                    onError: this.logger,
                }),
            };
        }

        return response;
    }
}
