import { LatLngLiteral, LocationOption } from 'dtg-shared/types/data';

import { ApiGeocoderFetchAddressSuggestionRequest, ApiGeocoderModule } from '../../modules/geocoder';

// source https://regex101.com/library/fZ1oR3
// made modification to allow space delimiter
const LAT_LNG_PATTERN =
    /^(?<lat>[-+]?[1-8]?\d(?:\.\d+)?|90(?:\.0+)?)(?<divider>,\s+|,|\s+)(?<lng>[-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))$/;

export class Geocoder {
    private readonly api: ApiGeocoderModule;

    constructor({ api }: { api: ApiGeocoderModule }) {
        this.api = api;
    }

    async fetchAddressSuggestions(search: string): Promise<LocationOption[]> {
        const latLng = this.parseLatLngString(search);
        const params: ApiGeocoderFetchAddressSuggestionRequest = latLng ?? { address: search };

        const { result } = await this.api.throwIfErrors(this.api.fetchAddressSuggestions(params));

        return result.map((suggestion) => ({
            id: suggestion.id,
            value: suggestion.value,
            address: {
                city: suggestion.city,
                country: 'France',
                postalCode: suggestion.postalCode,
                route: suggestion.route,
            },
            location: suggestion.coordinates,
        }));
    }

    private parseLatLngString(str: string): LatLngLiteral | null {
        const execResult = LAT_LNG_PATTERN.exec(str);

        if (!execResult) {
            return null;
        }

        const lat = Number(execResult.groups?.lat);
        const lng = Number(execResult.groups?.lng);

        return { lat, lng };
    }
}
