import {
    InferUploadType,
    Upload,
    UploadDataType,
    UploadEventHandler,
    UploadMeta,
    UploadReferenceLocal,
    UploadReferenceType,
} from './types';
import { addEventHandler } from './utils';

export class FirebaseUploadReferenceLocal<T extends UploadDataType>
    implements UploadReferenceLocal<InferUploadType<T>>
{
    readonly type = UploadReferenceType.local;
    readonly addEventHandler?: (eventHandler: UploadEventHandler) => void;

    private readonly dataType: T;
    private readonly upload: Upload;

    constructor(dataType: T, upload: Upload) {
        if (upload.type !== dataType) {
            throw new Error(`Type of uploaded data wasn't "${dataType}"`);
        }

        this.dataType = dataType;
        this.upload = upload;

        const { task } = upload;

        if (task) {
            this.addEventHandler = (eventHandler): void => {
                addEventHandler(task, eventHandler);
            };
        }
    }

    // For non-url types, the url is an empty string
    async getUrl(): Promise<string> {
        const { data, type } = this.upload;

        const stringData = data instanceof Blob ? '' : data;
        const url = [UploadDataType.dataUrl, UploadDataType.base64Url].includes(type) ? stringData : '';

        return Promise.resolve(url);
    }

    async getData(): Promise<InferUploadType<T>> {
        return Promise.resolve(this.upload.data as InferUploadType<T>);
    }

    async getMetadata(): Promise<UploadMeta> {
        const { meta } = this.upload;

        return Promise.resolve(meta);
    }
}
