import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_avatar = _resolveComponent("dtg-avatar")!
  const _component_dtg_icon = _resolveComponent("dtg-icon")!
  const _component_dtg_action = _resolveComponent("dtg-action")!

  return (_openBlock(), _createBlock(_component_dtg_action, {
    class: _normalizeClass(_ctx.b({ active: _ctx.active })),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onActivatorClick($event)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.b('info'))
      }, [
        (_ctx.fullName)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.b('name'))
            }, _toDisplayString(_ctx.fullName), 3))
          : _createCommentVNode("", true),
        (_ctx.rolesLabel)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.b('roles'))
            }, _toDisplayString(_ctx.rolesLabel), 3))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_dtg_avatar, { src: _ctx.avatarUrl }, null, 8, ["src"]),
      _createVNode(_component_dtg_icon, {
        class: _normalizeClass(_ctx.b('chevron')),
        name: "chevron"
      }, null, 8, ["class"])
    ]),
    _: 1
  }, 8, ["class"]))
}