export enum BlobFormat {
    text = 'text',
    dataUrl = 'dataUrl',
    binaryString = 'binaryString',
    arrayBuffer = 'arrayBuffer',
    blob = 'blob',
}

export type BlobFormatString = BlobFormat.text | BlobFormat.dataUrl | BlobFormat.binaryString;

export type BlobData<T extends BlobFormat> = T extends BlobFormatString
    ? string
    : T extends BlobFormat.arrayBuffer
    ? ArrayBuffer
    : Blob;
