import { objPick } from 'dtg-shared/utils/object';

import { DocumentData, DocumentFormData, documentFormDataKeys } from './types';

export function buildDocumentData({
    listItemId = null,
    comment = '',
    status = null,
    madeBy = '',
    madeByDate = null,
    createdTimestamp = Date.now(),
    valid = false,
    custom = false,
    groupId = null,
    information = null,
    visibleInReport = true,
    files = [],
    filesVisibleInReport = false,
}: Partial<DocumentData> = {}): DocumentData {
    return {
        listItemId,
        status,
        comment,
        madeBy,
        madeByDate,
        createdTimestamp,
        valid,
        custom,
        groupId,
        information,
        visibleInReport,
        files,
        filesVisibleInReport,
    };
}

export function buildDocumentFormData(data: Partial<DocumentData> = {}): DocumentFormData {
    return objPick<DocumentData, keyof DocumentFormData>(buildDocumentData(data), documentFormDataKeys);
}
