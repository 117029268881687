// eslint-disable-next-line no-restricted-imports
import 'dtg-components/dist/dtg-components.css';

import { Geocoder } from 'dtg-api/classes/Geocoder';
import { addressSuggestionsService, setGmapsApiConfig } from 'dtg-components';
import { initializeDates } from 'dtg-shared/utils/date';
import { initializeValidation } from 'dtg-shared/validation';

import { getApiModule, initApi } from '@/services/api';
import { initFirebase } from '@/services/firebase';
import { reportApiService } from '@/services/report-api';

import { ENV } from '@/utils/env';

import { initApp } from '@/app/init';

import { initServiceWorker } from './register-service-worker';

import '@/styles/globals/index.scss';
import 'dtg-design/styles/globals/layout.scss';
import 'dtg-design/styles/globals/loader.scss';

/* eslint-disable no-console */
console.info('App version:', ENV.VUE_APP_VERSION);
console.info('Build id:', ENV.VUE_APP_BUILD_ID);
/* eslint-enable no-console */

void (async (): Promise<void> => {
    if (ENV.VUE_APP_SERVICE_WORKER_ENABLED) {
        initServiceWorker(ENV.BASE_URL);
    }

    initializeDates();
    initializeValidation();

    const { tokens } = initApi();

    reportApiService.init(tokens);

    await initFirebase();

    setGmapsApiConfig({
        apiKey: ENV.VUE_APP_GOOGLE_MAPS_API_KEY,
    });

    const geocoder = new Geocoder({ api: getApiModule('geocoder') });

    addressSuggestionsService.set(geocoder);

    await initApp();
})();
