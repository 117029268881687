import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_action = _resolveComponent("dtg-action")!
  const _component_dtg_col = _resolveComponent("dtg-col")!
  const _component_dtg_header_button = _resolveComponent("dtg-header-button")!
  const _component_dtg_header_dropdown = _resolveComponent("dtg-header-dropdown")!
  const _component_dtg_row = _resolveComponent("dtg-row")!
  const _component_dtg_grid = _resolveComponent("dtg-grid")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(_ctx.b())
  }, [
    _createVNode(_component_dtg_grid, { padding: "none" }, {
      default: _withCtx(() => [
        _createVNode(_component_dtg_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_dtg_col, {
              class: _normalizeClass(_ctx.b('pane')),
              size: "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_dtg_action, {
                  class: _normalizeClass(_ctx.b('logo')),
                  href: "/",
                  routed: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      class: _normalizeClass(_ctx.b('logo-image')),
                      src: "/assets/icon/logo-with-text-light.svg",
                      alt: "MyDTG Terrain"
                    }, null, 2)
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            }, 8, ["class"]),
            _createVNode(_component_dtg_col, {
              class: _normalizeClass(_ctx.b('pane', { second: true }))
            }, {
              default: _withCtx(() => [
                _createElementVNode("nav", null, [
                  _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.b('nav'))
                  }, [
                    (_ctx.user)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("li", null, [
                            _createVNode(_component_dtg_header_button, {
                              class: _normalizeClass(_ctx.b('action')),
                              href: _ctx.listHref,
                              "icon-name": "folder",
                              routed: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" DTG ")
                              ]),
                              _: 1
                            }, 8, ["class", "href"])
                          ]),
                          _createElementVNode("li", null, [
                            _createVNode(_component_dtg_header_dropdown)
                          ])
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("li", null, [
                            _createVNode(_component_dtg_header_button, {
                              class: _normalizeClass(_ctx.b('action')),
                              href: _ctx.aboutHref,
                              "icon-name": "information",
                              routed: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" A propos de MyDTG ")
                              ]),
                              _: 1
                            }, 8, ["class", "href"])
                          ]),
                          _createElementVNode("li", null, [
                            _createVNode(_component_dtg_header_button, _mergeProps({
                              class: _ctx.b('action')
                            }, _ctx.backofficeLinkProps, { "icon-name": "dashboard" }), {
                              default: _withCtx(() => [
                                _createTextVNode(" MyDTG Manager ")
                              ]),
                              _: 1
                            }, 16, ["class"])
                          ]),
                          _createElementVNode("li", null, [
                            _createVNode(_component_dtg_header_button, {
                              class: _normalizeClass(_ctx.b('action')),
                              href: _ctx.loginHref,
                              "icon-name": "user",
                              routed: ""
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Connexion ")
                              ]),
                              _: 1
                            }, 8, ["class", "href"])
                          ])
                        ], 64))
                  ], 2)
                ])
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}