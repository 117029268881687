import { truthy } from '../utils/truthy';

export class ErrorWithContext extends Error {
    context: unknown;

    constructor(context: unknown, message?: string) {
        const actualMessage = message ?? ErrorWithContext.getErrorMessage(context);

        super(actualMessage);

        this.context = context;
    }

    static getErrorMessage(context: unknown | unknown[]): string {
        if (typeof context === 'string') {
            return context;
        }

        if (ErrorWithContext.hasMessage(context)) {
            return String(context.message);
        }

        if (Array.isArray(context)) {
            if (context.length === 0) {
                return 'Unknown error';
            }

            return context.map((error) => ErrorWithContext.getErrorMessage(error)).join(', ');
        }

        return JSON.stringify(context);
    }

    static hasMessage(error: unknown): error is { message: unknown } {
        return typeof error === 'object' && truthy(error) && 'message' in error;
    }
}
