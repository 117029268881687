export function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]!}`;
}
