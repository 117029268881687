import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_dropdown_item = _resolveComponent("dtg-dropdown-item")!
  const _component_dtg_dropdown = _resolveComponent("dtg-dropdown")!

  return (_openBlock(), _createBlock(_component_dtg_dropdown, null, {
    default: _withCtx(() => [
      _createVNode(_component_dtg_dropdown_item, _mergeProps(_ctx.profileLinkProps, { icon: "user" }), {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.b('item-profile-full-text'))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-profile-name'))
            }, _toDisplayString(_ctx.fullName), 3),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.b('item-profile-role'))
            }, _toDisplayString(_ctx.rolesLabel), 3)
          ], 2),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.b('item-profile-text'))
          }, " Mon profil ", 2)
        ]),
        _: 1
      }, 16),
      _createVNode(_component_dtg_dropdown_item, _mergeProps(_ctx.backofficeLinkProps, { icon: "dashboard" }), {
        default: _withCtx(() => [
          _createTextVNode(" MyDTG Manager ")
        ]),
        _: 1
      }, 16),
      _createVNode(_component_dtg_dropdown_item, {
        href: _ctx.supportUrl,
        icon: "call",
        routed: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Support ")
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_dtg_dropdown_item, {
        href: _ctx.aboutUrl,
        icon: "information",
        routed: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(" A propos de MyDTG ")
        ]),
        _: 1
      }, 8, ["href"]),
      _createVNode(_component_dtg_dropdown_item, {
        icon: "turnOff",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLogoutClick()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Déconnexion ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}