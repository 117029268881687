import { readonly, ref } from 'vue';

import { wrapPending } from 'dtg-shared/utils/wrap-pending';

import { useAuth } from '@/composables/use-auth';

import { CollectionSubscriptionCanceled } from '@/services/collection-subscriptions';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useStoreData<T extends unknown[]>({
    load,
    errorMessage,
}: {
    load: (...args: T) => Promise<void>;
    errorMessage?: string;
}) {
    const { wrapWithRefreshRetry } = useAuth();

    const loading = ref(false);

    async function fetchData(...args: T): Promise<boolean> {
        return wrapPending(
            wrapWithRefreshRetry(async () => {
                try {
                    await load(...args);
                } catch (error) {
                    if (!(error instanceof CollectionSubscriptionCanceled)) {
                        throw error;
                    }
                }
            }, errorMessage),
            loading,
        );
    }

    return {
        loading: readonly(loading),

        fetchData,
    };
}
