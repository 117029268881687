import { boolean, mixed, string } from 'yup';

import { objValues } from 'dtg-shared/utils/object';
import { defineSchema, objectSchema, ValidationSchema } from 'dtg-shared/validation';
import { PdfOrientation } from 'dtg-shared-report/types';

import { BuildingPlanFile, BuildingPlanForm } from './types';

export function buildingPlanValidationSchema(): ValidationSchema<BuildingPlanForm> {
    return defineSchema({
        name: string().label('Nom du plan').defined(),
        file: objectSchema<BuildingPlanFile>().label('Document du plan').nullable(),
        visibleInReport: boolean().defined(),
        orientation: mixed().oneOf(objValues(PdfOrientation)).label('Orientation sur le raport').required(),
    });
}
