import { computed, onMounted, onUnmounted, ref } from 'vue';

import { generateId } from '../../utils/generate-id';
import { createInjectedState } from '../use-injected-state';

import { FormValidationResult, Validatable } from './types';
import { runValidators } from './utils';

export type FormListState = {
    register: (id: string, value: Validatable) => void;
    unregister: (id: string) => void;
};

const { useStateOrThrow, provideState } = createInjectedState<FormListState>('formList');

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useNestedForms() {
    const formsMap = ref<Map<string, Validatable>>(new Map());

    const forms = computed(() => {
        return Array.from(formsMap.value.values());
    });

    function register(formId: string, form: Validatable): void {
        formsMap.value.set(formId, form);
    }

    function unregister(formId: string): void {
        formsMap.value.delete(formId);
    }

    async function validate(focus?: boolean): Promise<FormValidationResult> {
        const validators = forms.value.map((form) => form.validate);

        return runValidators(validators, focus);
    }

    provideState({ register, unregister });

    return { forms, validate };
}

export function useNestedFormsRegistration(form: Validatable, formId = generateId()): void {
    const { register, unregister } = useStateOrThrow();

    onMounted(() => {
        register(formId, form);
    });

    onUnmounted(() => {
        unregister(formId);
    });
}
