export const pageSize = {
    width: 794,
    height: 1122,
};

export const pageMargin = {
    top: 55,
    bottom: 50,
    left: 48,
    right: 48,
};
