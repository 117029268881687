import { computed, readonly, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useStoreCollection } from '@/store';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useDtg() {
    const dtgId = ref('');

    const {
        getters: { getItem: getDtg },
    } = useStoreCollection('dtg');

    const route = useRoute();

    const dtg = computed(() => getDtg(dtgId.value));
    const dtgName = computed(() => dtg.value?.data.name ?? '');

    watch(
        () => route.params.id,
        (id) => {
            if (id) {
                dtgId.value = String(id || '');
            }
        },
        { immediate: true },
    );

    return {
        dtgId: readonly(dtgId),
        dtg,
        dtgName,
    };
}
