import { Position } from '@deck.gl/core';

import { ParcelData } from 'dtg-firebase/modules/parcel';
import { LatLngLiteral } from 'dtg-shared/types/data';

import { CadastralEntry, ParcelExtraParsed } from './types';

export function buildParcelDataFromCadastralEntry({
    entry,
    gmaps,
    position,
    onError,
}: {
    entry: CadastralEntry;
    gmaps: typeof google.maps;
    position?: { lat: number; lng: number };
    onError: (error: unknown) => void;
}): ParcelData {
    const {
        cadastre_id: cadastralId,
        cadastre_type: cadastreType,
        feature_id: featureId,
        geom_centroid: geomCentroid,
        city_id: cityId,
        extra,
        name,
        category,
        value,
        layerName,
        order,
        id,
    } = entry.properties;

    const bounds = new gmaps.LatLngBounds();

    entry.geometry.coordinates
        .flat()
        .map(buildLatLngLiteral)
        .forEach((point) => bounds.extend(point));

    const center = bounds.getCenter();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const coordinates = {
        center: { lat: center.lat(), lng: center.lng() },
        bounds: [
            { lat: ne.lat(), lng: ne.lng() },
            { lat: sw.lat(), lng: sw.lng() },
        ],
    };

    try {
        const { number, prefix, section } = JSON.parse(entry.properties.extra) as ParcelExtraParsed;

        return {
            createdTimestamp: Date.now(),
            meta: {
                coordinates,
                position: position ?? coordinates.center,
                cadastralId,
                cadastreType,
                featureId,
                extra,
                name,
                geomCentroid,
                cityId,
                category,
                value,
                layerName,
                order,
                id,
            },

            number,
            prefix,
            section,
            valid: true,
        };
    } catch {
        onError(buildParseError(entry));

        // use cadastral_id to guess parcel's fields
        const number = cadastralId.slice(-4);
        const section = cadastralId.replaceAll(/\d/g, '');

        return {
            createdTimestamp: Date.now(),
            meta: {
                coordinates,
                position: position ?? coordinates.center,
                cadastralId,
                cadastreType,
                featureId,
                extra,
                name,
                geomCentroid,
                cityId,
                category,
                value,
                layerName,
                order,
                id,
            },

            section,
            number,
            prefix: '',
            valid: true,
        };
    }
}

function buildLatLngLiteral(coordinates: Position): LatLngLiteral {
    const [lng, lat] = coordinates;

    return { lat, lng };
}

function buildParseError(entry: CadastralEntry): Error {
    const { cadastre_type: type, cadastre_id: id, extra } = entry.properties;

    const message = `
        Cadastral entry parse error.
        Tried to parse ${type} ${id}: ${extra}`;

    return new Error(message);
}
