import { arrayIncludes } from 'dtg-shared/utils/array';

import { ApiBase, ApiRequestConfig, ApiResponseError, ApiResponseWrapper } from '../classes/Api';
import { ApiError } from '../classes/Error';
import { ApiAuthAccessErrorCode } from '../modules/auth';

const AUTH_ACCESS_ERROR_MESSAGE: Record<ApiAuthAccessErrorCode, string> = {
    [ApiAuthAccessErrorCode.unauthorized]: 'Autorisation requise',
    [ApiAuthAccessErrorCode.forbidden]: "Vous n'êtes pas autorisé à effectuer cette action",
    [ApiAuthAccessErrorCode.subscriptionNotFound]: 'Aucun abonnement actif',
    [ApiAuthAccessErrorCode.subscriptionNotActive]: 'Aucun abonnement actif',
    [ApiAuthAccessErrorCode.tokenInvalid]: 'Autorisation expirée',
    [ApiAuthAccessErrorCode.refreshTokenInvalid]: 'Autorisation expirée',
    [ApiAuthAccessErrorCode.userArchived]: 'Utilisateur archivé',
};

type RetryResult =
    | {
          type: 'success';
          response: ApiResponseWrapper<unknown>;
      }
    | {
          type: 'authError';
          error: ApiResponseError<ApiAuthAccessErrorCode>;
          message: string;
      }
    | {
          type: 'refreshError';
          error: unknown;
          message: string;
      }
    | {
          type: 'otherError';
      };

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useRetryRequest({ refreshTokens }: { refreshTokens: () => Promise<void> }) {
    async function refreshTokensAndRetryRequest({
        api,
        errors,
        requestConfig,
    }: {
        api: ApiBase;
        errors: ApiResponseError[];
        requestConfig: ApiRequestConfig;
    }): Promise<RetryResult> {
        const tokenInvalidError = errors.find((error) => {
            return error.code === ApiAuthAccessErrorCode.tokenInvalid;
        });

        // Try refreshing if the error is about invalid access token
        if (tokenInvalidError && !requestConfig.isTokenRefresh) {
            try {
                await refreshTokens();
            } catch (error) {
                const code = error instanceof ApiError ? error.code : null;
                const fallbackMessage = (error as Error).message;
                const message = getAccessErrorMessage(code, fallbackMessage);

                return { type: 'refreshError', error, message };
            }

            // eslint-disable-next-line require-atomic-updates
            requestConfig.isTokenRefresh = true;

            const response = await api.request(requestConfig);

            return { type: 'success', response };
        }

        const authError = errors.find((error): error is ApiResponseError<ApiAuthAccessErrorCode> => {
            return arrayIncludes(Object.values(ApiAuthAccessErrorCode), error.code);
        });

        // In the case that the error is something else related to authorization,
        // it's pointless to try and refresh the tokens
        // Instead, the user should be prompted to re-login
        if (authError) {
            const message = getAccessErrorMessage(authError.code, authError.message);

            return { type: 'authError', error: authError, message };
        }

        return { type: 'otherError' };
    }

    function getAccessErrorMessage(code: string | number | null, fallbackMessage: string): string {
        if (!code) {
            return fallbackMessage;
        }

        const messageMap = AUTH_ACCESS_ERROR_MESSAGE as Record<string, string>;

        return messageMap[code] ?? fallbackMessage;
    }

    return {
        refreshTokensAndRetryRequest,
    };
}
