import { RouteRecordRaw } from 'vue-router';

import { Route } from '../types';

export const supportRoutes: RouteRecordRaw[] = [
    {
        path: '/support',
        name: Route.support,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-support" */ '../../components/pages/page-support/component.vue'
            ),
    },
];
