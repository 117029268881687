/**
 * For cases where we want to ensure that some action that awaits a promise before executing
 * cannot be executed multiple times.
 *
 * @example
 * const action = useCancelableAction();
 *
 * let toastPromise = Promise.resolve();
 *
 * async function someAction() {
 *     if (await action.wasCanceledDuring(toastPromise)) {
 *         return;
 *     }
 *
 *     const toast = await openToast('I will be shown once!');
 *
 *     toastPromise = toast.onDidDismiss();
 * }
 *
 * someAction();
 * someAction();
 * someAction();
 */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useCancelableAction() {
    let callTimestamp = Math.random();

    async function wasCanceledDuring(promise: Promise<unknown>): Promise<boolean> {
        const curTimestamp = Math.random();

        callTimestamp = curTimestamp;

        await promise;

        return curTimestamp !== callTimestamp;
    }

    function cancel(): void {
        callTimestamp = Math.random();
    }

    return {
        wasCanceledDuring,
        cancel,
    };
}
