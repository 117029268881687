import { email as emailBase } from '@sideway/address';
import { string, StringSchema } from 'yup';

export function email(): StringSchema {
    return string().test('email', 'Saisissez une adresse électronique valide', (value) => {
        return !value || emailBase.isValid(value);
    });
}

export function phoneNumber(): StringSchema {
    return string().matches(/^\d{10}$/, {
        message: 'Saisissez une numéro de téléphone valide',
        excludeEmptyString: true,
    });
}

export function hexColor(): StringSchema {
    return string().matches(/^#[0-9a-fA-F]{6}$/, {
        message: 'Entrez une couleur hexadécimale valide',
        excludeEmptyString: true,
    });
}

export const passwordMinLength = 8;

export const regexHasUpperCase = /[A-ZÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸ]/;

export const regexHasLowerCase = /[a-zàâæçéèêëîïôœùûüÿ]/;

// https://owasp.org/www-community/password-special-characters
export const regexHasSpecialChar = /[!"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]/;

export const regexHasNumber = /[0-9]/;

export function password(): StringSchema {
    return string()
        .min(passwordMinLength, ({ min }) => `Votre mot de passe doit contenir au moins ${min} caractères`)
        .matches(regexHasUpperCase, 'Votre mot de passe doit contenir un caractère majuscule')
        .matches(regexHasLowerCase, 'Votre mot de passe doit contenir un caractère minuscule')
        .matches(regexHasSpecialChar, 'Votre mot de passe doit contenir un caractère spécial')
        .matches(regexHasNumber, 'Votre mot de passe doit contenir un chiffre');
}
