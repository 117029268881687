import { AxiosInstance } from 'axios';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

import { transformObject, TransformShape } from '../../utils/transform-object';
import { ApiError } from '../Error';

import { Api } from './Api';
import { ApiInternalError, ApiRequestConfig, ApiResponse, ApiServerResponse } from './types';
import { localizeErrorMessage } from './utils';

export function addApiInterceptors(api: Api, logger: (error: unknown) => void): void {
    api.addRequestInterceptor((config) => {
        const shape = getRequestShapeTransforms(config);

        if (shape !== true) {
            config.data = transformRequestData(config.data, shape.data);
            config.params = transformRequestData(config.params, shape.params);
        }

        return config;
    });

    api.addResponseInterceptor<ApiServerResponse, ApiResponse, ApiInternalError<ApiServerResponse>>(
        (response) => {
            const { data, config } = response;

            if (data.status === 0) {
                const resultRaw = data.result;
                const shape = getResponseShapeTransforms(config);

                const result = transformResponseData(resultRaw, shape);

                return { ...response, data: { ok: true, result } };
            }

            const { errors } = data;

            return { ...response, data: { ok: false, error: errors } };
        },
        async (error) => {
            if (api.isCancel(error)) {
                return Promise.reject(error);
            }

            logger(error);

            const isInternalError = api.isInternalError(error);
            const response = isInternalError ? error.response : null;
            const message = localizeErrorMessage(error);

            const apiError = new ApiError({
                message,
                status: response?.status,
                config: response?.config,
            });

            return Promise.reject(apiError);
        },
    );
}

export function addErrorMessageLocalization(api: AxiosInstance): void {
    api.interceptors.response.use(
        (response) => response,
        async (error: Error) => {
            error.message = localizeErrorMessage(error);

            return Promise.reject(error);
        },
    );
}

// eslint-disable-next-line complexity
function getRequestShapeTransforms(
    config: ApiRequestConfig,
): { data: TransformShape | true | null; params: TransformShape | true | null } | true {
    const { raw, transformShape: shapes } = config;

    if (raw === true) {
        return true;
    }

    const data =
        (typeof raw === 'object' && (raw.request || raw.requestData)) ||
        (typeof shapes === 'object' && (shapes.requestData ?? shapes.request)) ||
        null;

    const params =
        (typeof raw === 'object' && (raw.request || raw.requestParams)) ||
        (typeof shapes === 'object' && (shapes.request ?? shapes.requestParams)) ||
        null;

    return { data, params };
}

function getResponseShapeTransforms(config: ApiRequestConfig): TransformShape | null | true {
    const { raw, transformShape: shapes } = config;

    if (raw === true) {
        return true;
    }

    return (typeof raw === 'object' && raw.response) || shapes?.response || null;
}

function transformRequestData(data: unknown, shape: TransformShape | true | null): unknown {
    return shape === true ? data : transformObject(data, snakeCase, transformRequestValue, shape);
}

function transformResponseData(data: unknown, shape: TransformShape | true | null): unknown {
    return shape === true ? data : transformObject(data, camelCase, transformResponseValue, shape);
}

function transformRequestValue(el: unknown): unknown {
    return el === '' ? null : el;
}

function transformResponseValue(el: unknown): unknown {
    // eslint-disable-next-line no-undefined
    return el === null || el === '' ? undefined : el;
}
