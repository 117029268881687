/* eslint-disable no-restricted-imports */

import { RouteRecordRaw } from 'vue-router';

import DtgPageLogin from '@/components/pages/page-auth-login/component.vue';

import { Route } from '../types';

export const authRoutes: RouteRecordRaw[] = [
    {
        path: '/login',
        name: Route.authLogin,
        component: DtgPageLogin,
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/password-reset',
        name: Route.authPasswordReset,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-auth" */ '../../components/pages/page-auth-password-reset/component.vue'
            ),
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/password-choose',
        name: Route.authPasswordChoose,
        component: async () =>
            import(
                /* webpackChunkName: "chunk-auth" */ '../../components/pages/page-auth-password-choose/component.vue'
            ),
        meta: {
            noAuth: true,
        },
    },
];
