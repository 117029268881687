import { CourseData, CourseFormData } from './types';

export function buildCourseItemData({
    name = '',
    firstname = '',
    localisation = '',
    phoneNumber = '',
    createdTimestamp = Date.now(),
    valid = false,
}: Partial<CourseData> = {}): CourseData {
    return {
        name,
        firstname,
        localisation,
        phoneNumber,

        createdTimestamp,
        valid,
    };
}

export function buildCourseFormData({
    name = '',
    firstname = '',
    localisation = '',
    phoneNumber = '',
}: Partial<CourseData> = {}): CourseFormData {
    return {
        name,
        firstname,
        localisation,
        phoneNumber,
    };
}
