import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dtg_offline_indicator = _resolveComponent("dtg-offline-indicator")!
  const _component_dtg_layout_auth_base = _resolveComponent("dtg-layout-auth-base")!

  return (_openBlock(), _createBlock(_component_dtg_layout_auth_base, { "logo-alt": "MyDTG Terrain" }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx(() => [
          (slot === 'default' && _ctx.offline)
            ? (_openBlock(), _createBlock(_component_dtg_offline_indicator, {
                key: 0,
                class: _normalizeClass(_ctx.b('offline-indicator'))
              }, null, 8, ["class"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, slot)
        ])
      }
    })
  ]), 1024))
}